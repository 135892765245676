import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from '../../shared/services/crud.service';
import { LogService } from '../../shared/services/log.service';

const URL_USER_USER = environment.apiUrl + "/security/user";
@Injectable({
  providedIn: 'root'
})
export class UserService extends CrudService {

  ressourceBaseURL(): string {
    return URL_USER_USER;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }
}
