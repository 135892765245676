import { Component, Input, OnInit } from "@angular/core";
import {
  CodificationModePaiement,
  TransactionDecision,
} from "src/app/modules/gas/demande/models/demande-constants";
import { PaiementService } from "src/app/modules/gas/demande/services/paiement.service";
import {
  StatusView,
  StatusViewSeverity,
} from "src/app/modules/setting/models/codification-constants";
import { LogService } from "src/app/modules/shared/services/log.service";

@Component({
  selector: "app-paiement-transaction",
  templateUrl: "./paiement-transaction.component.html",
  styleUrls: ["./paiement-transaction.component.css"],
})
export class PaiementTransactionComponent implements OnInit {
  status: StatusView;

  @Input() paiement: string;
  @Input() label: string;

  @Input() accepted: boolean = true;
  @Input() showDetail: boolean = true;

  displayDetails: boolean;

  amount: number;
  transactions: any[];

  constructor(private paiementService: PaiementService,
    private logService:LogService) {}

  ngOnInit(): void {}

  getTransactionStatus() {
    this.paiementService.find(this.paiement).subscribe((paiementObject) => {
      if (
        paiementObject &&
        paiementObject.modePaiement &&
        paiementObject.modePaiement.id ==
          CodificationModePaiement.CARTE_BANCAIRE
      ) {
        if(!this.label){
          this.label = paiementObject.code;
        }
        this.paiementService
          .transactionAmount(paiementObject.id, this.accepted)
          .subscribe((amount) => {
            this.logService.log("transactionAmount: " + amount);
            this.status = {} as StatusView;
            this.status.label = amount + "";

            if (amount > 0 && amount >= paiementObject.montant) {
              this.status.severity = StatusViewSeverity.success;
            } else {
              this.status.severity = StatusViewSeverity.danger;
            }
          });
      }
    });
  }

  transctionStatusSeverity(transaction: any): string {
    return transaction && transaction.decision == TransactionDecision.ACCEPT
      ? StatusViewSeverity.success
      : StatusViewSeverity.danger;
  }
  ngOnChanges() {
    this.getTransactionStatus();
  }

  viewDetails() {
    if (this.showDetail) {
      this.displayDetails = true;
      this.paiementService
        .transactions(this.paiement)
        .subscribe((data) => {
          this.transactions = data;
        });
    }
  }
}
