import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { ObjectExtensionsComponent } from "src/app/modules/setting/components/extension/object-extensions/object-extensions.component";
import { ListItem } from "src/app/modules/setting/models/codification";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";

import { PersonnePhysiqueService } from "../../services/personne/personne-physique.service";
import { DemandeHabilitation } from "../../models/demande-habilitation";
import {
  CategorieDemande,
  Demande,
  TypeDemandeMontant,
} from "../../models/demande";
import { PersonnePhysique } from "../../models/personne";
import { Formulaire } from "../../models/formulaire";
import { DemandeService } from "../../services/demande.service";
import { CategorieDemandeService } from "../../services/categorie-demande.service";
import { DemandeConstants } from "../../models/demande-constants";
import { ReferentielConstants } from "../../models/gas-constants";
import { TypeDemandeMontantService } from "../../services/type-demande-montant.service";

@Component({
  selector: "app-demande-formulaire",
  templateUrl: "./formulaire.component.html",
  styleUrls: ["./formulaire.component.css"],
})
export class FormulaireComponent implements OnInit {
  @Input() demandeId: string;
  // @Input() groupeExtension: string;
  @Input() categorieDemande: string;
  @Input() typeService: string;
  @Output() afterSave: EventEmitter<any> = new EventEmitter();

  position = 1;

  concept: string;
  object: string;
  editable: boolean;

  demande: Demande;
  personne: PersonnePhysique;

  categorieDemandes: ListItem[];
  typeDemandeMontants: TypeDemandeMontant[];
  typeDemandeMontant: string;

  demandeurs: PersonnePhysique[];
  demandeur: PersonnePhysique;

  submitted: boolean;

  formulaire: Formulaire;

  // @ViewChild("ext") extComponent: ObjectExtensionsComponent;
  @ViewChildren(ObjectExtensionsComponent)
  extComponents!: QueryList<ObjectExtensionsComponent>;

  constructor(
    public messageService: MessageService,
    private logService: LogService,
    private habilitationService: DemandeHabilitation,
    private demandeService: DemandeService,
    private categorieDemandeService: CategorieDemandeService,
    private personnePhysiqueService: PersonnePhysiqueService,
    private typeDemandeMontantService: TypeDemandeMontantService,
    private notificationService: NotificationService
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_DEMANDE;
    logService.log("FormulaireComponent typeService: " + this.typeService);

    this.demande = {} as Demande;
    this.demandeur = {} as PersonnePhysique;
    this.demandeur.id = null;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.initParameters();
  }

  initParameters() {
    if (this.demandeId != null) {
      this.demandeService.find(this.demandeId).subscribe((data) => {
        if (!data || !data.id) {
          this.habilitationService.redirectHome();
        }
        this.demande = data;
        this.categorieDemande = this.demande.categorieDemande.id;
        this.typeDemandeMontant = this.demande.typeDemandeMontant.id;

        this.initTypeService(
          this.demande.categorieDemande.typeDemande.typeService.id
        );

        this.object = this.demande.id;
        this.editable = this.demande.etat.modifiable || !this.demande.etat.milestone;

        this.personnePhysiqueService
          .find(this.demande.personne.id)
          .subscribe((p) => {
            this.demandeur = p;
          });

        // this.extComponent.setObject(this.demande.id);
        // this.extComponent.updateValeurExtensions();
      });
    } else {
      this.object = null;
      this.editable = true;
    }
    if (this.typeService) {
      this.categorieDemandeService
        .getAll(this.typeService, null)
        .subscribe((data) => {
          this.categorieDemandes = data;
        });
    }
  }

  initTypeService(ts: string) {
    if (ts) {
      this.typeService = ts;
      this.categorieDemandeService
        .getAll(this.typeService, null)
        .subscribe((data) => {
          this.categorieDemandes = data;
          if (this.categorieDemandes && this.categorieDemandes.length == 1) {
            this.categorieDemande = this.categorieDemandes[0].id;
          }
          this.selectCategorie();
        });
    }
  }

  selectCategorie() {
    if (this.categorieDemande) {
      this.categorieDemandeService
        .formulaire(this.categorieDemande)
        .subscribe((fm) => {
          this.logService.log(fm);
          this.formulaire = fm;
        });
    }
    this.typeDemandeMontantService
      .getAllByCategorie(this.categorieDemande)
      .subscribe((data) => {
        this.typeDemandeMontants = data;
      });
  }

  enregistrer() {
    if (this.editable) {
      if (!this.demandeur || !this.demandeur.id) {
        this.notificationService.errorMessage(
          "Le demandeur",
          " est obligatoire!"
        );
        return;
      }
      if (!this.categorieDemande) {
        this.notificationService.errorMessage(
          "La catégorie de demande",
          "est obligatoire!"
        );
        return;
      }
      if (!this.typeDemandeMontant) {
        this.notificationService.errorMessage(
          "Le délai de demande",
          "est obligatoire!"
        );
        return;
      }

      let formvalid = true;
      this.logService.log(this.extComponents);
      if (this.extComponents && this.extComponents.length > 0) {
        this.extComponents.forEach((extComponent) => {
          if (!extComponent.checkValeurExtensions()) {
            formvalid = false;
          }
        });
      }

      if (!formvalid) {
        return;
      }

      this.demande.categorieDemande = {} as CategorieDemande;
      this.demande.categorieDemande.id = this.categorieDemande;
      this.demande.personne = {} as PersonnePhysique;
      this.demande.personne = this.demandeur;
      this.demande.typeDemandeMontant = {} as TypeDemandeMontant;
      this.demande.typeDemandeMontant.id = this.typeDemandeMontant;
      this.demandeService.save(this.demande).subscribe((data) => {
        if (data && data.id) {
          this.demande = data;

          // this.extComponent.setObject(this.demande.id);
          let savedInfos: boolean = true;
          if (this.extComponents && this.extComponents.length > 0) {
            this.extComponents.forEach((extComponent) => {
              savedInfos =
                savedInfos &&
                extComponent.saveValeurExtensions(this.demande.id);
            });
          }
          if (savedInfos) {
            this.logService.log("Formulaire event callback START");
            this.afterSave.emit(null);
            this.logService.log("Formulaire event callback END");
          }
        }
      });
    }
  }

  searchDemandeur(event) {
    let params = {
      sort: event.sortField ? event.sortField : "nom",
      direction: event.sortOrder == 1 ? "DESC" : "ASC",
      motcle: event.query ? event.query : null,
      referentiel: ReferentielConstants.REF_PERS_DEMANDEUR,
    };

    this.personnePhysiqueService.search(0, 10, params).subscribe((data) => {
      if (data && data.content) {
        this.demandeurs = data.content;
        this.demandeurs.forEach((d) => {
          d.fullname = d.nom + " " + d.prenoms;
        });
      } else {
        this.notificationService.errorMessage(null, null);
      }
    });
  }
}
