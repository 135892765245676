import { Component, OnInit } from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service'; 

@Component({
  selector: 'app-demande-timeline',
  templateUrl: './demande-timeline.component.html',
  styleUrls: ['./demande-timeline.component.css']
})
export class DemandeTimelineComponent implements OnInit {

    customEvents: any[];

    horizontalEvents: any[];

    constructor(private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            {label: 'Pages'},
            {label: 'Timeline', routerLink: ['/pages/timeline']}
        ]);
    }

    ngOnInit() {
        this.customEvents = [
            { 
                typeAgent: 'Comptablité',
                date: '15/10/2020 10:30',
                icon: PrimeIcons.SHOPPING_CART,
                style: 'p-button-success',
                image: 'game-controller.jpg',
            },
            {typeAgent: 'Chargé de licences', date: '15/10/2020 14:00', icon: PrimeIcons.COG, style: 'p-button-success'},
            {typeAgent: 'DCSV', date: '15/10/2020 16:15', icon: PrimeIcons.ENVELOPE, style: 'p-button-success'},
            {typeAgent: 'Directeur Général', date: '16/10/2020 10:00', icon: PrimeIcons.CHECK, style: 'p-button-warning'}
        ];
    }

}
