import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import { CodificationTypeDonnee } from "../../models/codification-constants";
import { Extension, RechercheValeurExtensionCritere, ValeurExtension } from "../../models/extension";
import { ListItemService } from "../codification/list-item.service";

const URL_EXTENSION: string = environment.apiUrl + "/setting/extension";
@Injectable({
  providedIn: "root",
})
export class ExtensionService extends CrudService {
  ressourceBaseURL(): string {
    return URL_EXTENSION;
  }

  constructor(
    public httpClient: HttpClient,
    public logService: LogService,
    private listItemService: ListItemService
  ) {
    super(httpClient, logService);
  }

  findAllByGroupe(idGrpe: string): Observable<Extension[]> {
    return this.httpClient
      .get<any>(this.ressourceBaseURL() + "/" + idGrpe + "/list")
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  getValeurExtensions(
    objId: string,
    gpExt: string
  ): Observable<ValeurExtension[]> {
    return this.httpClient
      .post<any>(
        this.ressourceBaseURL() +
          "/valeur/" +
          objId +
          "/object?idgExt=" +
          gpExt,
        {}
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  saveValeurExtensions(
    objId: string,
    vext: ValeurExtension[]
  ): Observable<boolean> {
    return this.httpClient
      .post<any>(
        this.ressourceBaseURL() + "/save/valeur/" + objId + "/object",
        vext
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  getvaleur(vext: ValeurExtension):string {
    if (vext.extension.typeDonnee.id == CodificationTypeDonnee.LIST) {
      this.logService.log("LIST EXT: " + vext.valeur);
      if (vext.valeur) {
        this.listItemService.find(vext.valeur).subscribe((itemList) => {
          this.logService.log(itemList);
          if (itemList && itemList.libelle) {
            return itemList.libelle;
          }
        });
      }
    } else if (
      vext.extension.typeDonnee.id == CodificationTypeDonnee.DATE ||
      vext.extension.typeDonnee.id == CodificationTypeDonnee.DATETIME
    ) {
      var date = new Date(vext.valeurDate);
      // let val =
      //   (date.getMonth() > 8
      //     ? date.getMonth() + 1
      //     : "0" + (date.getMonth() + 1)) +
      //   "/" +
      //   (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      //   "/" +
      //   date.getFullYear();

      // if (vext.extension.typeDonnee.id == CodificationTypeDonnee.DATETIME) {
      //   val = val + " " + date.getHours + ":" + date.getMinutes;
      // }
      return date.toISOString();
    } else {
      if(!vext.valeur) this.logService.log(vext.extension.propriete.libelle)
      return vext.valeur?vext.valeur:".................................";
    }
  }

  
  multiCritereAllParameters(): Observable<any> {
    return this.httpClient
      .get<any>(this.ressourceBaseURL() + "/multicritere/parameters")
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  multiCritereSearch(param:RechercheValeurExtensionCritere): Observable<any> {
    return this.httpClient
      .post<any>(this.ressourceBaseURL() + "/multicritere/search", param)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }
}
