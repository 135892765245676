import { Component, Input, OnInit } from "@angular/core";
import {
  StatusView,
  StatusViewSeverity,
} from "src/app/modules/setting/models/codification-constants";
import { LogService } from "src/app/modules/shared/services/log.service";
import { PieceJointeService } from "../../../services/piece-jointe.service";

@Component({
  selector: "app-piece-status",
  templateUrl: "./piece-status.component.html",
  styleUrls: ["./piece-status.component.css"],
})
export class PieceStatusComponent implements OnInit {
  statutPiece: StatusView;
  total: number;
  uploded: number;
  validated: number;

  @Input() demande: string;
  @Input() showDetail: boolean = false;

  constructor(
    private pieceJointeService: PieceJointeService,
    private logService: LogService
  ) {}

  ngOnInit(): void {}

  getPieceTagCheck() {
    if (this.demande) {
      this.pieceJointeService.checkByDmde(this.demande).subscribe((data) => {
        this.logService.log("getPieceTagCheck");
        this.logService.log(data);
        this.logService.log("getPieceTagCheck");

        this.statutPiece = {} as StatusView;
        this.total = data.total;
        this.uploded = data.uploded;
        this.validated = data.validated;

        if (this.total && this.total >= 0 && this.total) {
          this.statutPiece.label = this.uploded + "/" + this.total;
          // this.statutPiece.label = this.uploded + " soumises / " + this.total;

          if (this.uploded >= this.total) {
            this.statutPiece.severity = StatusViewSeverity.warning;
          }
          if (this.uploded < this.total) {
            this.statutPiece.severity = StatusViewSeverity.danger;
          }
          if (this.uploded <= this.validated) {
            this.statutPiece.severity = StatusViewSeverity.success;
          }
          if (this.uploded == 0) {
            this.statutPiece.severity = StatusViewSeverity.danger;
          }
        }
      });
    }
  }

  ngOnChanges() {
    this.logService.log("UPDATE getPieceTagCheck " + this.demande);
    this.getPieceTagCheck();
    this.logService.log("UPDATE getPieceTagCheck");
  }
}
