import { ProfilUtilisteur } from 'src/app/modules/shared/models/habilitation';
import { HabilitationService } from 'src/app/modules/shared/services/habilitation.service';
import { HabilitationConstants } from './gas-constants';

export class DemandeHabilitation extends HabilitationService {
	getFunction(): string {
		return HabilitationConstants.FUNCTION_DEMANDE;
	}


}
