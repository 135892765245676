import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { interval, Subscription } from "rxjs";
import { DemandeConstants } from "../../gas/demande/models/demande-constants";
import {
  HabilitationConstants,
  RouteConstants,
} from "../../gas/demande/models/gas-constants";
import { User } from "../../security/models/user";
import {
  CodificationUrgence,
  StatusViewSeverity,
} from "../../setting/models/codification-constants";
import { ActionNotification } from "../../setting/models/workflow";
import { WorkflowService } from "../../setting/services/workflow/workflow.service";
import { HomeHabilitation } from "../models/home-habilitation";
import { AuthService } from "../services/auth.service";
import { LogService } from "../services/log.service";
import { AppMainComponent } from "./app.main.component";
import { CacheService } from "../services/cache.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-topbar",
  templateUrl: "./app.topbar.component.html",
})
export class AppTopBarComponent implements OnInit, OnDestroy {

  keyNotificationDemande: string = "notificationDemande";
  keyNotificationPaiement: string = "notificationPaiement";
  keyNotificationPesronne: string = "notificationPersonne";
  // every 10 Minutes
  @Input() refresh: number = 600000; // in millsecconde

  activeItem: number;
  viewNotification: boolean;

  personneNotifications: ActionNotification[] = [];
  paiementNotifications: ActionNotification[] = [];
  demandeNotifications: ActionNotification[] = [];

  personneNotificationsAfficher: ActionNotification[] = [];
  paiementNotificationsAfficher: ActionNotification[] = [];
  demandeNotificationsAfficher: ActionNotification[] = [];

  pageSize: number= 5;

  subscription: Subscription;

  constructor(
    public appMain: AppMainComponent,
    public authService: AuthService,
    private workflowService: WorkflowService,
    private logService: LogService,
    public habilitation: HomeHabilitation,
    private cacheService: CacheService,
    private router: Router
  ) {
  }


  ngOnInit(): void {
    this.viewNotification = environment.viewNotification;
    if(this.viewNotification){
      this.getNotificationDemande();
      this.getNotificationPaiement();
      this.getNotificationPersonne();
      // Refresh notifications
      const refreshInterval = interval(this.refresh);
      this.subscription = refreshInterval.subscribe((val) => {
        this.getNotificationDemande();
        this.getNotificationPaiement();
        this.getNotificationPersonne();
      });
    }
   
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  mobileMegaMenuItemClick(index) {
    this.appMain.megaMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  getNotificationDemande() {
    if (this.cacheService.has(this.keyNotificationDemande)) {
      this.logService.log("get cache " + this.keyNotificationDemande);

      this.demandeNotifications = this.cacheService.get(this.keyNotificationDemande) as ActionNotification[];
      this.logService.log(this.demandeNotifications);
      this.logService.log("end get cache " + this.keyNotificationDemande);

      return;
    }

    this.workflowService
      .notification(
        DemandeConstants.CONCEPT_METIER_DEMANDE,
        DemandeConstants.FUNCTION_DEMANDE
      )
      .subscribe((data) => {
        this.logService.log("set cache " + this.keyNotificationDemande);
        this.cacheService.set(this.keyNotificationDemande, data);
        this.demandeNotifications = data;
        this.demandeNotificationsAfficher= this.demandeNotifications?.slice(0, this.pageSize);
      });
  }

  getNotificationPaiement() {
    if (this.cacheService.has(this.keyNotificationPaiement)) {
      this.logService.log("get cache " + this.keyNotificationPaiement);
      this.paiementNotifications = this.cacheService.get(this.keyNotificationPaiement) as ActionNotification[];
      return;
    }

    this.workflowService
      .notification(
        DemandeConstants.CONCEPT_METIER_PAIEMENT,
        DemandeConstants.FUNCTION_PAIEMENT, true
      )
      .subscribe((data) => {
        this.logService.log("getNotificationPaiement");
        this.logService.log(data);
        this.logService.log("getNotificationPaiement");

        this.logService.log("Set cache " + this.keyNotificationDemande);
        this.cacheService.set(this.keyNotificationPaiement, data);
        this.paiementNotifications = data;
        this.paiementNotificationsAfficher= this.paiementNotifications?.slice(0, this.pageSize);
      });
  }

  getNotificationPersonne() {
    if (this.cacheService.has(this.keyNotificationPesronne)) {
      this.logService.log("get cache " + this.keyNotificationPesronne);
      this.personneNotifications = this.cacheService.get(this.keyNotificationPesronne) as ActionNotification[];
      return;
    }
    this.workflowService
      .notification(
        HabilitationConstants.CONCEPT_METIER_PERSONNE,
        DemandeConstants.FUNCTION_PERS_PHYSIQUE, true
      )
      .subscribe((data) => {
        this.logService.log("set cache " + this.keyNotificationPesronne);
        this.cacheService.set(this.keyNotificationPesronne, data);
        this.personneNotifications = data;
        this.personneNotificationsAfficher= this.personneNotifications?.slice(0, this.pageSize);
      });
  }

  getNotificationSeverity(id: string): string {
    let severity = StatusViewSeverity.danger;
    if (id == CodificationUrgence.BASSE) {
      severity = StatusViewSeverity.info;
    } else if (id == CodificationUrgence.NORMALE) {
      severity = StatusViewSeverity.warning;
    }

    return severity;
  }

  userAvatarName(user: User): string {
    if (user) {
      let avatarName = user.firstName.charAt(0) + user.lastName.charAt(0);
      return avatarName.toUpperCase();
    }
  }
  userFullName(user: User): string {
    return user ? user.firstName + " " + user.lastName : "";
  }

  viewDemande(id: string) {
    this.logService.log("viewDemande: " + id);
    this.router.navigateByUrl(RouteConstants.ROUTE_DEMANDE + "?id=" + id);
  }
  viewPaiement(id: string) {
    this.logService.log("viewPaiement: " + id);
    this.router.navigateByUrl(
      RouteConstants.ROUTE_DEMANDE_PAIEMENT + "?id=" + id
    );
  }
  viewPersonne(id: string) {
    this.logService.log("viewPersonne: " + id);
    this.router.navigateByUrl(RouteConstants.ROUTE_PERSONNEL + "?id=" + id);
  }
}
