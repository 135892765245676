import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-demande-mode-paiement',
  templateUrl: './save-demande-mode-paiement.component.html',
  styleUrls: ['./save-demande-mode-paiement.component.css']
})
export class SaveDemandeModePaiementComponent implements OnInit {

  valRadio: string;
  constructor() { }

  ngOnInit(): void {
  }

}
