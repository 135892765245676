import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/modules/security/models/user";
import { ProfilUtilisteur } from "src/app/modules/shared/models/habilitation";
import { HomeHabilitation } from "src/app/modules/shared/models/home-habilitation";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { FormService } from "src/app/modules/shared/services/form.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { RouteConstants } from "../../../demande/models/gas-constants";
import { PersonnePhysiqueService } from "../../../demande/services/personne/personne-physique.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  checked: boolean;
  utilisateur: User = new User();
  validCaptcha: boolean;

  ngOnInit() { }

  constructor(
    private logService: LogService,
    private authService: AuthService,
    private formService: FormService,
    private personnePhysiqueService: PersonnePhysiqueService,
    private route: ActivatedRoute,
    private router: Router,
    private homeHabilitation: HomeHabilitation,
    private notificationService: NotificationService
  ) { }

  login(loginform: NgForm) {
    if (!this.validCaptcha) {
      this.notificationService.warningMessage(
        "Veuillez confirmer que vous n'êtes pas un robot!",
        ""
      );
      return;
    }

    if (this.formService.isNgFormValid(loginform)) {
      this.logService.log("login");
      // this.personne.sexe = this.genre.code;
      this.authService
        .login(this.utilisateur.email, this.utilisateur.password)
        .subscribe((authenticationInfo) => {
          this.logService.log("authenticationInfo: ");
          this.logService.log(authenticationInfo);
          this.logService.log("authenticationInfo: ");

          if (
            authenticationInfo.accessToken &&
            authenticationInfo.refreshToken
          ) {
            // authentication successful : we extract the token
            this.authService.setToken(authenticationInfo.accessToken);
            this.authService.setRefreshToken(authenticationInfo.refreshToken);
            this.authService.setTokenLifetime(authenticationInfo.expiresIn);

            // get logged user
            this.authService.getLoggedUser().subscribe((data) => {
              this.logService.log("logged user: ");
              this.logService.log(data);
              this.logService.log("logged user: ");

              if (data.user && data.user.id) {
                // this.authService.isUserActive(data.user.id).subscribe((active) => {

                this.logService.log("isUserActive: " + data.user.active);
                if (data.user.active) {
                  // everything ok
                  this.authService.setUserId(data.user.id);
                  this.authService.setUsername(data.user.username);
                  this.authService.setUserFullName(
                    data.user.firstName + " " + data.user.lastName
                  );
                  this.authService.setAuthorities(data.autorities);

                  if (
                    this.homeHabilitation.hasProfil(ProfilUtilisteur.DEMANDEUR)
                  ) {
                    this.authService.removeUserData();
                    this.notificationService.errorMessage("No Authorization");
                    this.utilisateur = {} as User;
                    return;
                  }

                  this.personnePhysiqueService
                    .findByUser(data.user.id)
                    .subscribe((personne) => {
                      this.logService.log("personne user: ");
                      this.logService.log(personne);
                      this.logService.log("personne user: ");

                      if (
                        personne &&
                        personne.etat &&
                        personne.etat.milestone
                      ) {
                        this.authService.setUserPersonneId(personne.id);
                        const returnUrl = this.authService.getReturnUrl() || RouteConstants.ROUTE_DASHBOARD;
                        this.logService.log("returnUrl " + returnUrl);
                        this.authService.removeReturnUrl();
                        this.router.navigateByUrl(returnUrl);
                      } else {
                        this.notificationService.errorMessage(
                          "Vous n'êtes encore pas autorisé!!",
                          null
                        );
                      }
                    });
                } else {
                  // something went wrong
                  // we removes previously stored token
                  this.authService.removeUserData();
                  this.notificationService.errorMessage(
                    "Votre compte utilisateur n'est pas activé",
                    null
                  );
                }
                //   //
                // });
              } else {
                // something went wrong
                // we removes previously stored token
                this.authService.removeUserData();
                this.notificationService.serverError();
              }
              //
            });
          } else {
            this.notificationService.errorMessage(
              "Email ou mot de passe incorrecte",
              ""
            );
          }
        });
    }
  }

  showResponse(event) {
    if (event && event.response) {
      this.validCaptcha = true;
      // check Captcha
      // this.authService.checkCaptha(event.response).subscribe((data) => {
      //   this.logService.log("check Captcha: " + data);
      //   this.validCaptcha = data;
      // });
    }
  }


  routeToForget(actionAfaire: string) {
    this.router.navigate(['/forget'], {
      queryParams: { action: actionAfaire },
    });
  }
}
