import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormService } from 'src/app/modules/shared/services/form.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { Demande, DocumentLivre } from '../../models/demande';
import { DemandeHabilitation } from '../../models/demande-habilitation';
import { DemandeService } from '../../services/demande.service';
import { DocumentLivreService } from '../../services/document-livre.service';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';

@Component({
  selector: 'app-document-livre',
  templateUrl: './document-livre.component.html',
  styleUrls: ['./document-livre.component.css']
})
export class DocumentLivreComponent implements OnInit {
  @Input() demandeId: string;
  @Input() editable: boolean;

  demande: Demande;
  documentLivre: DocumentLivre;

  constructor(private formService: FormService,
    public demandeHabilitation: DemandeHabilitation,
    private demandeService: DemandeService,
    private documentLivreService: DocumentLivreService,
    private notificationService: NotificationService,
    private logService: LogService) {

  }

  ngOnInit(): void {
    this.initParameters();
  }


  initParameters() {
    if (this.demandeId != null) {
      this.demandeService.find(this.demandeId).subscribe((data) => {
        if (!data || !data.id) {
          this.demandeHabilitation.redirectHome();
        }
        this.demande = data;
        this.documentLivre = this.demande.documentLivre;
        if (this.documentLivre.dateDebut) {
          this.documentLivre.dateDebut = new Date(this.documentLivre.dateDebut);
        }
        if (this.documentLivre.dateFin) {
          this.documentLivre.dateFin = new Date(this.documentLivre.dateFin);
        }
      });
    }
  }

  ngOnChanges() {
    this.initParameters();
  }

  modifier(){
    if (this.documentLivre.dateDebut) {
      this.documentLivre.dateDebut = new Date(this.documentLivre.dateDebut);
    }
    if (this.documentLivre.dateFin) {
      this.documentLivre.dateFin = new Date(this.documentLivre.dateFin);
    }
    this.editable=true;
  }

  saveDocumentLivre(formOccupant: NgForm) {
    if (!this.formService.isNgFormValid(formOccupant)) {
      return;
    }
    this.logService.log("##########saveDocumentLivre");
    this.logService.log(this.documentLivre);
    this.logService.log("##########saveDocumentLivre");

    if (this.documentLivre.dateDebut && this.documentLivre.dateFin) {
      if (this.documentLivre.dateDebut > this.documentLivre.dateFin) {
        this.notificationService.errorMessage("La date de fin",
          "La date de fin doit être supérieure à la date de debut!")
        return;
      }
    }

    this.documentLivreService.save(this.documentLivre).subscribe(data => {
      if (data) {
        this.documentLivre = data;
        this.editable = false;
      }
    });
  }

}
