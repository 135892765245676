import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ListItem } from "src/app/modules/setting/models/codification";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { Demande, Feeback } from "../../models/demande";
import { DemandeConstants } from "../../models/demande-constants";
import { DemandeService } from "../../services/demande.service";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.css"],
})
export class FeedbackComponent implements OnInit {
  @Input() demande: Demande;
  @Input() editable: boolean;

  feedback: Feeback;
  selectedCategory: any = null;

  constructor(
    private demandeService: DemandeService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    
  }

  initParameters() {

    if(this.demande.documentLivre && this.demande.documentLivre.dateDelivrance){
      this.demande.documentLivre.dateDelivrance = new Date(
        this.demande.documentLivre.dateDelivrance
      );
    }

    if (this.demande && this.demande.feedback) {
      this.feedback = this.demande.feedback;
    } else {
      this.feedback = {} as Feeback;
      this.feedback.demande = this.demande;
      this.feedback.contenuAppreciation = {} as ListItem;
      this.feedback.reponseAppreciation = {} as ListItem;
      this.feedback.delaiAppreciation = {} as ListItem;
      this.feedback.infoAppreciation = {} as ListItem;
      this.feedback.appreciationGlobale = {} as ListItem;
    }
  }

  ngOnChanges() {
    this.initParameters();
  }

  saveFeedback() {
    if (
      !this.feedback.contenuAppreciation.id ||
      !this.feedback.reponseAppreciation.id ||
      !this.feedback.delaiAppreciation.id ||
      !this.feedback.infoAppreciation.id ||
      !this.feedback.appreciationGlobale.id
    ) {
      this.notificationService.warningMessage(
        "Veuillez nous donner votre satifaction sur chaque sujet et globalement!",
        ""
      );
      return;
    }
    this.demandeService
      .saveFeedback(this.demande.id, this.feedback)
      .subscribe((fb) => {
        this.feedback = fb;
        this.editable = this.feedback.id == null;
        this.notificationService.successMessage("Merci pour votre feedback!");
      });
  }
}
