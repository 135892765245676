import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service';  

@Component({
  selector: 'app-liste-comptes',
  templateUrl: './liste-comptes.component.html',
  styleUrls: ['./liste-comptes.component.css']
})
export class ListeComptesComponent implements OnInit {
  statutsComptes: any[];
  comptes: any[];
  displayDetail: boolean;
  displayForm: boolean;
  displayValidation: boolean;
  saveCompteStep:number = 0;
  saveCompteMinMaxStep = [0,3];


  @ViewChild('dt') table: Table;

  selectedStatuts:any;


  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
        { label: 'SURVATTER'},
        { label: 'Liste des comptes', routerLink: ['/survatter/liste-demandes'] }
    ]);
    this.statutsComptes = [
      {id:1, icon: 'pi pi-circle-on',libelle: "Comptes non actifs"},
      {id:2,icon: 'pi pi-circle-on',libelle: "Comptes actifs"},
    ];
    
  }


    ngOnInit() {
        this.comptes = [
            {ref:"A", nom:"ALI", prenom:"Réne", createdAt:"10/14/2021"},
            {ref:"B", nom:"Koulibali", prenom:"Dupond", createdAt:"10/14/2021"},
            {ref:"C", nom:"DURKAIM", prenom:"Renard", createdAt:"10/14/2021"},
            {ref:"D", nom:"LEE", prenom:"Maxim", createdAt:"10/14/2021"},
            {ref:"E", nom:"LEE", prenom:"Clément", createdAt:"10/14/2021"},
            {ref:"F", nom:"ALIOU", prenom:"Pierre", createdAt:"10/14/2021"}
        ]
        this.selectStatuts(this.statutsComptes[0])
    }

    selectStatuts(selectedStatuts:any){
        this.selectedStatuts = selectedStatuts;
        this.comptes = this.getComptes(this.selectedStatuts.id);
    }

    getComptes(id:number){
        switch (id) {
            case 1:
                return [
                  {ref:"A", nom:"ALI", prenom:"Réne", createdAt:"10/14/2021"},
                  {ref:"B", nom:"Koulibali", prenom:"Dupond", createdAt:"10/14/2021"},
                  {ref:"C", nom:"DURKAIM", prenom:"Renard", createdAt:"10/14/2021"},
                  {ref:"D", nom:"LEE", prenom:"Maxim", createdAt:"10/14/2021"},
                  {ref:"E", nom:"LEE", prenom:"Clément", createdAt:"10/14/2021"},
                  {ref:"F", nom:"ALIOU", prenom:"Pierre", createdAt:"10/14/2021"}
                ];
            case 2:
                return [
                    {ref:"A1111", nom:"N", prenom:"P", createdAt:"10/14/2021"},
                    {ref:"B2222", nom:"N1", prenom:"P1", createdAt:""},
                    {ref:"C3333", nom:"N2", prenom:"P2", createdAt:""},
                    {ref:"D4444", nom:"N3", prenom:"P3", createdAt:""},
                    {ref:"E5555", nom:"N4", prenom:"P4", createdAt:""},
                    {ref:"F6666", nom:"N5", prenom:"P5", createdAt:""}
                ];
            case 3:
                return [
                    {ref:"R5f11", nom:"N", prenom:"P", createdAt:"10/14/2021"},
                    {ref:"TS262", nom:"N1", prenom:"P1", createdAt:""},
                    {ref:"RD521", nom:"N2", prenom:"P2", createdAt:""},
                    {ref:"85RDF", nom:"N3", prenom:"P3", createdAt:""},
                    {ref:"HYD6", nom:"N4", prenom:"P4", createdAt:""},
                    {ref:"FRS6", nom:"N5", prenom:"P5", createdAt:""}
                ];
            case 4:
                return [
                    {ref:"SXT11", nom:"N", prenom:"P", createdAt:"10/14/2021"},
                    {ref:"B2222", nom:"N1", prenom:"P1", createdAt:""},
                    {ref:"CFSC3", nom:"N2", prenom:"P2", createdAt:""},
                    {ref:"785R4", nom:"N3", prenom:"P3", createdAt:""},
                    {ref:"ODFH5", nom:"N4", prenom:"P4", createdAt:""},
                    {ref:"56PL6", nom:"N5", prenom:"P5", createdAt:""}
                ];
            default:
                return [];
        }
    }

    nextSaveCompteStep(){
        if(this.saveCompteStep<this.saveCompteMinMaxStep[1]){
            this.saveCompteStep += 1;
        }
    }
    previousSaveCompteStep(){
        if(this.saveCompteStep>this.saveCompteMinMaxStep[0]){
            this.saveCompteStep -= 1;
        }
    }

}
