import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { CodificationTypeDonnee } from "../../../models/codification-constants";
import {
  Extension,
  GroupeExtension,
  ValeurExtension,
} from "../../../models/extension";
import { ListItemService } from "../../../services/codification/list-item.service";
import { ExtensionService } from "../../../services/extension/extension.service";
import { GroupeExtensionService } from "../../../services/extension/groupe-extension.service";
import { ExtensionUtils } from "src/app/modules/shared/models/extension-utils";

@Component({
  selector: "app-object-extensions",
  templateUrl: "./object-extensions.component.html",
  styleUrls: ["./object-extensions.component.css"],
})
export class ObjectExtensionsComponent implements OnInit, OnChanges {
  @Input() concept: string;

  @Input() object: string;

  @Input() gExtension: string;

  @Input() editable = true;

  @Input() saveLabel = "Enregistrer";

  @Input() showAction = true;

  @Input() dateControl = true;

  // @Input() callback: Function;

  // @Input() public callbackParams: any;

  @Output() afterSave: EventEmitter<any> = new EventEmitter();

  valeurExtensions: ValeurExtension[];

  groupeExtension: GroupeExtension;

  constructor(
    private logService: LogService,
    private notificationService: NotificationService,
    private groupeExtensionService: GroupeExtensionService,
    private extensionService: ExtensionService,
    private listItemService: ListItemService
  ) {}

  ngOnInit(): void {
    this.logService.log("ObjectExtensionsComponent");
    this.logService.log(this.concept);
    this.logService.log(this.object);
    this.logService.log(this.gExtension);
    this.logService.log(this.editable);
    this.logService.log("ObjectExtensionsComponent");

    if (this.gExtension) {
      this.groupeExtensionService.find(this.gExtension).subscribe((data) => {
        this.logService.log("groupeExtensionService");
        this.logService.log(data);
        this.logService.log("groupeExtensionService");

        this.groupeExtension = data;
      });

      this.extensionService
        .getValeurExtensions(this.object, this.gExtension)
        .subscribe((data) => {
          this.logService.log("valeurExtensions");
          this.logService.log(data);
          this.logService.log("valeurExtensions");
          this.valeurExtensions = data;
          this.valeurExtensions.forEach((v) => {
            if (this.isDateTime(v.extension) || this.isDate(v.extension)) {
              if (v.valeur) {
                v.valeurDate = new Date(v.valeur);
              }
            }
          });
        });
    } else {
      this.notificationService.serverError("Extensions not found");
    }
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    // Write your code here
    this.logService.log("###DATA CHANGE");
    this.logService.log(this.object);
    this.updateValeurExtensions();
    this.logService.log("###DATA CHANGE");
  }

  updateValeurExtensions() {
    this.extensionService
      .getValeurExtensions(this.object, this.gExtension)
      .subscribe((data) => {
        this.logService.log("updateValeurExtensions START");
        this.logService.log(data);
        this.valeurExtensions = data;
        this.valeurExtensions.forEach((v) => {
          if (this.isDateTime(v.extension) || this.isDate(v.extension)) {
            if (v.valeur) {
              v.valeurDate = new Date(v.valeur);
            }
          }
        });
        this.logService.log("updateValeurExtensions END");
      });
  }

  checkValeurExtensionPresent(val: ValeurExtension): boolean {
    if (
      val.extension.requis &&
      !val.valeur &&
      !val.valeurDate &&
      !val.valeurItem
    ) {
      this.notificationService.errorMessage(
        val.extension.propriete.caption,
        "Veuillez renseigner ce champ!"
      );
      return false;
    }

    if (
      this.isAlphaNumeric(val.extension) ||
      this.isNumeric(val.extension) ||
      this.isTextArea(val.extension)
    ) {
      return !val.valeur || (val.valeur != null && val.valeur != "");
    } else if (this.isDate(val.extension) || this.isDateTime(val.extension)) {
      return (
        !val.valeurDate ||
        (val.valeurDate != null &&
          this.checkDate(val.valeurDate, val.extension.propriete.caption))
      );
    } else if (this.isSelect(val.extension)) {
      return (
        !val.valeurItem || (val.valeurItem != null && val.valeurItem.id != null)
      );
    } else if (this.isMultiSelect(val.extension)) {
      return (
        !val.valeurMultipleItems ||
        (val.valeurMultipleItems != null && val.valeurMultipleItems.length > 0)
      );
    }
    return false;
  }

  checkValeurExtensions(): boolean {
    let checked = true;
    this.valeurExtensions.forEach((val) => {
      // if (val.extension.requis) {
      if (!this.checkValeurExtensionPresent(val)) {
        this.notificationService.errorMessage(
          val.extension.propriete.caption,
          "Veuillez renseigner ce champ!"
        );
        checked = false;
      }
      // }
    });
    return checked;
  }

  saveValeurExtensions(object?: string): boolean {
    this.logService.log("saveValeurExtensions START" + object);
    const checked = this.checkValeurExtensions();
    let idObject: string = this.object;
    if (object) {
      idObject = object;
    }

    if (checked) {
      this.logService.log("saveValeurExtensions idObject" + idObject);

      this.extensionService
        .saveValeurExtensions(idObject, this.valeurExtensions)
        .subscribe((data) => {
          if (data) {
            this.logService.log("event callback START");
            this.afterSave.emit(null);
            this.logService.log("event callback END");
          } else {
            this.notificationService.serverError();
          }
        });
    }
    this.logService.log("saveValeurExtensions END");

    // this.logService.log(this.callback);
    // this.logService.log(this.callbackParams);

    // this.callback(this.callbackParams);

    return checked;
  }

  setObject(object: string) {
    this.object = object;
  }

  checkDate(date, caption): boolean {
    let today = new Date();

    this.logService.log("checkDate");
    this.logService.log(date);
    this.logService.log(today);
    this.logService.log("checkDate");
    if (this.dateControl && date < today) {
      this.notificationService.errorMessage(
        caption,
        "Doit être superieure à la date d'aujourd'hui "
      );
      return false;
    }
    return true;
  }
  onDateSelect(date, caption) {
    this.logService.log("onDateSelect");
    this.checkDate(date, caption);
    this.logService.log("onDateSelect");
  }

  isNumeric(extension: Extension): boolean {
    return ExtensionUtils.isNumeric(extension);
  }

  isAlphaNumeric(extension: Extension): boolean {
    return ExtensionUtils.isAlphaNumeric(extension);
  }
  isTextArea(extension: Extension): boolean {
    return ExtensionUtils.isTextArea(extension);
  }
  isDateTime(extension: Extension): boolean {
    return ExtensionUtils.isDateTime(extension);
  }

  isDate(extension: Extension): boolean {
    return ExtensionUtils.isDate(extension);
  }
  isSelect(extension: Extension): boolean {
    return ExtensionUtils.isSelect(extension);
  }

  isMultiSelect(extension: Extension): boolean {
    return ExtensionUtils.isMultiSelect(extension);
  }

  isTreeSelect(extension: Extension): boolean {
    return extension.typeDonnee.id == CodificationTypeDonnee.CLASSIFICATION;
  }

  isTagSelect(extension: Extension): boolean {
    return ExtensionUtils.isTagSelect(extension);
  }
}
