import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import {
  HabilitationConstants,
  ReferentielConstants,
  RouteConstants,
  UserProfilsConstants,
} from "src/app/modules/gas/demande/models/gas-constants";
import {
  PersonnePhysique,
  ReferentielPersonne,
} from "src/app/modules/gas/demande/models/personne";

import { PersonnePhysiqueService } from "src/app/modules/gas/demande/services/personne/personne-physique.service";
import { Profil, User } from "src/app/modules/security/models/user";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { FormService } from "src/app/modules/shared/services/form.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { PersonneHabilitation } from "../../../models/personne-habilitation";

@Component({
  selector: "app-demandeur",
  templateUrl: "./demandeur.component.html",
  styleUrls: ["./demandeur.component.css"],
})
export class DemandeurComponent implements OnInit {
  motcle: string;
  concept: string;
  objectId: string;
  userId: string;
  fonction: string;

  params: any;
  totalRecords: number;
  loading: boolean;

  referentiel: ReferentielPersonne;
  demandeur: PersonnePhysique;
  editable: boolean;
  display: boolean;

  demandeurId: string;

  demandeurs: PersonnePhysique[];

  @ViewChild("dt") table: Table;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private notificationService: NotificationService,
    private habilitation: PersonneHabilitation,
    private personnePhysiqueService: PersonnePhysiqueService,
    private formService: FormService,
    private authService: AuthService
  ) {
    this.route.queryParams.subscribe((parameters) => {
      if (parameters.id) {
        this.demandeurId = parameters.id;
      }
    });

    this.breadcrumbService.setItems([
      { label: "Personne" },
      {
        label: "Demandeurs",
        routerLink: [RouteConstants.ROUTE_DEMANDEUR],
      },
    ]);
    this.concept = HabilitationConstants.CONCEPT_METIER_PERSONNE;
    this.fonction = HabilitationConstants.FUNCTION_PERSONNE;
    this.userId = this.habilitation.userId();
    this.referentiel = {} as ReferentielPersonne;
    this.referentiel.id = ReferentielConstants.REF_PERS_DEMANDEUR;
    this.demandeur = {} as PersonnePhysique;
    this.demandeur.utilisateur = {} as User;
  }

  ngOnInit(): void {}

  load(event: LazyLoadEvent) {
    this.loading = true;

    // in a real application, make a remote request to load data using state metadata from event
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value
    // imitate db connection over a network

    // let  page = (event.sortField == null ? event.first / event.rows : 0);
    const page = event.first / event.rows;
    const size = event.rows;
    this.params = {
      sort: event.sortField ? event.sortField : "reference",
      direction: event.sortOrder == 1 ? "DESC" : "ASC",
      motcle: event.globalFilter ? event.globalFilter : null,
      referentiel: ReferentielConstants.REF_PERS_DEMANDEUR,
    };

    if (this.motcle) {
      this.params.motcle = this.motcle;
    }
    // Filter by Id
    if (this.demandeurId) {
      this.params.id = this.demandeurId;
    }

    this.personnePhysiqueService
      .search(page, size, this.params)
      .subscribe((data) => {
        if (data && data.content) {
          this.demandeurs = data.content;
          this.totalRecords = data.totalElements;
          this.loading = false;
        } else {
          this.notificationService.errorMessage(null, null);
        }
      });
    this.loading = false;
  }

  add() {
    this.demandeur = {} as PersonnePhysique;
    this.demandeur.utilisateur = {} as User;
    this.editable = true;
    this.display = true;
  }

  view(demandeur: PersonnePhysique, editable = false) {
    this.display = true;
    this.demandeur = demandeur;
    this.editable = demandeur != null && demandeur.etat.modifiable && editable;
  }

  refreshTable() {
    this.table.reset();
  }
  resetFilters() {
    this.motcle = null;
  }

  closeView() {
    this.display = false;
  }

  save(editform: NgForm) {

    if(isNaN(Number(this.demandeur.telephone))){
      this.notificationService.errorMessage(
        "Téléphone est incorrect",
        "Corriger le numéro de téléphone, seuls les caractères numériques sont autorisés!"
      );
      return;
    }
    
    if (this.formService.isNgFormValid(editform)) {
      if (
        this.demandeur.utilisateur.password !=
        this.demandeur.utilisateur.confirmPassword
      ) {
        this.notificationService.errorMessage(
          "Mot de passe est incorrect",
          "Le mot de passe saisi et sa confirmation ne concordent pas!"
        );
        return;
      }
      this.demandeur.utilisateur.firstName = this.demandeur.nom;
      this.demandeur.utilisateur.lastName = this.demandeur.prenoms;
      this.demandeur.utilisateur.email = this.demandeur.email;
      this.demandeur.utilisateur.username = this.demandeur.email;
      this.demandeur.utilisateur.profils = [];
      let profil = {} as Profil;
      profil.id = UserProfilsConstants.PROFIL_DEMANDEUR;
      this.demandeur.utilisateur.profils.push(profil);
      this.demandeur.demandeur=true;

      this.demandeur.referentielPersonne = this.referentiel;
      if (this.demandeur.id) {
        this.personnePhysiqueService
          .save(this.demandeur)
          .subscribe((personne) => {
            if (personne.id) {
              this.notificationService.successMessage();
              this.closeView();
              this.refreshTable();
            } else {
              // something went wrong
              this.notificationService.serverError();
            }
          });
      } else {
        this.authService.register(this.demandeur).subscribe((personne) => {
          if (personne.id) {
            this.notificationService.successMessage(
              "Utilisateur notifié par mail"
            );
            this.closeView();
            this.refreshTable();
          } else {
            // something went wrong
            this.notificationService.serverError();
          }
        });
      }
    }
  }
}
