import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableModule } from "primeng/table";
import { SelectButtonModule } from "primeng/selectbutton";
import { ProgressBarModule } from "primeng/progressbar";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { TimelineModule } from "primeng/timeline";
import { CardModule } from "primeng/card";
import { ToolbarModule } from "primeng/toolbar";
import { StepsModule } from "primeng/steps";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { RadioButtonModule } from "primeng/radiobutton";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CompteModule } from "../compte/compte.module";
import { InputNumberModule } from "primeng/inputnumber";
import { NgHttpLoaderModule } from "ng-http-loader";
import { RouterModule } from "@angular/router";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { SettingModule } from "../../setting/setting.module";
import { AutoCompleteModule } from "primeng/autocomplete";
import { SurvatterComponent } from "./components/survatter/survatter.component";
import { PelComponent } from "./components/pel/pel.component";
import { ListDemandesComponent } from "./components/list-demandes/list-demandes.component";
import { ComptabiliteModule } from "../comptabilite/comptabilite.module";
import { DemandeHabilitation } from "./models/demande-habilitation";
import { PieceStatusComponent } from "./components/pieces-jointes/piece-status/piece-status.component";
import { TagModule } from "primeng/tag";
import { RecapitulatifComponent } from "./components/recapitulatif/recapitulatif.component";
import { DividerModule } from "primeng/divider";
import { PiecesJointesComponent } from "./components/pieces-jointes/pieces-jointes.component";
import { CheckboxModule } from "primeng/checkbox";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { FormulaireComponent } from "./components/formulaire/formulaire.component";
import { DemandePaiementComponent } from "./components/paiement/demande-paiement.component";
import { CalendarModule } from "primeng/calendar";
import { TabViewModule } from "primeng/tabview";
import { ViewPaiementComponent } from './components/pages/view-paiement/view-paiement.component';
import { ViewPiecesComponent } from './components/pages/view-pieces/view-pieces.component';
import { ViewFormulaireComponent } from './components/pages/view-formulaire/view-formulaire.component';
import { ViewDemandeComponent } from "./components/pages/view-demande/view-demande.component";
import { PaiementHabilitation } from "./models/paiement-habilitation";
import { MontantUtils } from "./models/montant-utils";
import { DocumentLivreComponent } from './components/document-livre/document-livre.component';
import { FeedbackComponent } from "./components/feedback/feedback.component";

@NgModule({
  declarations: [
    SurvatterComponent,
    PelComponent,
    ListDemandesComponent,
    PieceStatusComponent,
    RecapitulatifComponent,
    PiecesJointesComponent,
    FormulaireComponent,
    DemandePaiementComponent,
    
    ViewDemandeComponent,
    ViewPaiementComponent,
    ViewPiecesComponent,
    ViewFormulaireComponent,
    DocumentLivreComponent,
    FeedbackComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),

    CompteModule,
    SettingModule,
    ComptabiliteModule,

    TableModule,
    ProgressBarModule,
    SelectButtonModule,
    ButtonModule,
    DialogModule,
    TimelineModule,
    CardModule,
    ToolbarModule,
    StepsModule,
    DropdownModule,
    FileUploadModule,
    RadioButtonModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    AutoCompleteModule,
    TagModule,
    DividerModule,
    CheckboxModule,
    CalendarModule,
    TabViewModule,


    PdfViewerModule,
  ],
  exports: [ListDemandesComponent],
  providers: [DemandeHabilitation, PaiementHabilitation, MontantUtils ],
})
export class DemandeModule {}
