export class FileUtils {
  static isImage(type: string): boolean {
    return type.includes(FileContentType.IMAGE);
  }
  static isPDFDocument(type: string): boolean {
    return type.includes(FileContentType.PDF);
  }
}

export enum FileContentType {
  PDF = "application/pdf",
  IMAGE = "image/",
}
