import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { LogService } from "../services/log.service";
import { NotificationService } from "../services/notification.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private logService: LogService,
    private notificationService: NotificationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = this.authService.getToken();

    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });
    request = request.clone({
      headers: request.headers.set("Access-Control-Allow-Origin", "*"),
    });
    request = request.clone({
      headers: request.headers.set("Access-Control-Allow-Methods", "*"),
    });
    request = request.clone({
      headers: request.headers.set(
        "Access-Control-Allow-Headers",
        "Content-Type"
      ),
    });
    
    // this.logService.log(next.handle(request));

    return next.handle(request).pipe(
      
      catchError((error: HttpErrorResponse) => {
        let errorMsg = "";
        if (error.error instanceof ErrorEvent) {
          this.logService.log("this is client side error");
          errorMsg = `Error: ${error.error.message}`;
        } else {
          this.logService.log("this is server side error");
          // this.notificationService.serverError();
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          if (error instanceof HttpErrorResponse) {
            if (error.status == 403) {
              // Access Denied to user
              this.authService.accessDenied();
            } else if (error.status == 401) {
              // referesh the token
              // this.authService.refreshToken();
              this.authService.logout();
            } else if (error.status === 500) {
              this.logService.log("Bad request - ERROR");
            }
          }
        }
        this.logService.log(errorMsg);
        return throwError(errorMsg);
      })
    );
  }
}
