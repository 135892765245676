import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import {
  CodificationUrgence,
  StatusViewSeverity,
} from "../../../models/codification-constants";
import { ActionNotification, StepPermission } from "../../../models/workflow";
import { ActionService } from "../../../services/workflow/action.service";
import { WorkflowService } from "../../../services/workflow/workflow.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() concept: string;
  @Input() fonction: string;


  @Input() icon: string = "pi pi-envelope";

  @Input() user: string;

  @Input() showNotificationUser: boolean = true;
  @Input() anonymousUser: string = "Anonymous";

  @Input() viewURL: string;

  // every 10 second
  @Input() refresh: number = 10000;

  notifications: ActionNotification[];

  subscription: Subscription;

  constructor(
    private logService: LogService,
    private notificationService: NotificationService,
    private workflowService: WorkflowService,
    private actionService: ActionService
  ) {}

  ngOnInit(): void {
    this.getNotification();
    this.logService.log("refreshNotification: " + this.refresh);
    //emit value in sequence every 10 second
    const source = interval(this.refresh);
    this.subscription = source.subscribe((val) => {
      this.logService.log("UPDATE NOTIFICATIONS START");
      this.getNotification();
      this.logService.log("UPDATE NOTIFICATIONS END");
    });
  }

  getNotification() {
    this.workflowService.notification(this.concept, this.fonction).subscribe((data) => {
      this.logService.log("notification");
      this.logService.log(data);
      this.logService.log("notification");
      this.notifications = data;
    });
  }

  getNotificationSeverity(id: string): string {
    let severity = StatusViewSeverity.danger;
    if (id == CodificationUrgence.BASSE) {
      severity = StatusViewSeverity.info;
    } else if (id == CodificationUrgence.NORMALE) {
      severity = StatusViewSeverity.warning;
    }

    return severity;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
