import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ObjectStateComponent } from "src/app/modules/setting/components/workflow/object-state/object-state.component";
import {
  StatusView,
  StatusViewSeverity,
} from "src/app/modules/setting/models/codification-constants";
import { Demande } from "../../models/demande";
import { DemandeConstants } from "../../models/demande-constants";

import { DemandeHabilitation } from "../../models/demande-habilitation";
import { DemandeService } from "../../services/demande.service";

@Component({
  selector: "app-demande-recapitulatif",
  templateUrl: "./recapitulatif.component.html",
  styleUrls: ["./recapitulatif.component.css"],
})
export class RecapitulatifComponent implements OnInit {
  demande: Demande;
  concept: string;
  fonction: string;
  userId: string;

  statutPieces: StatusView;
  statutPaiement: StatusView;
  @Input() demandeId: string;

  @ViewChild("state") stateComponent: ObjectStateComponent;

  constructor(
    private demandeService: DemandeService,
    private habilitationService: DemandeHabilitation
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_DEMANDE;
    this.fonction = DemandeConstants.FUNCTION_DEMANDE;
    this.userId = this.habilitationService.userId();
  }

  ngOnInit(): void {}

  initParameters() {
    this.statutPieces = {} as StatusView;
    this.statutPieces.label = "Soumis";
    this.statutPieces.severity = StatusViewSeverity.info;

    if (this.demandeId != null) {
      this.demandeService.find(this.demandeId).subscribe((data) => {
        if (!data || !data.id) {
          this.habilitationService.redirectHome();
        }
        this.demande = data;
      });
    }
  }

  ngOnChanges() {
    this.initParameters();
  }

  viewPiece() {}
  viewPaiement() {}
}
