import { HabilitationService } from "src/app/modules/shared/services/habilitation.service";

export class DemandeConstants {
  static FUNCTION_DEMANDE = "SET_FC_DEMANDE";
  static CONCEPT_METIER_DEMANDE = "DEMANDE";
  
  static CONCEPT_METIER_DOCUMENT_DELIVRE = "DOCUMENT_LIVRE";


  static FUNCTION_PAIEMENT = "SET_FC_PAIEMENT";
  static CONCEPT_METIER_PAIEMENT = "PAIEMENT";

  static FUNCTION_PERS_PHYSIQUE = "SET_FC_PERSONNE_PHYSIQUE";
  static FUNCTION_PERS_MORALE = "SET_FC_PERSONNE_MORALE";

  static CONCEPT_PERS_PHYSIQUE = "CONCEPT_METIER_PERSONNE";

  static CONCEPT_METIER_PIECEJONTE = "PIECE_JOINTE";

  static CODIFICATION_MODE_PAIEMENT = "MODE_PAIEMENT";
  static CODIFICATION_OPTION_PAIEMENT = "OPTION_PAIEMENT";
}

export enum TypePaiementConstants {
  TYPE_PAIEMENT_COMPTANT = "TYPE_PAIEMENT_COMPTANT",
  TYPE_PAIEMENT_ONLINE = "TYPE_PAIEMENT_ONLINE",
}

export enum CodificationModePaiement {
  ESPECE = "MODE_PAIEMENT_ESPECE",
  CHEQUE = "MODE_PAIEMENT_CHEQUE",
  CARTE_BANCAIRE = "MODE_PAIEMENT_CARTE",
  VIREMENT = "MODE_PAIEMENT_VIREMENT",
  MOBILE_MONEY = "MODE_PAIEMENT_MOBILE",
}

export enum CodificationOptionPaiement {
  REDEVANCE = "OPTION_PAIEMENT_REDEVANCE",
  TOTALITE = "OPTION_PAIEMENT_TOTALITE",
  RESTE = "OPTION_PAIEMENT_RESTE",
  EXONERATION = "OPTION_PAIEMENT_EXO",
}

export enum TransactionDecision {
  ACCEPT = "ACCEPT",
  DECLINE = "DECLINE",
  REVIEW = "REVIEW",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
}
