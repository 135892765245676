import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import { PersonnePhysique } from "../../models/personne";

const URL_PERSONNE_PHYSIQUE: string = environment.gasAPIUrl + "/personne/pp";
@Injectable({
  providedIn: "root",
})
export class PersonnePhysiqueService extends CrudService {
  ressourceBaseURL(): string {
    return URL_PERSONNE_PHYSIQUE;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  findByUser(idUser: string): Observable<PersonnePhysique> {
    return this.httpClient
      .get<any>(URL_PERSONNE_PHYSIQUE + "/find/" + idUser + "/user")
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  updateProfile(pp: PersonnePhysique): Observable<PersonnePhysique> {
    return this.httpClient
      .post<any>(URL_PERSONNE_PHYSIQUE + "/profile", pp)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }
}
