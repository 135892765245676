export class RouteConstants {
  static ROUTE_HOME = "/";
  static ROUTE_DASHBOARD = "/";
  static ROUTE_LOGIN = "/login";
  static ROUTE_ACCESS_DENIED = "/accessdenied";
  static ROUTE_NOTFOUND = "/notfound";
  static ROUTE_ERROR = "/notfound";

  static ROUTE_DEMANDE = "/demande";
  static ROUTE_DEMANDE_FORMULAIRE = RouteConstants.ROUTE_DEMANDE + "/formulaire";
  static ROUTE_DEMANDE_PAIEMENT = RouteConstants.ROUTE_DEMANDE + "/paiement";
  static ROUTE_DEMANDE_PIECE = RouteConstants.ROUTE_DEMANDE + "/pieces";


  static ROUTE_SURVATTER = "/survatter";
  static ROUTE_PEL = "/pel";

  static ROUTE_COMPTABILITE = "/comptabilite";
  static ROUTE_COMPTABILITE_PAIEMENT =
    RouteConstants.ROUTE_COMPTABILITE + "/paiement";
  // static ROUTE_COMPTABILITE_DEMANDE =
  //   RouteConstants.ROUTE_COMPTABILITE + "/demande";

  static ROUTE_PERSONNE = "/personne";
  static ROUTE_DEMANDEUR = RouteConstants.ROUTE_PERSONNE + "/demandeur";
  static ROUTE_PERSONNE_MORALE = RouteConstants.ROUTE_PERSONNE + "/pm";

  static ROUTE_PERSONNEL = "/personnel";
}

export class HabilitationConstants {
  static FUNCTION_DEMANDE = "SET_FC_DEMANDE";
  static CONCEPT_METIER_DEMANDE = "DEMANDE";

  static FUNCTION_PAIEMENT = "SET_FC_PAIEMENT";
  static CONCEPT_METIER_PAIEMENT = "PAIEMENT";

  static FUNCTION_PERSONNE = "SET_FC_PERSONNE";
  static FUNCTION_PERSONNE_PHYSIQUE = "SET_FC_PERSONNE_PHYSIQUE";
  static FUNCTION_PERSONNE_MORALE = "SET_FC_PERSONNE_MORALE";

  static CONCEPT_METIER_PERSONNE = "PERSONNE";
}

export enum ReferentielConstants {
  REF_PERS_DEMANDEUR = "REF_PERS_DEMANDEUR",
  REF_AGT_ANAC = "REF_AGT_ANAC",
  REF_AGT_ANAC_INS = "REF_AGT_ANAC_INS",
}

export enum UserProfilsConstants {
  PROFIL_DEMANDEUR = "PROFIL_DEMANDEUR",
}

export enum TypeServiceConstants {
  SURVATTER = "SURVATTER",
  PEL = "PEL",
}

export enum TypeDemandeConstants {
  SURVATTER = "SURVATTER",
  PEL_CARTE = "PEL_CARTE",
  PEL_LIC_PNT = "PEL_LIC_PNT",
  PEL_LIC_PNC = "PEL_LIC_PNC",
  PEL_LIC_TMA = "PEL_LIC_TMA",
  PEL_LIC_ATC = "PEL_LIC_ATC",
  PEL_LIC_ATE = "PEL_LIC_ATE",
  PEL_LIC_ULM = "PEL_LIC_ULM",
  PEL_LIC_TPL = "PEL_LIC_TPL",
}

export enum GroupeExtensionConstants {
  GROUPE_EXTENSION_SURVATTER = "SURVATTER_GEXT",
}

export enum CategorieDemandeConstants {
  DEL_AUTO_SURVATTER = "DEL_AUTO_SURVATTER",
}

export enum TYPE_SIGNATURE {
  QRCODE = "QRCODE",
  SIGNATURE = "SIGNATURE",
  SANS_SIGNATURE = "SANS_SIGNATURE",
}

export enum TYPE_DEMANDE {
  SURVATTER = "SURVATTER",
  PEL = "PEL",
}

export enum ExtensionSurvatterConstants {
  SURVATTER_HENT = "SURVATTER_HENT",
  SURVATTER_HEXIT = "SURVATTER_HEXIT",
}

export enum TypeDemandeMontantConstants {
  SURVATTER_0 = "SURVATTER_0",
  SURVATTER_0_3 = "SURVATTER_0.3",
  SURVATTER_3_6 = "SURVATTER_3.6",
}