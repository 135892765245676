declare var jQuery: any;
declare var $: any;

export class Helpers {

	// supprimer les champs de params qui sont null, undefined, ""
	static cleanHttpParams(params: any) {
		const cleanedHttpParams = {};
		Object.keys(params).forEach(param => {
			if (params[param] != null) {
				cleanedHttpParams[param] = params[param];
			}
		});
		return cleanedHttpParams;
	}

	static encodeURIComponent(str) {
		return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
			return '%' + c.charCodeAt(0).toString(16);
		});
	}

	static getFormattedDate(date: Date): string {
		const d = new Date(date);
		return [this.pad(d.getDate()), this.pad(d.getMonth() + 1), d.getFullYear()].join('/')
	}
	static pad(s) { return (s < 10) ? '0' + s : s; }
}

export enum MessageLevel {
	INFO = 'info', WARNING = 'warn', ERROR = 'error', DEBUG = 'debug'
}
