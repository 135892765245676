import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccueilComponent } from './components/accueil/accueil.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { PasswordModule } from 'primeng/password';
import { SplitterModule } from 'primeng/splitter';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { SettingModule } from '../../setting/setting.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CaptchaModule } from 'primeng/captcha';
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';


@NgModule({
  declarations: [
    AccueilComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    SplitterModule,
    RouterModule,
    MenubarModule,
    TieredMenuModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),

    SettingModule,

    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    PasswordModule,
    ToastModule,
    CheckboxModule,
    BreadcrumbModule,
    BadgeModule,
    AvatarModule,
    MenubarModule,
    CaptchaModule,
    TableModule,
    ChartModule,
    DropdownModule
  ]
})
export class HomeModule { }
