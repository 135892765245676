import { Component, OnInit } from "@angular/core";
import { PersonneHabilitation } from "../../gas/compte/models/personne-habilitation";
import { PersonnePhysiqueHabilitation } from "../../gas/compte/models/personne-physique-habilitation";
import { DemandeHabilitation } from "../../gas/demande/models/demande-habilitation";
import { RouteConstants } from "../../gas/demande/models/gas-constants";
import { PaiementHabilitation } from "../../gas/demande/models/paiement-habilitation";
import { ProfilUtilisteur } from "../models/habilitation";
import { AppMainComponent } from "./app.main.component";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: any[];

  constructor(
    public appMain: AppMainComponent,
    private personneHabilitation: PersonneHabilitation,
    private personnePhysiqueHabilitation: PersonnePhysiqueHabilitation,
    private demandeHabilitation: DemandeHabilitation,
    private paiementHabilitation: PaiementHabilitation
  ) { }

  ngOnInit() {
    this.model = [];

    this.model.push({
      label: "Accueil",
      icon: "pi pi-fw pi-home",
      routerLink: ["/"],
    });
    if (this.demandeHabilitation.canRead()) {
      if (
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.AGENT_SURVATTER) ||
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.RESP_SURVATTER) ||
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.AGENT_COMPTABLE) ||
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.RESP_COMPTABLE) ||
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.DG)
      ) {
        this.model.push({
          label: "Survol & Atterissage",
          icon: "pi pi-fw pi-send",
          routerLink: [RouteConstants.ROUTE_SURVATTER],
        });
      }
      if (
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.AGENT_PEL) ||
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.RESP_PEL) ||
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.AGENT_COMPTABLE) ||
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.RESP_COMPTABLE) ||
        this.demandeHabilitation.hasProfil(ProfilUtilisteur.DG)
      ) {
        // this.model.push({
        //   label: "Licence PEL",
        //   icon: "pi pi-fw pi-id-card",
        //   routerLink: [RouteConstants.ROUTE_PEL],
        // });
      }
    }

    if (
      this.paiementHabilitation.canRead()
      &&
      (this.paiementHabilitation.hasProfil(ProfilUtilisteur.AGENT_COMPTABLE) ||
        this.paiementHabilitation.hasProfil(ProfilUtilisteur.RESP_COMPTABLE)
        || this.paiementHabilitation.hasProfil(ProfilUtilisteur.DG)
      )
    ) {
      this.model.push({
        label: "Comptabilité",
        icon: "pi pi-fw pi-money-bill",
        routerLink: [RouteConstants.ROUTE_COMPTABILITE_PAIEMENT],
      });
    }

    if (
      this.personneHabilitation.canRead() &&
      (this.personnePhysiqueHabilitation.hasProfil(
        ProfilUtilisteur.AGENT_SURVATTER
      ) ||
        this.personnePhysiqueHabilitation.hasProfil(
          ProfilUtilisteur.RESP_SURVATTER
        ))
    ) {
      this.model.push({
        label: "Gestion des demandeurs",
        icon: "pi pi-fw pi-user",
        routerLink: [RouteConstants.ROUTE_DEMANDEUR],
      });
      // this.model.push({
      //   label: "Personnes morales",
      //   icon: "pi pi-fw pi-home",
      //   routerLink: [RouteConstants.ROUTE_PERSONNE_MORALE],
      // });
    }
    if (
      this.personneHabilitation.canRead() &&
      this.personnePhysiqueHabilitation.hasProfil(
        ProfilUtilisteur.RESP_PERSONNEL
      )
    ) {
      this.model.push({
        label: "Gestion du personnel",
        icon: "pi pi-fw pi-user-edit",
        routerLink: [RouteConstants.ROUTE_PERSONNEL],
      });
    }

    // this.model = [
    //   {
    //     label: "Tableau de bord",
    //     icon: "pi pi-fw pi-chart-bar",
    //     routerLink: ["/"],
    //   },
    //   {
    //     label: "Survol & Atterissage",
    //     icon: "pi pi-fw pi-send",
    //     routerLink: [RouteConstants.ROUTE_SURVATTER],
    //   },
    //   {
    //     label: "Licence PEL",
    //     icon: "pi pi-fw pi-id-card",
    //     routerLink: [RouteConstants.ROUTE_PEL],
    //   },
    //   {
    //     label: "Comptabilité",
    //     icon: "pi pi-fw pi-percentage",
    //     routerLink: [RouteConstants.ROUTE_COMPTABILITE],
    //     items: [
    //       {
    //         label: "Paiements",
    //         icon: "pi pi-fw pi-money-bill",
    //         routerLink: [RouteConstants.ROUTE_COMPTABILITE_PAIEMENT],
    //       },
    //     ],
    //   },
    //   {
    //     label: "Gestion des personnes",
    //     icon: "pi pi-fw pi-user",
    //     routerLink: [RouteConstants.ROUTE_DEMANDEUR],
    //     items: [
    //       {
    //         label: "Demandeurs",
    //         icon: "pi pi-fw pi-user",
    //         routerLink: [RouteConstants.ROUTE_DEMANDEUR],
    //       },
    //       {
    //         label: "Personnes morales",
    //         icon: "pi pi-fw pi-home",
    //         routerLink: [RouteConstants.ROUTE_PERSONNE_MORALE],
    //       },
    //     ],
    //   },

    //   {
    //     label: "Gestion du personnel",
    //     icon: "pi pi-fw pi-user-edit",
    //     routerLink: [RouteConstants.ROUTE_PERSONNEL],
    //   },
    // ];
  }

  onMenuClick() {
    this.appMain.menuClick = true;
  }
}
