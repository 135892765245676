import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';


@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private notificationService: NotificationService) { }

  log(message: any) {
    if (!environment.production) {
      console.log(message);
    }
  }

  error(objet: any) {
   
    if (!environment.production) {
      console.error(objet);
    } else {
      //  A ecrire dans un fichier
    }
  }

}
