import {
  Habilitation,
  ProfilUtilisteur,
} from "src/app/modules/shared/models/habilitation";
import { HabilitationService } from "src/app/modules/shared/services/habilitation.service";
import { HabilitationConstants } from "./gas-constants";

export class PaiementHabilitation extends HabilitationService {
  getFunction(): string {
    return HabilitationConstants.FUNCTION_PAIEMENT;
  }

  canRead(): boolean {
    return (
      super.canRead() &&
      (this.hasProfil(ProfilUtilisteur.AGENT_COMPTABLE) ||
        this.hasProfil(ProfilUtilisteur.RESP_COMPTABLE)
        || this.hasProfil(ProfilUtilisteur.DG))
    );
  }
}
