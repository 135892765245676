import { HabilitationService } from 'src/app/modules/shared/services/habilitation.service';
import { HabilitationConstants } from '../../demande/models/gas-constants';

export class PersonnePhysiqueHabilitation extends HabilitationService {
	getFunction(): string {
		return HabilitationConstants.FUNCTION_PERSONNE_PHYSIQUE;
	}


}
