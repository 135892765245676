import { Component, OnInit } from '@angular/core';
import { RouteConstants } from 'src/app/modules/gas/demande/models/gas-constants';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service';

@Component({
  selector: 'app-personne-morale',
  templateUrl: './personne-morale.component.html',
  styleUrls: ['./personne-morale.component.css']
})
export class PersonneMoraleComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: "Personne" },
      {
        label: "Personnes Morales",
        routerLink: [RouteConstants.ROUTE_PERSONNE_MORALE],
      }
    ]);
  }

  ngOnInit(): void {
  }

}
