import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service';  
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Paramétrage' },
      { label: 'Sécurité' },
      { label: 'Utilisateurs', routerLink: ['/security/user'] }
    ]);
  }

  ngOnInit(): void {
  }

}
