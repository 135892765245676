import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import { PieceJointe } from "../models/piece";

const URL_PIECE_JOINTE: string = environment.gasAPIUrl + "/piece-jointe";

@Injectable({
  providedIn: "root",
})
export class PieceJointeService extends CrudService {
  ressourceBaseURL(): string {
    return URL_PIECE_JOINTE;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  listByDmde(idDmde: string): Observable<PieceJointe[]> {
    return this.httpClient
      .get<any>(this.ressourceBaseURL() + "/" + idDmde + "/demande")
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  upload(
    idDmde: string,
    idPieceRequise: string,
    file: any
  ): Observable<PieceJointe> {
    const formData: FormData = new FormData();
    formData.append("uploadfile", file, file.name);

    return this.httpClient
      .post<any>(
        this.ressourceBaseURL() +
          "/upload/" +
          idDmde +
          "?idPiece=" +
          idPieceRequise,
        formData
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  downloadPieceJointe(idPiece: string): Observable<any> {
    return this.httpClient
      .get<any>(this.ressourceBaseURL() + "/download/" + idPiece)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  checkByDmde(idDmde: string): Observable<any> {
    return this.httpClient
      .get<any>(this.ressourceBaseURL() + "/check/" + idDmde + "/demande")
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  processPieces(pieces: PieceJointe[]): Observable<boolean> {
    return this.httpClient
      .post<any>(this.ressourceBaseURL() + "/process", { pieces })
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }
}
