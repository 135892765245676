
export class AuthUser {
 id:string;
 personneId:string;
 email: string;
 username:string;
 name:string;
 userRole:string;
 token:string;
 refreshToken:string;
 tokenLifetime:number;
 authorities:string[];
 authProvider:string;
}

export class AuthenticationInfo {
  id: number;
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

export class LoginInfo {
  username: string;
  password: string;
}


export class Habilitation {
  static HABILITATION_READ = 0;
  static HABILITATION_CREATE = 1;
  static HABILITATION_UPDATE = 2;
  static HABILITATION_DELETE = 3;
  static HABILITATION_VALIDATE = 4;

  static HABILITATION_AUDIT = 5;
  static HABILITATION_ADMINISTRATE = 99;
}

export enum ProfilUtilisteur {
  DEMANDEUR = "PROFIL_DEMANDEUR",
  AGENT_SURVATTER = "AGENT_SURVATTER",
  RESP_SURVATTER = "RESP_SURVATTER",
  AGENT_COMPTABLE = "AGENT_ANAC_COMPTA",
  RESP_COMPTABLE = "RESP_ANAC_COMPTA",
  
  RESP_PERSONNEL="RESP_PERSONNEL",

  DG = "DG_ANAC",

  AGENT_PEL = "AGENT_PEL",
  RESP_PEL = "RESP_PEL",

}
