import { User } from "../../security/models/user";
import { ListItem } from "./codification";

export class Workflow {
  id: string;
  numero: number;
  delaiExecution: number;
  description: string;
  libelle: string;
  actionsList: any;
  typeWorkflow: any;
  statut: any;
}

export class Step {
  id: string;
  sequence: number;
  ordre: number;
  libelle: string;
  code: string;
  workflow: Workflow;
  codeCouleur: string;
  modifiable: boolean;
  milestone: boolean;
  delaiExecution: number;
}

export class Action {
  id: string;
  actionLabel: ActionLabel;
  etat: Step;
  etatSuivant: Step;
  codeCouleur: string;
  callbacks: CallBack[];
  habilitation: ActionLabel;
  codeCouleurText: string;
  indicateurCommentaire: ActionLabel;
  infoTransition: ActionLabel;
  profils: any[];
}

export interface ActionLabel {
  id: string;
  code: string;
  codification?: string;
  libelle: string;
  dateActivation?: string;
}

export interface CallBack {
  id: string;
  code: string;
  libelle: string;
  error: string;
  implementation: string;
  numeroOrdre: string;
}

export interface ActionParam {
  user: string;
  conceptMetier: string;
  idObject: string;
  codeFonction: string;
  idAction: string;
  commentaire: string;
  users: string[];
  allAllowedUsers: boolean;
  restrictedProfiles: string[];
  executor: string;
  paramsExecutor: any;
  extensions: any[];
  urgence: ListItem;
}

export interface TrackingItem {
  id: string;
  natureEvent: ListItem;
  idObject: string;
  dateEvent: Date;
  comment: string;
  conceptMetier: ListItem;
  step: Step;
  nextStep: Step;
  utilisateur: User;
  action: Action;
  commentaireAnonyme: string;
}

/**
 * Step notification
 */
export interface StepPermission {
  id: string;
  idObject: string;
  conceptMetier: ListItem;
  step: Step;
  user: User;
  urgence: ListItem;
}

export interface ActionNotification {
  stepPermission: StepPermission;
  tracking: TrackingItem;
}

export enum WorkflowActionViewModel {
  BLOCK = "BLOCK",
  INLINE = "INLINE",
}