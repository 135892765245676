import { Component, Input, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ListItem } from "src/app/modules/setting/models/codification";
import { ListItemService } from "src/app/modules/setting/services/codification/list-item.service";
import { FormService } from "src/app/modules/shared/services/form.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { Demande } from "../../models/demande";
import {
  CodificationModePaiement,
  CodificationOptionPaiement,
  DemandeConstants,
  TypePaiementConstants,
} from "../../models/demande-constants";

import { DemandeHabilitation } from "../../models/demande-habilitation";
import { TypeServiceConstants } from "../../models/gas-constants";
import { Paiement, TypePaiement } from "../../models/paiement";
import { Personne } from "../../models/personne";
import { PieceJointe } from "../../models/piece";
import { DemandeService } from "../../services/demande.service";
import { PaiementService } from "../../services/paiement.service";

@Component({
  selector: "app-demande-paiement",
  templateUrl: "./demande-paiement.component.html",
  styleUrls: ["./demande-paiement.component.css"],
})
export class DemandePaiementComponent implements OnInit {
  @Input() demandeId: string;

  demande: Demande;
  pieces: PieceJointe[];
  paiements: Paiement[];

  typePaiements: TypePaiement[];
  modePaiements: ListItem[];

  selectedPaiement: Paiement;
  paiement: Paiement;
  optionPaiements: ListItem[];

  displayVisa: boolean;
  displayEspece: boolean;
  displayCheque: boolean;
  displayVirement: boolean;

  paiementAcceptation: boolean;
  optionPaiement: string;

  concept: string;
  fonction: string;
  userId: string;

  constructor(
    private notificationService: NotificationService,
    private paiementService: PaiementService,
    private listItemService: ListItemService,
    private demandeService: DemandeService,
    private formService: FormService,
    private habilitationService: DemandeHabilitation,
    private logService: LogService
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_PAIEMENT;
    this.fonction = DemandeConstants.FUNCTION_PAIEMENT;
    this.userId = this.habilitationService.userId();
    this.initPaiement();
  }
  initPaiement() {
    this.paiement = {} as Paiement;
    this.paiement.optionPaiement = {} as ListItem;
    this.paiement.personne = {} as Personne;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.initParameters();
  }

  initParameters() {
    if (this.demandeId) {
      this.demandeService.find(this.demandeId).subscribe((data) => {
        if (!data || !data.id) {
          this.habilitationService.redirectHome();
        }
        this.demande = data;

        this.getPaiements();
      });
    }

    this.paiementService.listTypePaiements().subscribe((data) => {
      this.typePaiements = data;
    });

    this.listItemService
      .listByCodification(DemandeConstants.CODIFICATION_OPTION_PAIEMENT)
      .subscribe((data) => {
        this.optionPaiements = data;
        if (this.demande.categorieDemande?.typeDemande?.typeService) {
          if (
            this.demande.categorieDemande.typeDemande.typeService.code ==
            TypeServiceConstants.SURVATTER
          ) {
            this.optionPaiements = this.optionPaiements.filter(function (op) {
              return (
                op.id == CodificationOptionPaiement.EXONERATION ||
                op.id == CodificationOptionPaiement.TOTALITE
              );
            });
            this.optionPaiement = CodificationOptionPaiement.TOTALITE;
            this.paiement.optionPaiement.id = this.optionPaiement;
          }
        }
      });
  }

  getPaiements() {
    if (this.demande) {
      this.paiement.demande = this.demande;
      this.paiement.devise = this.demande.typeDemandeMontant.devise;
      this.optionPaiement = CodificationOptionPaiement.REDEVANCE;
      this.paiement.personne.id = this.habilitationService.userPersonneId();

      this.paiement.optionPaiement = {} as ListItem;
      if (
        this.demande.categorieDemande.typeDemande.typeService.code ==
        TypeServiceConstants.SURVATTER
      ) {
        this.optionPaiement = CodificationOptionPaiement.TOTALITE;
      }
      this.paiement.optionPaiement.id = this.optionPaiement;

      this.paiementService.listByDmde(this.demande.id).subscribe((data) => {
        if (data && data.length > 0) {
          this.logService.log("paiements");
          this.logService.log(data);

          this.logService.log("paiements");

          this.paiements = data;
          this.optionPaiement = CodificationOptionPaiement.RESTE;
          this.paiement.optionPaiement.id = this.optionPaiement;
        }
        this.getMontantAPayer();
      });
    }
  }

  getOptionPaiement() {
    this.optionPaiements.forEach((o) => {
      if (o.id == this.optionPaiement) {
        this.paiement.optionPaiement = o;
        return;
      }
    });
  }
  getMontantAPayer() {
    this.getOptionPaiement();
    let montantPaye = 0;

    if (this.paiements && this.paiements.length > 0) {
      this.paiements.forEach((p) => {
        montantPaye = montantPaye + p.montant;
      });
    }
    if (
      this.paiement.optionPaiement.id == CodificationOptionPaiement.REDEVANCE
    ) {
      this.paiement.montant = this.demande.typeDemandeMontant.montantRedevance;
    } else if (
      this.paiement.optionPaiement.id == CodificationOptionPaiement.EXONERATION
||  this.paiement.optionPaiement.id == CodificationOptionPaiement.TOTALITE      ) {
      this.paiement.montant = this.demande.typeDemandeMontant.montantTotal;
    } else {
      this.paiement.montant =
        this.demande.typeDemandeMontant.montantTotal - montantPaye;
    }

    if (this.paiement.montant < 0) {
      this.paiement.montant = 0;
      //   this.notificationService.infoMessage('Tous les frais sont payés!');
    }
  }

  isPaiementExonore(): boolean {
    return (
      this.paiement.optionPaiement &&
      this.paiement.optionPaiement.id == CodificationOptionPaiement.EXONERATION
    );
  }

  getPaiement() {
    this.optionPaiements.forEach((o) => {
      if (o.id == this.optionPaiement) {
        this.paiement.optionPaiement = o;
        return;
      }
    });
  }

  isPaiementCarte(): boolean {
    return (
      this.paiement &&
      this.paiement.modePaiement &&
      this.paiement.modePaiement.id == CodificationModePaiement.CARTE_BANCAIRE
    );
  }

  initPaiementVisa() {
    this.displayVisa = true;
  }

  isPaiementMobile(): boolean {
    return (
      this.paiement &&
      this.paiement.modePaiement &&
      this.paiement.modePaiement.id == CodificationModePaiement.MOBILE_MONEY
    );
  }

  selectTypePaiement() {
    this.listItemService
      .listByCodification(DemandeConstants.CODIFICATION_MODE_PAIEMENT)
      .subscribe((data) => {
        this.modePaiements = data;
        if (
          this.paiement.typePaiement.id ==
          TypePaiementConstants.TYPE_PAIEMENT_COMPTANT
        ) {
          this.modePaiements = this.modePaiements.filter(
            (md) => md.id == CodificationModePaiement.ESPECE
          );
        }
        if (
          this.paiement.typePaiement.id ==
          TypePaiementConstants.TYPE_PAIEMENT_ONLINE
        ) {
          this.modePaiements = this.modePaiements.filter(
            (md) => md.id == CodificationModePaiement.CARTE_BANCAIRE
          );
        }

        if (this.modePaiements.length == 1) {
          this.paiement.modePaiement = this.modePaiements[0];
          this.selectModePaiement();
        }
      });
  }

  selectModePaiement() {
    this.logService.log("selectModePaiement");
    this.logService.log(this.paiement.modePaiement);
    this.logService.log("selectModePaiement");

    this.getMontantAPayer();
    if (this.paiement.modePaiement.id == CodificationModePaiement.ESPECE) {
      this.displayEspece = true;
    } else if (
      this.paiement.modePaiement.id == CodificationModePaiement.CHEQUE
    ) {
      this.displayCheque = true;
    } else if (
      this.paiement.modePaiement.id == CodificationModePaiement.VIREMENT
    ) {
      this.displayVirement = true;
    }
  }

  savePaiement(payform: NgForm) {
    if (this.paiement.montant > 0) {
      if (!this.paiementAcceptation) {
        this.notificationService.warningMessage(
          "Validation du paiement",
          "Veuillez accepter les conditions d'utilisations"
        );
      }
      if (this.formService.isNgFormValid(payform)) {
        this.paiementService.save(this.paiement).subscribe((data) => {
          if (data && data.id) {
            this.initPaiement();
            this.paiementAcceptation = false;
            this.getPaiements();
          }
        });
      }
    }
  }
}
