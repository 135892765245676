import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConfirmationService, MenuItem, PrimeIcons } from "primeng/api";
import { User } from "src/app/modules/security/models/user";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { ListItem } from "../../../models/codification";
import { CodificationUrgence } from "../../../models/codification-constants";
import {
  ActionParam,
  Step,
  TrackingItem,
  Action,
  WorkflowActionViewModel,
} from "../../../models/workflow";
import { ListItemService } from "../../../services/codification/list-item.service";
import { ActionService } from "../../../services/workflow/action.service";
import { WorkflowService } from "../../../services/workflow/workflow.service";

@Component({
  selector: "app-object-state",
  templateUrl: "./object-state.component.html",
  styleUrls: ["./object-state.component.scss"],
})
export class ObjectStateComponent implements OnInit {
  @Input() concept: string;

  @Input() object: string;

  @Input() user: string;

  @Input() fonction: string;

  @Input() showAction = true;

  @Input() showUrgence = true;

  @Input() tracking = true;

  @Input() notify = true;

  @Input() label: string;

  @Input() actionViewModel: WorkflowActionViewModel;

  @Input() allAllowedUsers: boolean = false;

  @Input() restrictedProfiles: string[];

  @Input() viewLastComment:boolean = true;
  @Input() noComment:boolean = false;
  @Input() requiredComment:boolean = true;

  @Output() onFinish: EventEmitter<any> = new EventEmitter();

  comment: string;

  users: User[];

  terminaison: boolean = false;

  selectedUsers: User[];

  items: MenuItem[] = [];

  step: Step;

  actions: Action[] = [];

  action: Action;

  urgences: ListItem[];

  urgence: ListItem;
  urgenceRating: number = 2;

  trackings: TrackingItem[] = [];
  lastComment:TrackingItem;

  events: any[];

  display: boolean;

  displayExecution: boolean;

  constructor(
    private logService: LogService,
    private notificationService: NotificationService,
    private workflowService: WorkflowService,
    private listItemService: ListItemService,
    private actionService: ActionService,
    private confirmationService: ConfirmationService
  ) {
    this.logService.log("actionViewModel********************");
    if (!this.actionViewModel) {
      this.actionViewModel = WorkflowActionViewModel.BLOCK;
    }
    this.logService.log(this.actionViewModel);
    this.logService.log("actionViewModel********************");
  }

  ngOnInit(): void {
    this.getObjectState();
    this.initParameters();
  }
  ngOnChanges() {
    this.logService.log("UPDATE ObjectState");
    this.getObjectState();
    this.logService.log("UPDATE ObjectState");
  }

  initParameters() {
    this.urgence = {} as ListItem;
    this.urgence.id = CodificationUrgence.NORMALE;
    this.urgence.code = "2";

    this.listItemService
      .listByCodification(CodificationUrgence.CODIF_URGENCE)
      .subscribe((data) => {
        this.urgences = data;
      });
  }

  getUrgence() {
    this.logService.log(this.urgenceRating);
    this.logService.log("getUrgence code: " + this.urgence.code);

    this.urgences.forEach((u) => {
      if (u.code == this.urgence.code) {
        this.urgence = u;
      }
    });
  }

  getUsers() {
    this.users = [];

    this.actionService
      .findNextUsersByAction(
        this.object,
        this.concept,
        this.action.id,
        this.fonction,
        this.restrictedProfiles
      )
      .subscribe((data) => {
        this.logService.log("getUsers");
        this.logService.log(data);
        this.logService.log("getUsers");
        if (data) {
          this.users = data.users;
          this.terminaison = data.terminaison;
          // if (this.allAllowedUsers) {
          //   this.selectedUsers = this.users;
          // }
        }
      });
  }

  getObjectState() {
    this.logService.log("getObjectState");
    this.logService.log(this.object);
    this.logService.log(this.concept);
    this.logService.log(this.fonction);

    this.workflowService
      .findObjectStep(this.object, this.concept, this.fonction, this.viewLastComment)
      .subscribe((data) => {
        this.logService.log("getObjectState");
        this.logService.log(data);
        this.logService.log("getObjectState");

        this.logService.log("getObjectState.lastComment");
        this.logService.log(data.lastComment);
        this.logService.log("getObjectState.lastComment");

        if (data) {
          this.items = [];
          this.action = null;
          this.step = data.step;
          this.actions = data.actions;
          this.lastComment= data.lastComment as TrackingItem;
          if (this.actions && this.actions.length == 1) {
            this.action = this.actions[0];
          }
          // this.viewTracking();
        } else {
          this.notificationService.serverError();
        }
      });
  }

  resetAction() {
    this.users = [];
    this.displayExecution = false;
  }

  initExecuterAction(event: Event, action) {
    this.logService.log("initExecuterAction ");
    this.logService.log(action);
    this.logService.log("initExecuterAction ");

    if (this.actions.length == 1 && this.noComment) {
      this.confirmExecuterAction(event, action);
      // this.executerAction(action);
    } else {
      this.action = action;
      this.getUsers();
      this.displayExecution = true;
    }
  }

  executerAction(action) {
    this.logService.log("executerAction ");
    this.logService.log(action);
    this.logService.log(this.selectedUsers);
    this.logService.log("executerAction ");

    const actionParam = {} as ActionParam;
    actionParam.idAction = action.id;
    actionParam.commentaire = this.comment;
    actionParam.conceptMetier = this.concept;
    actionParam.user = this.user;
    actionParam.codeFonction = this.fonction;
    actionParam.idObject = this.object;
    actionParam.allAllowedUsers = this.allAllowedUsers;
    actionParam.restrictedProfiles = this.restrictedProfiles;
    actionParam.urgence = this.urgence;

    if (!this.noComment && this.requiredComment && !this.comment) {
      this.notificationService.errorMessage(
        "Commentaire requis",
        "Veuillez mettre un commentaire d'explication! "
      );
      return;
    }

    if (this.selectedUsers && this.selectedUsers.length > 0) {
      actionParam.users = [];
      this.selectedUsers.forEach((user) => {
        actionParam.users.push(user.id);
      });
    } else if (!this.allAllowedUsers && this.users.length > 0) {
      this.logService.log("<app-object-state>: Erreur de configuration ");
      this.notificationService.warningMessage(
        "Veuillez sélectionner les utilisateurs à notifier "
      );
      return;
    }

    this.logService.log("workfow forward ActionParam ");
    this.logService.log(actionParam);
    this.logService.log("workfow forward ActionParam ");

    this.workflowService.executerAction(actionParam).subscribe((data) => {
      this.logService.log("executerAction");
      this.logService.log(data);
      this.logService.log("executerAction");
      if (data) {
        this.notificationService.successMessage();
        this.getObjectState();

        this.executerActionFeedBack(actionParam);

        this.logService.log("onFinish");
        this.onFinish.emit(data);
        this.logService.log("onFinish");
      } else {
        this.logService.log("Callbacks Errors " + action.callbacks.length);
        action.callbacks.forEach((cb) => {
          this.notificationService.errorMessage(cb.error, "");
        });
      }
    });
    this.resetAction();
  }

  executerActionFeedBack(actionParam: ActionParam) {
    this.workflowService
      .executerActionFeedBack(actionParam)
      .subscribe((data) => {
        this.logService.log("executerActionFeedBack");
        this.logService.log(data);
        this.logService.log("executerActionFeedBack");

        if (data) {
          Object.keys(data).forEach((attr) => {
            this.notificationService.warningMessage(data.attr);
          });
        }
      });
  }

  isActionsViewInline(): boolean {
    return this.actionViewModel == WorkflowActionViewModel.INLINE;
  }

  viewTrackingDialog() {
    if(this.tracking){
      this.viewTracking();
      this.display =  true;
    }
  }

  viewTracking() {
    if (this.tracking) {
      this.workflowService
        .tracking(this.object, this.concept)
        .subscribe((data) => {
          this.logService.log("viewTracking");
          this.logService.log(data);
          this.logService.log("viewTracking");

          this.trackings = data;
          this.events = [];
          this.trackings.forEach((trackingItem) => {
            const event = {
              utilisateur: trackingItem.utilisateur,
              status: trackingItem.nextStep.libelle,
              comment: trackingItem.comment,
              date: trackingItem.dateEvent,
              icon: trackingItem.nextStep.milestone
                ? PrimeIcons.CHECK
                : PrimeIcons.ENVELOPE,
              color:
                trackingItem.action && trackingItem.action.codeCouleur
                  ? trackingItem.action.codeCouleur
                  : trackingItem.nextStep.milestone
                    ? "#22C55E"
                    : "#607D8B",
              action: trackingItem.action,
              image: "game-controller.jpg",
            };
            this.events.push(event);
          });
        });
    }
  }

  userAvatarName(user: User): string {
    if (!user) { return; }

    let avatarName = user.firstName?.charAt(0) + user.lastName?.charAt(0);
    return avatarName.toUpperCase();
  }
  userFullName(user: User): string {
    return user ? user.firstName + " " + user.lastName : "";
  }

  confirmExecuterAction(event: Event, action) {
    this.displayExecution = false;

    this.confirmationService.confirm({
      target: event.target,
      header: "Confirmation",
      message: "Etes-vous sûr d'éffectuer cette action?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.executerAction(action);
        this.confirmationService.close();
      },
      reject: () => {
        //reject action
        this.confirmationService.close();
      },
    });
  }
}
