import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { RouteConstants } from "../../gas/demande/models/gas-constants";
import { PersonnePhysique } from "../../gas/demande/models/personne";
import { Profil, User } from "../../security/models/user";
import { AuthenticationInfo, AuthUser, LoginInfo } from "../models/habilitation";
import { LogService } from "./log.service";
import { CacheService } from "./cache.service";


const URL_PERSONNE_REGISTER = `${environment.gasAPIUrl}/personne/pp/register`;

const URL_USER = `${environment.apiUrl}/security/user`;
const URL_USER_ACTIVATION = `${environment.apiUrl}/security/user/activation/key`;

const URL_LOGIN = `${environment.apiUrl}/auth/login`;
const URL_LOGOUT = `${environment.apiUrl}/auth/logout`;
const URL_USER_AUTH_INFO = `${environment.apiUrl}/auth/info`;
const URL_USER_CHECK_ROLE = `${environment.apiUrl}/auth/role`;
const URL_USER_CHECK_CAPTCHA = `${environment.apiUrl}/auth/captcha`;

const URL_REFRESH_TOKEN = `${environment.apiUrl}/auth/token/refresh`;

const URL_USER_PROFIL = environment.apiUrl + "/security/profil";

const ROLE_CONNECTED = `CONNECTED`;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // authorizedEndPoint: any = "/oauth2/authorization/google";
  authUser: AuthUser;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private logService: LogService,
    private cacheService: CacheService
  ) {
    this.initAuthUser();
  }

  initAuthUser() {
    this.authUser = {} as AuthUser;
  }
  register(personne: PersonnePhysique): Observable<PersonnePhysique> {
    return this.httpClient
      .post<PersonnePhysique>(URL_PERSONNE_REGISTER, personne)
      .pipe(
        catchError((error: any): Observable<PersonnePhysique> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  activate(activationKey: String): Observable<User> {
    return this.httpClient
      .post<User>(`${URL_USER_ACTIVATION}/${activationKey}`, null)
      .pipe(
        catchError((error: any): Observable<User> => {
          this.logService.error(error);
          return of(new User());
        })
      );
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return token != null;
  }

  login(username: string, password: string): Observable<AuthenticationInfo> {

    this.initAuthUser();
    const headers_object = new HttpHeaders();
    const httpOptions = {
      headers: headers_object,
    };

    const loginInfo = new LoginInfo();
    loginInfo.username = username;
    loginInfo.password = password;

    return this.httpClient.post<AuthenticationInfo>(URL_LOGIN, loginInfo).pipe(
      tap((authInfo) => {
        if (authInfo.accessToken) {
          // this.createQueues();
        }
      }),
      catchError((error: any): Observable<AuthenticationInfo> => {
        this.logService.error(error);
        return of(new AuthenticationInfo());
      })
    );
  }

  refreshToken() {
    this.httpClient
      .post<AuthenticationInfo>(URL_REFRESH_TOKEN, {})
      .pipe(
        catchError((error: any): Observable<AuthenticationInfo> => {
          this.logService.error(error);
          return of(new AuthenticationInfo());
        })
      )
      .subscribe((authenticationInfo) => {
        if (authenticationInfo.accessToken && authenticationInfo.refreshToken) {
          // authentication successful : we extract the token
          this.setToken(authenticationInfo.accessToken);
          this.setRefreshToken(authenticationInfo.refreshToken);
        } else {
          this.logout();
        }
      });
  }

  checkCaptha(response: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(URL_USER_CHECK_CAPTCHA + "?response=" + response, {})
      .pipe(
        catchError((error: any): Observable<boolean> => {
          this.logService.error(error);
          return of(false);
        })
      );
  }

  sendForgetPwdLink(email: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(URL_USER + "/sendPasswordReset/" + email + "?index=1", {})
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(false);
        })
      );
  }


  sendActivationMail(email: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(URL_USER + "/sendActivationMail/" + email + "?index=1", {})
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(false);
        })
      );
  }

  getUserByResetKey(key: string): Observable<User> {
    return this.httpClient
      .post<User>(`${URL_USER}/resetPassword/key/${key}`, {})
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  resetpassword(user: User): Observable<boolean> {
    return this.httpClient
      .post<boolean>(URL_USER + "/resetPassword" + "?index=0", user)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(false);
        })
      );
  }
  getLoggedUser(): Observable<any> {
    return this.httpClient.post<any>(URL_USER_AUTH_INFO, {}).pipe(
      catchError((error: any): Observable<any> => {
        this.logService.error(error);
        return null;
      })
    );
  }

  accessDenied() {
    if (this.isLoggedIn()) {
      this.removeReturnUrl();
      this.router.navigate([RouteConstants.ROUTE_ACCESS_DENIED]);
    } else {
      this.router.navigate([RouteConstants.ROUTE_LOGIN]);
    }
  }

  logout() {
    this.cacheService.reset();

    if (!this.getToken()) {
      this.router.navigateByUrl(RouteConstants.ROUTE_LOGIN);
    }
    this.httpClient
      .post<boolean>(URL_LOGOUT, {})
      .pipe(
        catchError((error: any): Observable<boolean> => {
          this.logService.error(error);
          return of(false);
        })
      )
      .subscribe((deconnected) => {
        this.authUser = null;
        // this.removeUserData();
        this.router.navigateByUrl(RouteConstants.ROUTE_LOGIN);
      });

    // this.deleteQueues();
  }

  delayLogout(delayMilliSeconds?: number) {
    setTimeout(() => {
      this.logout();
    }, delayMilliSeconds ? delayMilliSeconds : 5000);
  }

  removeUserData() {
    // localStorage.removeItem(environment.storagePrefix + "_user_id");
    // localStorage.removeItem(environment.storagePrefix + "_user_email");
    // localStorage.removeItem(environment.storagePrefix + "_user_name");
    // localStorage.removeItem(environment.storagePrefix + "_name");

    // localStorage.removeItem(environment.storagePrefix + "_user_role");
    // localStorage.removeItem(environment.storagePrefix + "_token");
    // localStorage.removeItem(environment.storagePrefix + "_refresh_token");
    // localStorage.removeItem(environment.storagePrefix + "_token_lifetime");
    // localStorage.removeItem(environment.storagePrefix + "_autorities");
    // localStorage.removeItem(environment.storagePrefix + "_auth_provider");
    this.removeReturnUrl();
  }
  setReturnUrl(url) {
    localStorage.setItem(
      environment.storagePrefix + "_return_url", url
    );
  }

  getReturnUrl() {
    return localStorage.getItem(
      environment.storagePrefix + "_return_url"
    );
  }

  removeReturnUrl() {
    localStorage.removeItem(environment.storagePrefix + "_return_url");
  }
  getAuthProvider() {
    return localStorage.getItem(environment.storagePrefix + "_auth_provider");
  }
  setAuthProvider(provider) {
    localStorage.setItem(
      environment.storagePrefix + "_auth_provider",
      provider
    );
  }

  getUserId(): string {
    // return localStorage.getItem(environment.storagePrefix + "_user_id");
    if (!this.authUser) return;
    return this.authUser.id;
  }
  setUserId(id) {
    // localStorage.setItem(environment.storagePrefix + "_user_id", id);
    if (!this.authUser) return;
    this.authUser.id = id;
  }

  getUserPersonneId(): string {
    // return localStorage.getItem(environment.storagePrefix + "_personne_id");
    if (!this.authUser) return;
    return this.authUser.personneId;
  }
  setUserPersonneId(personneId) {
    // localStorage.setItem(environment.storagePrefix + "_personne_id", id);
    if (!this.authUser) return;
    this.authUser.personneId = personneId;
  }

  getToken(): string {
    // return localStorage.getItem(environment.storagePrefix + "_token");
    if (!this.authUser) return;
    return this.authUser.token;
  }

  setToken(token) {
    // localStorage.setItem(environment.storagePrefix + "_token", token);
    if (!this.authUser) return;
    this.authUser.token = token;
  }

  getTokenLifetime(): number {
    // return localStorage.getItem(environment.storagePrefix + "_token_lifetime");
    if (!this.authUser) return;
    return this.authUser.tokenLifetime;
  }

  setTokenLifetime(tokenLifetime: number) {
    // localStorage.setItem(
    //   environment.storagePrefix + "_token_lifetime",
    //   token_lifetime
    // );
    this.authUser.tokenLifetime = tokenLifetime;
  }

  getRefreshToken(): string {
    // return localStorage.getItem(environment.storagePrefix + "_refresh_token");
    if (!this.authUser) return;
    return this.authUser.refreshToken;
  }
  setRefreshToken(refreshToken) {
    // localStorage.setItem(
    //   environment.storagePrefix + "_refresh_token",
    //   refreshToken
    // );
    this.authUser.refreshToken = refreshToken;

  }

  getUsername(): string {
    // return localStorage.getItem(environment.storagePrefix + "_user_name");
    if (!this.authUser) return;
    return this.authUser.username;
  }

  setUsername(username) {
    // localStorage.setItem(environment.storagePrefix + "_user_name", username);
    this.authUser.username = username;

  }
  getUserFullName(): string {
    // return localStorage.getItem(environment.storagePrefix + "_name");
    if (!this.authUser) return;
    return this.authUser.name;
  }

  setUserFullName(name) {
    // localStorage.setItem(environment.storagePrefix + "_name", name);
    this.authUser.name = name;

  }

  getAuthorizationHeader(): string {
    return `Bearer Access ${this.getToken()};Refresh ${this.getRefreshToken()}`;
  }

  getAuthorities(): string[] {
    // return localStorage.getItem(environment.storagePrefix + "_autorities");
    if (!this.authUser) return;
    return this.authUser.authorities;
  }

  setAuthorities(autorities) {
    // localStorage.setItem(environment.storagePrefix + "_autorities", autorities);
    this.authUser.authorities = autorities;
  }

  authenticated(): boolean {
    const authorities = this.getAuthorities()

    const authenticated = this.getToken();
    if (authorities && authenticated) {
      // return authorities.split(",").includes(ROLE_CONNECTED);;
      return true;
    }
    return false;
  }

  checkHabilitation(fonction: string, niveauHabilitation: number): boolean {
    const authorities = this.getAuthorities();
    const authenticated = this.isLoggedIn();
    if (authorities && authorities.length > 0 && authenticated) {
      // return authorities
      //   .split(",")
      //   .includes(fonction + "." + niveauHabilitation);
      return authorities.includes(fonction + "." + niveauHabilitation);
    }
    return false;
    // let habilitation: any = {};
    // habilitation.fonction = fonction;
    // habilitation.niveau = niveauHabilitation;

    // let response = this.httpClient
    //   .post<boolean>(URL_USER_CHECK_ROLE, habilitation)
    //   .pipe(
    //     catchError((error: any): Observable<boolean> => {
    //       this.logService.error(error);
    //       return of(false);
    //     })
    //   )
    //   .toPromise();
    // return response;
  }

  checkProfilHab(profil: string): boolean {
    return this.isLoggedIn() && this.getAuthorities().includes(profil);
  }

  allProfils(): Observable<Profil[]> {
    return this.httpClient.get<Profil[]>(URL_USER_PROFIL + "/all").pipe(
      catchError((error: any): Observable<Profil[]> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }
}
