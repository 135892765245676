import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { LazyLoadEvent, MessageService } from "primeng/api";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { Demande } from "../../models/demande";
import { DemandeConstants } from "../../models/demande-constants";

import { DemandeHabilitation } from "../../models/demande-habilitation";
import { FileContentType } from "../../models/file-utils";
import { PieceJointe } from "../../models/piece";
import { DemandeService } from "../../services/demande.service";
import { PieceJointeService } from "../../services/piece-jointe.service";
import { PieceRequisService } from "../../services/piece-requis.service";
import { Table } from "primeng/table";
import { saveAs } from "file-saver";
@Component({
  selector: "app-demande-pieces-jointes",
  templateUrl: "./pieces-jointes.component.html",
  styleUrls: ["./pieces-jointes.component.css"],
  providers: [MessageService],
})
export class PiecesJointesComponent implements OnInit {
  @Input() demandeId: string;

  @ViewChild("dt") table: Table;
  currentPage: number=0;
  first:number= 0;

  demande: Demande;
  pieces: PieceJointe[];
  selectedPiece: PieceJointe;

  uploadedFiles: any[] = [];
  checked = false;

  src: string;

  concept: string;
  userId: string;
  fonction: string;

  constructor(
    private notificationService: NotificationService,
    private logService: LogService,
    private pieceRequisService: PieceRequisService,
    private pieceJointeService: PieceJointeService,
    private habilitation: DemandeHabilitation,
    private demandeService: DemandeService
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_PIECEJONTE;
    this.fonction = DemandeConstants.FUNCTION_DEMANDE;
    this.userId = this.habilitation.userId();
  }

  ngOnInit() {}

  ngOnChanges() {
    this.initParameters();
  }

  initParameters() {
    if (this.demandeId != null) {
      this.demandeService.find(this.demandeId).subscribe((data) => {
        if (!data || !data.id) {
          this.habilitation.redirectHome();
        }
        this.demande = data;

        this.getDemandePieceJointes();
      });
    } else {
      this.getDemandePieceJointes();
    }
  }

  resetTable() {
    this.table.reset();
    this.table.first= this.first>0? 0: this.table.totalRecords+1;
    setTimeout(() => this.table.first=this.first, 100);
  }

  
  onPage(event: LazyLoadEvent){
    this.logService.log("OnPage");
    this.logService.log(event)
    this.first= event.first;
    this.currentPage = event.first / event.rows;
    this.logService.log("OnPage - "+ this.currentPage);
  }

  getDemandePieceJointes() {
    if (this.demande && this.demande.id) {
      this.pieces = [] as PieceJointe[];

      this.pieceJointeService.listByDmde(this.demande.id).subscribe((data) => {
        if (data && data.length > 0) {
          this.pieces = data;
        }
        this.pieceRequisService
          .listByCategorieDmde(this.demande.categorieDemande.id)
          .subscribe((pieceRequises) => {
            this.logService.log(pieceRequises);
            if (pieceRequises) {
              pieceRequises.forEach((pr) => {
                this.logService.log(
                  "filterByPieceRequis: " + this.filterByPieceRequis(pr.id)
                );
                if (!this.filterByPieceRequis(pr.id)) {
                  const pj: PieceJointe = {} as PieceJointe;
                  pj.demande = this.demande;
                  pj.pieceRequis = pr;
                  pj.valide = false;
                  this.pieces.push(pj);
                }
              });
            };
            this.pieces.sort((a,b)=>(a.pieceRequis.code > b.pieceRequis.code) ? 1 :
             ((b.pieceRequis.code > a.pieceRequis.code) ? -1 : 0));
          });
      });
    }
    // else {
    //   this.notificationService.errorMessage("Demande non trouvéé");
    // }
  }

  filterByPieceRequis(idPieceRequise: string): boolean {
    let checked = false;
    if (this.pieces && this.pieces.length > 0) {
      this.pieces.forEach((p) => {
        if (p.pieceRequis.id == idPieceRequise) {
          checked = true;
        }
      });
    }
    return checked;
  }

  canNextPage(): boolean {
    let checked = true;
    if (this.demande == null || this.demande.id == null) {
      return false;
    }
    if (this.pieces && this.pieces.length > 0) {
      this.pieces.forEach((pj) => {
        if (pj.document == null || pj.document.id == null) {
          checked = false;
          this.notificationService.errorMessage(
            pj.pieceRequis.piece.libelle,
            "Veuillez charger cette pièce!"
          );
        } else if (!pj.conform) {
          this.notificationService.warningMessage(
            pj.pieceRequis.piece.libelle,
            "Veuillez cocher document conforme!"
          );
          checked = false;
        }
      });
    }

    return checked;
  }

  isPieceSubmitted(piece: PieceJointe): boolean {
    return piece != null && piece.id != null && piece.document != null;
  }

  onRowSelect(event) {
    this.selectedPiece.conform = this.selectedPiece.id != null;
    this.setViewURL();
    this.logService.log(this.src);
  }

  setViewURL() {
    if (this.selectedPiece) {
      this.src =
        this.pieceJointeService.ressourceBaseURL() +
        "/download/" +
        this.selectedPiece.id;
    }
  }

  downloadPieceJointe(piece: PieceJointe) {
    window.open(
      this.pieceJointeService.ressourceBaseURL() +
        "/download/" +
        piece.id +
        "?download=true"
    );
  }

  acceptUploadedFile() {
    this.logService.log("acceptUploadedFile");
    this.logService.log(this.selectedPiece.conform);
    this.logService.log("acceptUploadedFile");

    this.pieces.forEach((pj) => {
      if (pj.id == this.selectedPiece.id) {
        pj.conform = this.selectedPiece.conform;
      }
    });
  }

  upload(piece: PieceJointe) {
    this.pieceJointeService
      .upload(this.demande.id, piece.pieceRequis.id, piece.file)
      .subscribe((data) => {
        this.pieces.forEach((p) => {
          if (p.pieceRequis.id == data.pieceRequis.id) {
            p.id = data.id;
            p.document = data.document;
            this.notificationService.infoMessage(
              "Pièce '" + p.pieceRequis.libelle + "' Soumise "
              // + p.file.name
            );
            this.selectedPiece = p;
            this.setViewURL();
            this.resetTable();
          }
        });
      });
  }

  onSelect(event, piece: PieceJointe) {
    this.logService.log(event.files);
    
    if(event.files.length<1)return;
    // for (const file of event.files) {
      let file=event.files[event.files.length-1];
      this.logService.log(file);
      if (!this.isImage(file.type) && !this.isPDFDocument(file.type)) {
        this.notificationService.errorMessage(
          "Format PDF ou image accepté",
          null
        );
        // break;
      }
      this.logService.log(file);
      piece.file = file;

      this.upload(piece);
      // this.uploadedFiles.push(file);
    // }
   

    // this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }

  isImage(type: string) {
    return type.includes(FileContentType.IMAGE);
  }
  isPDFDocument(type: string) {
    return type.includes(FileContentType.PDF);
  }

  checkPiece(piece: PieceJointe) {
    this.logService.log("checkPiece " + piece.pieceRequis.code);
    this.logService.log("checkPiece" + piece.valide);

    // this.pieces.forEach((pj) => {
    //   if (pj.id == piece.id) {
    //     pj.valide = !pj.valide;
    //   }
    // });
  }

  validatePiece() {
    let validePieces: PieceJointe[] = [];
    this.pieces.forEach((pj) => {
      if (pj.valide) {
        validePieces.push(pj);
      }
      this.pieceJointeService.save(pj).subscribe((data) => {
        if (!data) {
          this.notificationService.serverError();
        }
      });
    });
    this.logService.log("validatePiece: " + validePieces.length);
  }
}
