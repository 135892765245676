import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TraitementComponent } from './components/traitement/traitement.component';
import { StatComponent } from './components/stat/stat.component';
import { ListeDemandesComponent } from './components/liste-demandes/liste-demandes.component';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ButtonModule } from 'primeng/button';
import { DetailDemandeComponent } from './components/detail-demande/detail-demande.component';
import { DialogModule } from 'primeng/dialog';
import { DemandeTimelineComponent } from './components/detail-demande/demande-timeline/demande-timeline.component';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import { SaveDemandeComponent } from './components/save-demande/save-demande.component';
import { ObeservationValidationFormComponent } from './components/obeservation-validation-form/obeservation-validation-form.component';
import { StepsModule } from 'primeng/steps';
import { DropdownModule } from 'primeng/dropdown';
import { SaveDemandeAutreInformationComponent } from './components/save-demande/save-demande-autre-information/save-demande-autre-information.component';
import { SaveDemandePieceJointesComponent } from './components/save-demande/save-demande-piece-jointes/save-demande-piece-jointes.component';
import { SaveDemandeIndentiteComponent } from './components/save-demande/save-demande-indentite/save-demande-indentite.component';
import { SaveDemandeModePaiementComponent } from './components/save-demande/save-demande-mode-paiement/save-demande-mode-paiement.component';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    TraitementComponent,
    StatComponent,
    ListeDemandesComponent,
    DemandeTimelineComponent,
    SaveDemandeComponent,
    SaveDemandeIndentiteComponent,
    SaveDemandeAutreInformationComponent,
    SaveDemandePieceJointesComponent,
    SaveDemandeModePaiementComponent,
    DetailDemandeComponent,
    ObeservationValidationFormComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    ProgressBarModule,
    SelectButtonModule,
    ButtonModule,
    DialogModule,
    TimelineModule,
    CardModule,
    ToolbarModule,
    StepsModule,
    DropdownModule,
    FileUploadModule,
    RadioButtonModule,
    FormsModule

  ],
  exports: [
    DetailDemandeComponent,
    ObeservationValidationFormComponent
  ]
})
export class SurvatterModule { }
