import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { isValidDate } from "@fullcalendar/core";
import { Table } from "primeng/table";
import { FormService } from "src/app/modules/shared/services/form.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { DefaultObjet } from "../../../models/codification";
import { CodificationTypeDonnee } from "../../../models/codification-constants";
import {
  Extension,
  GroupeExtension,
  RechercheCritere,
  RechercheOperation,
  RechercheValeurExtensionCritere,
} from "../../../models/extension";
import { ListItemService } from "../../../services/codification/list-item.service";
import { ExtensionService } from "../../../services/extension/extension.service";
import { GroupeExtensionService } from "../../../services/extension/groupe-extension.service";

@Component({
  selector: "app-search-extensions-form",
  templateUrl: "./search-extensions-form.component.html",
  styleUrls: ["./search-extensions-form.component.css"],
})
export class SearchExtensionsFormComponent implements OnInit {
  @Input() concept: string;

  @Input() gExtensions: string[];

  @Output() notify: EventEmitter<any> = new EventEmitter();

  // groupeExtension: GroupeExtension;
  groupeExtensions: GroupeExtension[] = [];
  rechercheFilter: RechercheValeurExtensionCritere;

  extension: Extension;
  critere: RechercheCritere;
  valeur: any;

  numOperateur: string;
  textOperateur: string;
  itemOperateur: string;
  listOperateur: string;

  numOperateurs: DefaultObjet[];
  textOperateurs: DefaultObjet[];
  itemOperateurs: DefaultObjet[];
  listOperateurs: DefaultObjet[];

  @ViewChild("dtCriteres") dtCriteres: Table;

  constructor(
    private logService: LogService,
    private notificationService: NotificationService,
    private groupeExtensionService: GroupeExtensionService,
    private extensionService: ExtensionService,
    private formService: FormService,
    private listItemService: ListItemService
  ) {}

  ngOnInit(): void {
    this.logService.log("SearchExtensionsFormComponent");
    this.logService.log(this.concept);
    this.logService.log(this.gExtensions);

    // this.gExtensions.push("SURVATTER_GEXT");
    this.logService.log("SearchExtensionsFormComponent");

    if (!this.gExtensions) {
      this.notificationService.serverError("Extensions not found");
    }

    this.extensionService.multiCritereAllParameters().subscribe((data) => {
      this.numOperateurs = data.numOperateurs;
      this.textOperateurs = data.textOperateurs;
      this.itemOperateurs = data.itemOperateurs;
      this.listOperateurs = data.listOperateurs;
    });

    this.initParameters();
    this.resetParameters();
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    // Write your code here
    this.logService.log("### SearchExtensionsFormComponent DATA CHANGE");
    this.initParameters();
    this.logService.log("### SearchExtensionsFormComponent DATA CHANGE");
  }

  notifyRechercheCritere() {
    this.logService.log("notifyRechercheCritere event callback START");
    if (
      this.rechercheFilter.criteres &&
      this.rechercheFilter.criteres.length > 0
    ) {
      this.notify.emit(this.rechercheFilter);
    }
    this.logService.log("notifyRechercheCritere event callback END");
  }

  initParameters() {
    this.groupeExtensions=[];
    this.gExtensions.forEach((gExt) => {
      this.groupeExtensionService.find(gExt).subscribe((data) => {
        this.groupeExtensions.push(data as GroupeExtension);
      });
    });

    if (!this.rechercheFilter) {
      this.rechercheFilter = {} as RechercheValeurExtensionCritere;
      this.rechercheFilter.concept = this.concept;
      this.rechercheFilter.criteres = [];
      this.rechercheFilter.page = 0;
      this.rechercheFilter.size = 10;
    }
  }
  resetParameters() {
    this.numOperateur = null;
    this.textOperateur = null;
    this.itemOperateur = null;
    this.listOperateur = null;
    this.valeur = null;
    this.extension = null;
  }

  refreshTableCriteres() {
    this.dtCriteres.reset();
  }

  selectExtension(extension: Extension) {}

  getOperateurs(extension: Extension) {}

  addExtensionCritere(form: NgForm) {
    if (!this.formService.isNgFormValid(form)) {
      return;
    }
    this.logService.log(this.valeur);

    let operation = {} as RechercheOperation;
    operation.numOperateur = this.numOperateur;
    operation.textOperateur = this.textOperateur;
    operation.itemOperateur = this.itemOperateur;
    operation.listOperateur = this.listOperateur;

    if (this.valeur instanceof Date) {
      operation.valeurDate = this.valeur;
    } else if (Array.isArray(this.valeur)) {
      operation.valeurMultiple = this.valeur;
    } else {
      operation.valeur = this.valeur;
    }

    let critere = {} as RechercheCritere;

    let extId = this.extension.id;
    let newArray = this.rechercheFilter.criteres.filter(function (cr) {
      return cr.extension.id == extId;
    });

    if (newArray.length > 0) {
      let index = this.rechercheFilter.criteres.indexOf(newArray[0]);

      critere = this.rechercheFilter.criteres[index];

      let selectedOperations = critere.operations.filter(function (op) {
        return (
          op.valeur == operation.valeur &&
          op.valeurDate == operation.valeurDate &&
          op.valeurMultiple == operation.valeurMultiple &&
          op.numOperateur == operation.numOperateur &&
          op.textOperateur == operation.textOperateur &&
          op.itemOperateur == operation.itemOperateur &&
          op.listOperateur == operation.listOperateur
        );
      });

      if (selectedOperations.length == 0) {
        critere.operations.push(operation);
      }

      this.rechercheFilter.criteres[index] = critere;
    } else {
      critere.extension = this.extension;
      critere.operations = [];
      critere.operations.push(operation);
      this.rechercheFilter.criteres.push(critere);
      this.refreshTableCriteres();
    }

    this.logService.log(this.rechercheFilter);

    this.resetParameters();
  }

  viewCritereOperationLabel(
    critere: RechercheCritere,
    operation: RechercheOperation
  ): string {
    let label = "";
    if (this.isNumeric(critere.extension)) {
      label = this.numOperateurs.filter(function (op) {
        return op.code == operation.numOperateur;
      })[0].description;
      label = label + " '" + operation.valeur + "'";
    }
    if (this.isDateTime(critere.extension) || this.isDate(critere.extension)) {
      label = this.numOperateurs.filter(function (op) {
        return op.code == operation.numOperateur;
      })[0].description;
      label = label + " '" + operation.valeurDate + "'";
    }

    if (
      this.isAlphaNumeric(critere.extension) ||
      this.isTextArea(critere.extension)
    ) {
      label = this.textOperateurs.filter(function (op) {
        return op.code == operation.textOperateur;
      })[0].description;
      label = label + " '" + operation.valeur + "'";
    }

    if (
      this.isSelect(critere.extension) ||
      this.isTagSelect(critere.extension)
    ) {
      label = this.itemOperateurs.filter(function (op) {
        return op.code == operation.itemOperateur;
      })[0].description;
      let itemLabel = critere.extension.listItems.filter(function (it) {
        return it.id == operation.valeur;
      })[0].libelle;

      label = label + " '" + itemLabel + "'";
    }

    if (this.isMultiSelect(critere.extension)) {
      label = this.itemOperateurs.filter(function (op) {
        return op.code == operation.listOperateur;
      })[0].description;
      let itemLabel = critere.extension.listItems.filter(function (it) {
        return it.id == operation.valeur;
      })[0].libelle;

      label = label + " '" + itemLabel + "'";
    }
    return label;
  }

  removeCritereOperation(
    critere: RechercheCritere,
    operation: RechercheOperation
  ) {
    let extId = critere.extension.id;
    let selectedCriteres = this.rechercheFilter.criteres.filter(function (cr) {
      return cr.extension.id == extId;
    });

    if (selectedCriteres.length > 0) {
      let index = this.rechercheFilter.criteres.indexOf(selectedCriteres[0]);
      critere = this.rechercheFilter.criteres[index];
      let selectedOperations = critere.operations.filter(function (op) {
        return op != operation;
      });
      critere.operations = selectedOperations;
      this.rechercheFilter.criteres[index] = critere;
      if (critere.operations.length == 0) {
        this.removeCritere(critere);
        return;
      }
    }
  }

  removeCritere(critere: RechercheCritere) {
    let extId = critere.extension.id;
    let selectedCriteres = this.rechercheFilter.criteres.filter(function (cr) {
      return cr.extension.id != extId;
    });

    this.rechercheFilter.criteres = selectedCriteres;
    this.refreshTableCriteres();
  }

  isNumeric(extension: Extension): boolean {
    return (
      extension &&
      extension.typeDonnee &&
      extension.typeDonnee.id == CodificationTypeDonnee.NUMERIQUE
    );
  }

  isAlphaNumeric(extension: Extension): boolean {
    return (
      extension &&
      extension.typeDonnee &&
      extension.typeDonnee.id == CodificationTypeDonnee.ALPHANUMERIQUE
    );
  }
  isTextArea(extension: Extension): boolean {
    return (
      extension &&
      extension.typeDonnee &&
      extension.typeDonnee.id == CodificationTypeDonnee.TEXT
    );
  }
  isDateTime(extension: Extension): boolean {
    return (
      extension &&
      extension.typeDonnee &&
      extension.typeDonnee.id == CodificationTypeDonnee.DATETIME
    );
  }

  isDate(extension: Extension): boolean {
    return (
      extension &&
      extension.typeDonnee &&
      extension.typeDonnee.id == CodificationTypeDonnee.DATE
    );
  }
  isSelect(extension: Extension): boolean {
    return (
      extension &&
      extension.typeDonnee &&
      extension.typeDonnee.id == CodificationTypeDonnee.LIST
    );
  }

  isMultiSelect(extension: Extension): boolean {
    return (
      extension &&
      extension.typeDonnee &&
      extension.typeDonnee.id == CodificationTypeDonnee.MULTIVALUE
    );
  }

  isTreeSelect(extension: Extension): boolean {
    return extension.typeDonnee.id == CodificationTypeDonnee.CLASSIFICATION;
  }

  isTagSelect(extension: Extension): boolean {
    return (
      extension &&
      extension.typeDonnee &&
      extension.typeDonnee.id == CodificationTypeDonnee.ETIQUETTE
    );
  }
}
