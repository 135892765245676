import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service';
@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Paramétrage' },
      { label: 'Sécurité' },
      { label: 'Profil des utilisateurs', routerLink: ['/security/profil'] }
    ]);
  }

  ngOnInit(): void {
  }

}
