import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { PaiementHabilitation } from "src/app/modules/gas/demande/models/paiement-habilitation";
import { Habilitation, ProfilUtilisteur } from "../../models/habilitation";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthComptabiliteGuard implements CanActivate {
  constructor(
    private router: Router,
    private paiementHabilitation: PaiementHabilitation,
    private authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this.authService.isLoggedIn() &&
      this.paiementHabilitation.canRead() &&
      (this.paiementHabilitation.hasProfil(ProfilUtilisteur.AGENT_COMPTABLE) ||
        this.paiementHabilitation.hasProfil(ProfilUtilisteur.RESP_COMPTABLE)
        ||  this.paiementHabilitation.hasProfil(ProfilUtilisteur.DG))
    ) {
      return true;
    }
    this.authService.setReturnUrl(state.url);
    this.authService.accessDenied();
    return false;
  }
}
