import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-demande',
  templateUrl: './save-demande.component.html',
  styleUrls: ['./save-demande.component.css']
})
export class SaveDemandeComponent implements OnInit {

  stepsItems:any[];
  @Input() saveDemandeStep = 0;
  constructor() { }

  ngOnInit(): void {
    this.stepsItems = [
      {
          label: 'Informations Générale'
      },
      {
          label: 'Pièces jointes'
      },
      {
          label: 'Paiement'
      },
      {
        label: 'Résumé'
    }
  ];
  }

}
