import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-demande-indentite',
  templateUrl: './save-demande-indentite.component.html',
  styleUrls: ['./save-demande-indentite.component.css']
})
export class SaveDemandeIndentiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
