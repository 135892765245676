import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import jsPDF from "jspdf";
import * as QRCode from "qrcode";
import { ValeurExtension } from "src/app/modules/setting/models/extension";
import { Demande } from "../models/demande";
import { CodificationTypeDonnee } from "src/app/modules/setting/models/codification-constants";
import { ExtensionService } from "src/app/modules/setting/services/extension/extension.service";
import { ListItemService } from "src/app/modules/setting/services/codification/list-item.service";

const URL_FORMULAIRE: string = environment.gasAPIUrl + "/formulaire";

@Injectable({
  providedIn: "root",
})
export class FormulaireService extends CrudService {
  ressourceBaseURL(): string {
    return URL_FORMULAIRE;
  }

  constructor(
    public httpClient: HttpClient,
    public logService: LogService,
    private extensionService: ExtensionService,
    private listItemService: ListItemService
  ) {
    super(httpClient, logService);
  }

  printFormulaire(demande: Demande, valeurs: ValeurExtension[]) {
    const margin = {
      x: 13,
      y: 10,
    };
    const doc = new jsPDF("p", "mm", "a4");

    const totalPagesExp = "{total_pages_count_string}";
    const scale = 8;

    doc.setFontSize(8);
    doc.setTextColor(40);
    doc.setFont("Helvetica", "bold");

    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    // ENTETE DE L'ATTESTATION
    const img = new Image();
    img.src = `${environment.logoBaseUrl}/ANAC.jpg`;

    if (img) {
      doc.addImage(
        img,
        "PNG",
        width / 2 - 40 / 2,
        margin.y + 5,
        40,
        30,
        undefined,
        "FAST"
      );
      doc.saveGraphicsState();
      doc.setGState(doc.GState({ opacity: 0.2 }));
      doc.addImage(
        img,
        "PNG",
        width / 2 - 100 / 2,
        height / 2 - 100 / 2,
        100,
        100,
        undefined,
        "FAST"
      );
      doc.setGState(doc.GState({ opacity: 1 }));
    }

    const MINISTERE =
      "MINISTERE DES TRANSPORTS ROUTIERS, AERIENS ET FERROVIAIRES";
    doc.text(MINISTERE, 75 / 2 + margin.x, margin.y, {
      maxWidth: 75,
      align: "center",
    });
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(10);
    doc.text(
      "AGENCE NATIONALE DE L’AVIATION CIVILE",
      75 / 2 + margin.x,
      margin.y + 12,
      { maxWidth: 100, align: "center" }
    );
    
    doc.text(
      "ANAC/DG/DTA/SRETA",
      75 / 2 + margin.x,
      margin.y + 20,
      { maxWidth: 100, align: "center" }
    );

    doc.text("REPUBLIQUE TOGOLAISE", width - (60 / 2 + margin.x), margin.y, {
      maxWidth: 60,
      align: "center",
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      "Travail - Liberté - Patrie",
      width - (60 / 2 + margin.x),
      margin.y + 5,
      { maxWidth: 100, align: "center" }
    );

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14);

    // TITRE DE L'ATTESTATION
    const ATTESTATION_TITLE_START = 45;
    // const FR_TITLE = ;
    // const EN_TITLE = ;

    const TITRES: string[] = [
      "Demande d'autorisation / Permit Request",
      demande.reference,
    ];

    TITRES.forEach((title, index) => {
      const TITLE = title;
      const FR_TITLE_START_X = width / 2 - doc.getTextWidth(TITLE) / 2;

      doc.text(
        TITLE,
        FR_TITLE_START_X,
        margin.y + ATTESTATION_TITLE_START + index * 8,
        {
          maxWidth: doc.getTextWidth(TITLE) + 3,
        }
      );
      doc.line(
        FR_TITLE_START_X,
        margin.y + ATTESTATION_TITLE_START + index * 8 + 1,
        FR_TITLE_START_X + doc.getTextWidth(TITLE),
        margin.y + ATTESTATION_TITLE_START + index * 8 + 1
      );
    });

    //

    doc.setFontSize(12);

   let type = valeurs.find((v) => v.extension.id == "SURVATTER_TYPE");

    let recuInfos: { nom: string; value: string }[] = [
      { nom: type.extension.propriete.libelle, value: type.valeurItem.libelle },

      // { nom: "Type: ", value: `Atterrissage` },
    ];

    const selectedValeurs = [
      "SURVATTER_APPL",
      "SURVATTER_NB",
      "SURVATTER_NAT",
      "SURVATTER_IMMA",
      "SURVATTER_MED",
      "SURVATTER_PAT",
      "SURVATTER_CREW",
      "SURVATTER_CDBD",
      "SURVATTER_NBPA",
      "SURVATTER_NBPR",
      "SURVATTER_FLOBJ",
      "SURVATTER_NATFT",
      "SURVATTER_ITI",
      "SURVATTER_PAC",
      "SURVATTER_INFO"
    ]
    selectedValeurs.forEach( vid => {
      const vext = valeurs.find((v) => v.extension.id == vid)
      if(vext){
        const valeur = this.extensionService.getvaleur(vext)
        recuInfos.push({
          nom: vext.extension.propriete.libelle + ": ",
          value: valeur,
        });
      }
    })
    

    const start = width / 12;
    const itemStart = height / 2 - 75;
    recuInfos.forEach((e, index) => {
      if (e.value) {
        let itemScale = index * 8;

        const nameWidth =
          doc.getTextWidth(e.nom) + doc.getTextWidth(e.nom)*0.1;

        doc.setFont("Helvetica", "bold");
        doc.text(e.nom, start, itemScale + itemStart, {
          maxWidth: nameWidth,
        });
        doc.setFont("Helvetica", "normal");

        doc.text(e.value, start + nameWidth + 3, itemScale + itemStart, {
          maxWidth: 100,
        });
      }
    });

    const bodyFooterStartY = 60;
    // POSTE ET NOM
    doc.setFont("Helvetica", "bold");

    let roles: string[] = ["LE DIRECTEUR GENERAL", "(The General Director)"];
    let DG_NOM_START_Y = 0;
    roles.forEach((role, index) => {
      DG_NOM_START_Y =
        recuInfos.length * 5 + itemStart + index * 10 + bodyFooterStartY + 8;
      doc.text(role, width - width / 4, DG_NOM_START_Y, {
        maxWidth: 50,
        align: "center",
      });
    });
    const NOM = "";
    doc.text(NOM, width - width / 4, DG_NOM_START_Y + 20, {
      maxWidth: 50,
      align: "center",
    });

    // Code QR

    QRCode.toDataURL(demande.id, function (err, url) {
      const qrcodeImg = new Image();
      qrcodeImg.src = url;
      doc.addImage(
        qrcodeImg,
        "PNG",
        start - 5,
        itemStart + recuInfos.length * 5 + bodyFooterStartY,
        40,
        40,
        undefined,
        "FAST"
      );
    });

    // PIED DE PAGE
    const INFOLINE_HEIGHT = 10;
    doc.setFillColor(47, 85, 151);
    const rectY = height - 10 - margin.y;
    doc.rect(margin.x, rectY, width - margin.x * 2, INFOLINE_HEIGHT, "F");
    doc.setFontSize(9);
    doc.setTextColor("white");
    doc.setFont("Helvetica", "bold");
    doc.text(
      "BP : 2699 Lomé – Tél : 22 26 37 40/22 26 85 97 – Fax : 22 26 08 60 – E-mail :survatter@yahoo.fr – survatter@anac-togo.tg",
      width / 2,
      rectY + INFOLINE_HEIGHT / 2 + 1,
      { maxWidth: width, align: "center" }
    );

    const date = new Date();
    // this.converter.convert(220000)

    try {
      // window.open(doc.output('bloburl').toString(), '_blank');
      doc.save(`FORM-${demande.reference}.pdf`);
    } catch (error) {
      this.logService.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }
}
