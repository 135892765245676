import { Component, OnInit } from "@angular/core";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { GroupeExtensionConstants, RouteConstants, TypeServiceConstants } from "../../models/gas-constants";


@Component({
  selector: "app-survatter",
  templateUrl: "./survatter.component.html",
  styleUrls: ["./survatter.component.css"],
})
export class SurvatterComponent implements OnInit {
  typeService: string;
  groupeExtension: string;

  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: "Survol & Atterissage" },
      {
        label: "Autorisations",
        routerLink: [RouteConstants.ROUTE_SURVATTER],
      },
    ]);

    this.typeService = TypeServiceConstants.SURVATTER;
    this.groupeExtension = GroupeExtensionConstants.GROUPE_EXTENSION_SURVATTER;
  }

  ngOnInit(): void {}
}
