import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { ListItem } from "src/app/modules/setting/models/codification";
import { ListItemService } from "src/app/modules/setting/services/codification/list-item.service";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { FormService } from "src/app/modules/shared/services/form.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { DemandeConstants } from "../../../demande/models/demande-constants";
import {
  HabilitationConstants,
  ReferentielConstants,
  RouteConstants,
} from "../../../demande/models/gas-constants";
import { MontantUtils } from "../../../demande/models/montant-utils";
import { Paiement, TypePaiement } from "../../../demande/models/paiement";
import { PersonnePhysique } from "../../../demande/models/personne";
import { PaiementService } from "../../../demande/services/paiement.service";

import { PersonnePhysiqueService } from "../../../demande/services/personne/personne-physique.service";
import { ComptabiliteHabilitation } from "../../models/comptabilite-habilitation";
import { saveAs } from "file-saver";

@Component({
  selector: "app-paiement",
  templateUrl: "./paiement.component.html",
  styleUrls: ["./paiement.component.css"],
})
export class PaiementComponent implements OnInit {
  motcle: string;
  concept: string;
  objectId: string;
  userId: string;
  fonction: string;

  params: any;
  totalRecords: number;
  loading: boolean;

  totalMontantPaiement: number = 0;
  totalMontantPaiementAccepted: number = 0;

  paiement: Paiement;
  editable: boolean;
  display: boolean;

  paiements: Paiement[];
  demandeurs: PersonnePhysique[];
  demandeurRecherche: PersonnePhysique;

  modePaiements: ListItem[];
  modePaiement: string;

  optionPaiements: ListItem[];
  optionPaiement: string;

  typePaiements: TypePaiement[];
  typePaiement: string;

  dateDebut: Date;
  dateFin: Date;

  paiementId: string;
  effectivePaid: boolean= true;

  @ViewChild("dt") table: Table;

  constructor(
    private listItemService: ListItemService,
    private breadcrumbService: BreadcrumbService,
    private notificationService: NotificationService,
    private habilitation: ComptabiliteHabilitation,
    private paiementService: PaiementService,
    private formService: FormService,
    private logService: LogService,
    private personnePhysiqueService: PersonnePhysiqueService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((parameters) => {
      logService.log("parameters:");
      logService.log(parameters);
      logService.log("parameters:");

      if (parameters.id) {
        this.paiementId = parameters.id;
      }
    });
    this.breadcrumbService.setItems([
      { label: "Comptabilite" },
      {
        label: "Liste des paiements",
        routerLink: [RouteConstants.ROUTE_COMPTABILITE_PAIEMENT],
      },
    ]);
    this.concept = HabilitationConstants.CONCEPT_METIER_PAIEMENT;
    this.fonction = HabilitationConstants.FUNCTION_PAIEMENT;
    this.userId = this.habilitation.userId();
    this.paiement = {} as Paiement;

    this.initParameters();
  }

  ngOnInit(): void { }

  initParameters() {
    this.paiementService.listTypePaiements().subscribe((data) => {
      this.typePaiements = data;
    });

    this.listItemService
      .listByCodification(DemandeConstants.CODIFICATION_MODE_PAIEMENT)
      .subscribe((data) => {
        this.modePaiements = data;
      });

    this.listItemService
      .listByCodification(DemandeConstants.CODIFICATION_OPTION_PAIEMENT)
      .subscribe((data) => {
        this.optionPaiements = data;
      });

    this.getTotalAmounts();
  }

  resetFilters() {
    this.modePaiement = null;
    this.optionPaiement = null;
    this.typePaiement = null;
    this.motcle = null;
    this.demandeurRecherche = null;
    this.dateDebut = null;
    this.dateFin = null;
  }

  load(event: LazyLoadEvent) {
    this.loading = true;

    // in a real application, make a remote request to load data using state metadata from event
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value
    // imitate db connection over a network

    // let  page = (event.sortField == null ? event.first / event.rows : 0);
    const page = event.first / event.rows;
    const size = event.rows;
    this.params = {
      sort:[ "demande.createdDate", (event.sortField ? event.sortField : "demande.reference"), 
       "modePaiement.code" ],
      direction: event.sortOrder == 1 ? "DESC" : "ASC",
      motcle: event.globalFilter ? event.globalFilter : null,
    };

    if (this.effectivePaid) {
      this.params.effectivePaid = this.effectivePaid;
    }

    if (this.paiementId) {
      this.params.id = this.paiementId;
    }

    if (this.motcle) {
      this.params.motcle = this.motcle;
    }
    this.logService.log("demandeurRecherche");
    this.logService.log(this.demandeurRecherche);
    this.logService.log(this.dateDebut);
    this.logService.log(this.dateFin);

    this.logService.log("demandeurRecherche");
    if (this.optionPaiement) {
      this.params.optionPaiement = this.optionPaiement;
    }
    if (this.modePaiement) {
      this.params.modePaiement = this.modePaiement;
    }
    if (this.typePaiement) {
      this.params.typePaiement = this.typePaiement;
    }

    if (this.demandeurRecherche && this.demandeurRecherche.id) {
      this.params.personne = this.demandeurRecherche.id;
    }

    if (this.dateDebut) {
      this.params.dateDebut = this.dateDebut.toISOString();
    }
    if (this.dateFin) {
      if (this.dateDebut && this.dateDebut > this.dateFin) {
        this.notificationService.errorMessage("Paramètres",
          "La date de fin doit supérieure à la date de debut"
        );
        return;
      }
      this.params.dateFin = this.dateFin.toISOString();
    }
    this.logService.log(this.params);

    this.paiementService.search(page, size, this.params).subscribe((data) => {
      if (data && data.content) {
        this.paiements = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
        this.totalMontantPaiement = 0;
        this.totalMontantPaiementAccepted = 0;

        this.paiements.forEach((p) => {
          this.totalMontantPaiement = this.totalMontantPaiement + p.montant;

          if (p.etat.milestone) {
            this.totalMontantPaiementAccepted =
              this.totalMontantPaiementAccepted + p.montant;
          }
        });
      } else {
        this.notificationService.errorMessage(null, null);
      }
    });
    this.loading = false;
  }

  totalAmount: number = 0;
  totalAmountValide: number = 0;

  getTotalAmounts() {
    let params: any = {};
    if (this.optionPaiement) {
      this.params.optionPaiement = this.optionPaiement;
    }
    if (this.modePaiement) {
      params.modePaiement = this.modePaiement;
    }
    if (this.typePaiement) {
      params.typePaiement = this.typePaiement;
    }

    if (this.optionPaiement) {
      params.optionPaiement = this.optionPaiement;
    }

    if (this.demandeurRecherche && this.demandeurRecherche.id) {
      params.personne = this.demandeurRecherche.id;
    }

    if (this.dateDebut) {
      params.dateDebut = this.dateDebut.toISOString();
    }
    if (this.dateFin) {
      params.dateFin = this.dateFin.toISOString();
    }
    // let params:any  = {};
    // params = this.params
    // this.paiementService.total(params).subscribe(amount=> this.totalAmount=amount);

    params.valide = true;
    this.paiementService.total(params).subscribe(amount => {
      this.totalAmountValide = amount ? amount : 0
    });

  }

  add() {
    this.paiement = {} as Paiement;
    this.editable = true;
    this.display = true;
  }

  view(paiement: Paiement, editable = false) {
    this.display = true;
    this.paiement = paiement;
    this.editable = paiement != null && paiement.etat.modifiable && editable;
  }

  refreshTable() {
    this.table.reset();
  }

  closeView() {
    this.display = false;
  }

  save(editform: NgForm) {
    if (this.formService.isNgFormValid(editform)) {
      if (this.paiement.id) {
        this.paiementService.save(this.paiement).subscribe((p) => {
          if (p.id) {
            this.notificationService.successMessage();
            this.closeView();
            this.refreshTable();
          } else {
            // something went wrong
            this.notificationService.serverError();
          }
        });
      }
    }
  }

  searchDemandeur(event) {
    let params = {
      sort: event.sortField ? event.sortField : "nom",
      direction: event.sortOrder == 1 ? "DESC" : "ASC",
      motcle: event.query ? event.query : null,
      referentiel: ReferentielConstants.REF_PERS_DEMANDEUR,
    };

    this.personnePhysiqueService.search(0, 10, params).subscribe((data) => {
      if (data && data.content) {
        this.demandeurs = data.content;
        this.demandeurs.forEach((d) => {
          d.fullname = d.nom + " " + d.prenoms;
        });
      } else {
        this.notificationService.errorMessage(null, null);
      }
    });
  }

  selectDemandeur(demandeur) {
    this.logService.log("selectDemandeur");
    this.logService.log(demandeur);
    this.logService.log(this.demandeurRecherche);
    this.logService.log("selectDemandeur");
  }

  printInvoice(paiement: Paiement) {
    // this.paiementService.printRecu(paiement);

    this.paiementService
      .downloadRecu(paiement.id, true)
      .subscribe((response) => {
        this.logService.log(response);
        // let downloadURL = window.URL.createObjectURL(new Blob(resultBlob.body, {type: "application/pdf"}));
        // window.open(downloadURL);
        var mediaType = "application/pdf";
        var blob = new Blob([response.body], { type: response.body.type });
        saveAs(blob, "RECU.pdf");
      });
    return;
  }
}
