export class User {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  fullName: string;
  active: boolean;
  password: string;

  administrator: boolean;

  activationKey: string;
  activationExpireDate: any;
  resetKey: string;
  resetKeyExpireDate: any;

  authorities: any[];
  profils: Profil[];
  // Transient attributes
  confirmPassword: string;
}

export interface Profil {
  id: string;
  code: string;
  intitule: string;
  active: boolean;
  habilitations: Habilitation[];
}

export interface Habilitation {
  id: string;
  profil: string;
  fonction: any;
  niveauHabilitation: string;
}
