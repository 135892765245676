import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-traitement',
  templateUrl: './traitement.component.html',
  styleUrls: ['./traitement.component.css']
})
export class TraitementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
