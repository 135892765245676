import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { Demande } from "../../../models/demande";
import { DemandeHabilitation } from "../../../models/demande-habilitation";
import {
  RouteConstants,
  TypeServiceConstants,
} from "../../../models/gas-constants";
import { DemandeService } from "../../../services/demande.service";

@Component({
  selector: "app-view-demande",
  templateUrl: "./view-demande.component.html",
  styleUrls: ["./view-demande.component.css"],
})
export class ViewDemandeComponent implements OnInit {
  demande: Demande;
  constructor(
    private route: ActivatedRoute,
    private demandeService: DemandeService,
    private habilitationService: DemandeHabilitation,
    private breadcrumbService: BreadcrumbService,
    private logService: LogService
  ) {
    this.route.queryParams.subscribe((params) => {
      const id = params.id;
      this.logService.log("ViewDemandeComponent: " + id);
      if (id) {
        this.demandeService.find(id).subscribe((data) => {
          if (!data || !data.id) {
            this.habilitationService.redirectHome();
          }
          this.demande = data;
          let listUrl = "";
          if (
            this.demande.categorieDemande.typeDemande.typeService.code ==
            TypeServiceConstants.SURVATTER
          ) {
            listUrl = RouteConstants.ROUTE_SURVATTER;
          } else if (
            this.demande.categorieDemande.typeDemande.typeService.code ==
            TypeServiceConstants.PEL
          ) {
            listUrl = RouteConstants.ROUTE_PEL;
          }
          this.breadcrumbService.setItems([
            {
              label: this.demande.categorieDemande.typeDemande.libelle,
              routerLink: listUrl,
            },
            { label: this.demande.reference },
          ]);
        });
      } else {
        this.habilitationService.redirectHome();
      }
    });
  }

  ngOnInit(): void {}
}
