import {Component} from '@angular/core';
import { BreadcrumbService } from '../modules/shared/components/breadcrumb.service';  

@Component({
    templateUrl: './display.component.html'
})
export class DisplayComponent {
    constructor(private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            { label: 'Utilities' },
            { label: 'Display', routerLink: ['/utilities/display'] }
        ]);
    }
}
