import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaiementComponent } from "./components/paiement/paiement.component";
import { TableModule } from "primeng/table";
import { SelectButtonModule } from "primeng/selectbutton";
import { ProgressBarModule } from "primeng/progressbar";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { TimelineModule } from "primeng/timeline";
import { CardModule } from "primeng/card";
import { ToolbarModule } from "primeng/toolbar";
import { StepsModule } from "primeng/steps";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { RadioButtonModule } from "primeng/radiobutton";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SurvatterModule } from "../survatter/survatter.module";
import { CompteModule } from "../compte/compte.module";
import { ComptabiliteHabilitation } from "./models/comptabilite-habilitation";
import { InputNumberModule } from "primeng/inputnumber";
import { NgHttpLoaderModule } from "ng-http-loader";
import { RouterModule } from "@angular/router";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { SettingModule } from "../../setting/setting.module";
import { AutoCompleteModule } from "primeng/autocomplete";
import { PaiementStatusComponent } from "./components/paiement/paiement-status/paiement-status.component";
import { TagModule } from "primeng/tag";
import { DemandeModule } from "../demande/demande.module";
import { CalendarModule } from "primeng/calendar";
import { PaiementTransactionComponent } from "./components/paiement/paiement-transaction/paiement-transaction.component";
import { InputSwitchModule } from "primeng/inputswitch";

@NgModule({
  declarations: [
    PaiementComponent,
    PaiementStatusComponent,
    PaiementTransactionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),

    SurvatterModule,
    CompteModule,
    SettingModule,

    TableModule,
    ProgressBarModule,
    SelectButtonModule,
    ButtonModule,
    DialogModule,
    TimelineModule,
    CardModule,
    ToolbarModule,
    StepsModule,
    DropdownModule,
    FileUploadModule,
    RadioButtonModule,
    InputNumberModule,
    InputTextModule,
    InputSwitchModule,
    InputTextareaModule,
    AutoCompleteModule,
    TagModule,
    CalendarModule,
  ],
  exports: [PaiementStatusComponent, PaiementTransactionComponent],
  providers: [ComptabiliteHabilitation],
})
export class ComptabiliteModule {}
