export enum CodificationTypeDonnee {
  NUMERIQUE = "TYPE_DONNEE_NUM",
  ALPHANUMERIQUE = "TYPE_DONNEE_ALNUM",
  DATE = "TYPE_DONNEE_DATE",
  DATETIME = "TYPE_DONNEE_DATETIME",
  TEXT = "TYPE_DONNEE_TEXT",
  MULTIVALUE = "TYPE_DONNEE_MULTI",
  LIST = "TYPE_DONNEE_LIST",
  CLASSIFICATION = "TYPE_DONNEE_CLASSIFICATION",
  ETIQUETTE = "TYPE_DONNEE_ETIQUETTE",
}

export enum CodificationUrgence {
  CODIF_URGENCE = "URGENCE",
  BASSE = "URGENCE_BASSE",
  NORMALE = "URGENCE_NORMALE",
  ELEVEE = "URGENCE_ELEVEE",
  TRES_ELEVEE = "URGENCE_TRES_ELEVEE",
  IMMEDIATE = "URGENCE_IMMEDIATE",
}

export interface StatusView {
  id: string;
  severity: StatusViewSeverity;
  label: string;
}

export enum StatusViewSeverity {
  success = "success",
  info = "info",
  warning = "warning",
  danger = "danger",
}
