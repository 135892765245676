import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import {
  ActionNotification,
  ActionParam,
  StepPermission,
  TrackingItem,
} from "../../models/workflow";

const URL_WORKFLOW: string = environment.apiUrl + "/workflow";

@Injectable({
  providedIn: "root",
})
export class WorkflowService extends CrudService {
  ressourceBaseURL(): string {
    return URL_WORKFLOW;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  findObjectStep(
    idObject: string,
    cpt: string,
    codeFonction?: string,
    lastComment?: boolean
    
  ): Observable<any> {
    let url =
      this.ressourceBaseURL() + "/find-current-etat/" + cpt + "/" + idObject;
    if (codeFonction) {
      url = url + "?codeFonction=" + codeFonction;
      if (lastComment) {
        url = url + "&lastComment=" + lastComment;
      }
    }
   

    return this.httpClient.get<any>(url, {}).pipe(
      catchError((error: any): Observable<any> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }

  executerAction(actionParam: ActionParam): Observable<any> {
    return this.httpClient
      .post<any>(this.ressourceBaseURL() + "/executer-action", actionParam)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }
  executerActionFeedBack(actionParam: ActionParam): Observable<any> {
    return this.httpClient
      .post<any>(this.ressourceBaseURL() + "/executer-feedback", actionParam)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  tracking(idObject: string, cpt: string): Observable<TrackingItem[]> {
    return this.httpClient
      .get<any>(
        this.ressourceBaseURL() + "/tracking/" + cpt + "/" + idObject,
        {}
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  notification(
    cpt: string,
    fonction: string,
    excludeValidation?: boolean
  ): Observable<ActionNotification[]> {
    let url = this.ressourceBaseURL() + "/notification/" + cpt + "/" + fonction;
    if (excludeValidation != null) {
      url = url + "?excludeValidation=" + excludeValidation;
    }
    return this.httpClient.get<any>(url, {}).pipe(
      catchError((error: any): Observable<any> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }

  ownNotification(
    cpt: string,
    fonction: string
  ): Observable<ActionNotification[]> {
    return this.httpClient
      .post<any>(
        this.ressourceBaseURL() + "/notification/" + cpt + "/user/" + fonction,
        {}
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }
}
