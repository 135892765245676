import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableModule } from "primeng/table";
import { ProgressBarModule } from "primeng/progressbar";
import { SelectButtonModule } from "primeng/selectbutton";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { TimelineModule } from "primeng/timeline";
import { CardModule } from "primeng/card";
import { ToolbarModule } from "primeng/toolbar";
import { StepsModule } from "primeng/steps";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RadioButtonModule } from "primeng/radiobutton";
import { FileUploadModule } from "primeng/fileupload";
import { DropdownModule } from "primeng/dropdown";
import { ListeComptesComponent } from "./components/liste-comptes/liste-comptes.component";
import { DetailCompteComponent } from "./components/detail-compte/detail-compte.component";
import { SurvatterModule } from "../survatter/survatter.module";
import { CreerCompteComponent } from "./components/creer-compte/creer-compte.component";
import { RouterModule } from "@angular/router";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { PasswordModule } from "primeng/password";
import { ToastModule } from "primeng/toast";
import { DemandeurComponent } from "./components/personne/demandeur/demandeur.component";
import { PersonneMoraleComponent } from "./components/personne/personne-morale/personne-morale.component";
import { PersonnelComponent } from "./components/personne/personnel/personnel.component";
import { NgHttpLoaderModule } from "ng-http-loader";
import { PersonneHabilitation } from "./models/personne-habilitation";
import { SettingModule } from "../../setting/setting.module";
import { PersonnePhysiqueHabilitation } from "./models/personne-physique-habilitation";
import { PersonneMoraleHabilitation } from "./models/personne-morale-habilitation";
import { MultiSelectModule } from "primeng/multiselect";
import { ActivationComponent } from "./components/activation/activation.component";
import { ForgetPasswordComponent } from "./components/forget-password/forget-password.component";
import { MonCompteComponent } from "./components/mon-compte/mon-compte.component";
import { RecoveryComponent } from "./components/forget-password/recovery/recovery.component";

@NgModule({
  declarations: [
    ListeComptesComponent,
    DetailCompteComponent,
    MonCompteComponent,
    CreerCompteComponent,
    DemandeurComponent,
    PersonneMoraleComponent,
    PersonnelComponent,
    ActivationComponent,
    ForgetPasswordComponent,
    RecoveryComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),

    SettingModule,
    SurvatterModule,

    TableModule,
    ProgressBarModule,
    SelectButtonModule,
    ButtonModule,
    DialogModule,
    TimelineModule,
    CardModule,
    ToolbarModule,
    StepsModule,
    DropdownModule,
    FileUploadModule,
    RadioButtonModule,

    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    PasswordModule,
    ToastModule,
    MultiSelectModule,

    NgHttpLoaderModule.forRoot(),
  ],
  providers: [PersonneHabilitation, PersonnePhysiqueHabilitation, PersonneMoraleHabilitation],
})
export class CompteModule {}
