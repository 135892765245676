import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service';  

@Component({
    selector: 'app-liste-demandes',
    templateUrl: './liste-demandes.component.html',
    styleUrls: ['./liste-demandes.component.scss']
})
export class ListeDemandesComponent implements OnInit {

    statutsDemandes: any[];
    demandes: any[];
    displayDetail: boolean;
    displayForm: boolean;
    displayValidation: boolean;
    saveDemandeStep: number = 0;
    saveDemandeMinMaxStep = [0, 3];


    @ViewChild('dt') table: Table;

    selectedStatuts: any;


    constructor(private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            { label: 'SURVATTER' },
            { label: 'Liste des demandes', routerLink: ['/survatter/liste-demandes'] }
        ]);
        this.statutsDemandes = [
            { id: 1, icon: 'pi pi-circle-on', libelle: "Demande en attentes" },
            { id: 2, icon: 'pi pi-circle-on', libelle: "Demande en cours" },
            { id: 3, icon: 'pi pi-circle-on', libelle: "Demande en validées" },
            { id: 4, icon: 'pi pi-circle-on', libelle: "Demande rejetées" }
        ];

    }


    ngOnInit() {
        this.demandes = [
            { ref: "A", nom: "N", prenom: "P", createdAt: "10/14/2021" },
            { ref: "B", nom: "N1", prenom: "P1", createdAt: "" },
            { ref: "C", nom: "N2", prenom: "P2", createdAt: "" },
            { ref: "D", nom: "N3", prenom: "P3", createdAt: "" },
            { ref: "E", nom: "N4", prenom: "P4", createdAt: "" },
            { ref: "F", nom: "N5", prenom: "P5", createdAt: "" }
        ]
        this.selectStatuts(this.statutsDemandes[0])
    }

    selectStatuts(selectedStatuts: any) {
        this.selectedStatuts = selectedStatuts;
        this.demandes = this.getDemandes(this.selectedStatuts.id);
    }

    getDemandes(id: number) {
        switch (id) {
            case 1:
                return [
                    { ref: "222A", nom: "N", prenom: "P", createdAt: "10/14/2021" },
                    { ref: "665B", nom: "N1", prenom: "P1", createdAt: "" },
                    { ref: "854C", nom: "N2", prenom: "P2", createdAt: "" },
                    { ref: "642D", nom: "N3", prenom: "P3", createdAt: "" },
                    { ref: "E854", nom: "N4", prenom: "P4", createdAt: "" },
                    { ref: "F891", nom: "N5", prenom: "P5", createdAt: "" }
                ];
            case 2:
                return [
                    { ref: "A1111", nom: "N", prenom: "P", createdAt: "10/14/2021" },
                    { ref: "B2222", nom: "N1", prenom: "P1", createdAt: "" },
                    { ref: "C3333", nom: "N2", prenom: "P2", createdAt: "" },
                    { ref: "D4444", nom: "N3", prenom: "P3", createdAt: "" },
                    { ref: "E5555", nom: "N4", prenom: "P4", createdAt: "" },
                    { ref: "F6666", nom: "N5", prenom: "P5", createdAt: "" }
                ];
            case 3:
                return [
                    { ref: "R5f11", nom: "N", prenom: "P", createdAt: "10/14/2021" },
                    { ref: "TS262", nom: "N1", prenom: "P1", createdAt: "" },
                    { ref: "RD521", nom: "N2", prenom: "P2", createdAt: "" },
                    { ref: "85RDF", nom: "N3", prenom: "P3", createdAt: "" },
                    { ref: "HYD6", nom: "N4", prenom: "P4", createdAt: "" },
                    { ref: "FRS6", nom: "N5", prenom: "P5", createdAt: "" }
                ];
            case 4:
                return [
                    { ref: "SXT11", nom: "N", prenom: "P", createdAt: "10/14/2021" },
                    { ref: "B2222", nom: "N1", prenom: "P1", createdAt: "" },
                    { ref: "CFSC3", nom: "N2", prenom: "P2", createdAt: "" },
                    { ref: "785R4", nom: "N3", prenom: "P3", createdAt: "" },
                    { ref: "ODFH5", nom: "N4", prenom: "P4", createdAt: "" },
                    { ref: "56PL6", nom: "N5", prenom: "P5", createdAt: "" }
                ];
            default:
                return [];
        }
    }

    nextSaveDemandeStep() {
        if (this.saveDemandeStep < this.saveDemandeMinMaxStep[1]) {
            this.saveDemandeStep += 1;
        }
    }
    previousSaveDemandeStep() {
        if (this.saveDemandeStep > this.saveDemandeMinMaxStep[0]) {
            this.saveDemandeStep -= 1;
        }
    }

}
