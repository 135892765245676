import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CrudService } from 'src/app/modules/shared/services/crud.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { environment } from 'src/environments/environment';
import { Paiement, TypePaiement } from '../models/paiement';
import * as QRCode from "qrcode";
import jsPDF from 'jspdf';
import { MontantUtils } from '../models/montant-utils';
import { Helpers } from 'src/app/modules/shared/models/helpers';

const URL_PAIEMENT: string = environment.gasAPIUrl + '/demande/paiement';
const URL_TYPE_PAIEMENT: string = environment.gasAPIUrl + '/type-paiement';


@Injectable({
  providedIn: 'root'
})
export class PaiementService extends CrudService {


  ressourceBaseURL(): string {
    return URL_PAIEMENT;
  }

  constructor(
    public httpClient: HttpClient, 
    public logService: LogService,
    private montantUtils: MontantUtils
    ) {
    super(httpClient, logService);
  }

  listByDmde(idDmde: string, valide?: boolean): Observable<Paiement[]> {
    let url = this.ressourceBaseURL() + '/' + idDmde + '/demande';
    if (valide != null) {
      url = url + "?valide=" + valide;
    }
    return this.httpClient.get<any>(url).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  amontByDmde(idDmde: string, valide?: boolean): Observable<number> {
    let url = this.ressourceBaseURL() + '/amount/' + idDmde + '/demande';
    if (valide != null) {
      url = url + "?valide=" + valide;
    }
    return this.httpClient.get<any>(url).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  listTypePaiements(): Observable<TypePaiement[]> {
    return this.httpClient.get<any>(URL_TYPE_PAIEMENT + '/all'
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  
  transactionAmount(idPaiement: string, accepted: boolean): Observable<number> {
    let url = this.ressourceBaseURL() + "/amount/" + idPaiement + "/transaction";
    url = url + "?accepted=" + accepted;
    return this.httpClient.post<any>(url, {}).pipe(
      catchError((error: any): Observable<any> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }

  total( params: any = {}): Observable<number> {
    const cleanedParams = Helpers.cleanHttpParams(params);
    return this.httpClient
      .get<Number>(
        this.ressourceBaseURL() + "/amount/total", { params: cleanedParams }
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  transactions(idPaiement: string): Observable<any[]> {
    let url = this.ressourceBaseURL() + "/" + idPaiement + "/transactions";
    // url = url + "?accepted=" + accepted;
    return this.httpClient.post<any>(url, {}).pipe(
      catchError((error: any): Observable<any[]> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }

  downloadRecu(idPaiement: string, download?:boolean): Observable<any> {
    let url =this.ressourceBaseURL() + "/recu/" + idPaiement;
    if(download){
      url =url+"?download="+download;
    }
    return this.httpClient
      .get<any>(url, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  printRecu(paiement: Paiement) {
    const margin = {
      x: 13,
      y: 15,
    };
    const doc = new jsPDF("p", "mm", "a4");

    const totalPagesExp = "{total_pages_count_string}";
    const scale = 8;

    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    // ENTETE DE L'ATTESTATION
    const img = new Image();
    img.src = `${environment.logoBaseUrl}/ANAC.jpg`;

    if (img) {
      doc.addImage(
        img,
        "PNG",
        width / 2 - 40 / 2,
        margin.y + 5,
        40,
        30,
        undefined,
        "FAST"
      );
      doc.saveGraphicsState();
      doc.setGState(doc.GState({ opacity: 0.2 }));
      doc.addImage(
        img,
        "PNG",
        width / 2 - 100 / 2,
        height / 2 - 100 / 2,
        100,
        100,
        undefined,
        "FAST"
      );
      doc.setGState(doc.GState({ opacity: 1 }));
    }

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(10);
    const MINISTERE =
      "MINISTERE DES TRANSPORTS ROUTIERS, AERIENS ET FERROVIAIRES";
    doc.text(MINISTERE, 75 / 2 + margin.x, margin.y, {
      maxWidth: 75,
      align: "center",
    });
    doc.setFontSize(8);
    doc.setTextColor(40);
    doc.text(
      "AGENCE NATIONALE DE L’AVIATION CIVILE",
      75 / 2 + margin.x,
      margin.y + 12,
      { maxWidth: 100, align: "center" }
    );
    
    doc.text(
      "ANAC/DG/DTA/SRETA",
      75 / 2 + margin.x,
      margin.y + 16,
      { maxWidth: 100, align: "center" }
    );

    doc.text("REPUBLIQUE TOGOLAISE", width - (60 / 2 + margin.x), margin.y, {
      maxWidth: 60,
      align: "center",
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      "Travail - Liberté - Patrie",
      width - (60 / 2 + margin.x),
      margin.y + 5,
      { maxWidth: 100, align: "center" }
    );

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14);

    // TITRE DE L'ATTESTATION
    const ATTESTATION_TITLE_START = 45;
    // const FR_TITLE = ;
    // const EN_TITLE = ;

    const TITRES:string[] = ["Reçu de la demande / Receipt of the request"]
    
    TITRES.forEach(
      (title, index) => {
        const TITLE = title;
        const FR_TITLE_START_X = (width / 2)-(doc.getTextWidth(TITLE)/2);

        doc.text(TITLE, FR_TITLE_START_X, margin.y + ATTESTATION_TITLE_START+ (index*8), {
          maxWidth: doc.getTextWidth(TITLE)+3,
        });
        doc.line(
          FR_TITLE_START_X,
          margin.y + ATTESTATION_TITLE_START + (index*8) + 1,
          FR_TITLE_START_X+doc.getTextWidth(TITLE),
          margin.y + ATTESTATION_TITLE_START + (index*8) + 1
        );
      }
    )


    // 
    doc.setFontSize(10);
    let validite=`${paiement.demande.typeDemandeMontant.delaiValidite} mois`;
    if(paiement.demande.typeDemandeMontant.delaiValidite==0){
      validite=`Autorisation ponctuelle`;
    }
    if(paiement.demande.typeDemandeMontant.delaiValidite==3){
      validite=`Autorisation permanente de 0 à 3 mois`;
    }
    if(paiement.demande.typeDemandeMontant.delaiValidite==6){
      validite=`Autorisation permanente de 3 à 6 mois`;
    }
    if(paiement.demande.typeDemandeMontant.delaiValidite==12){
      validite=`Autorisation permanente de 6 à 12 mois`;
    }
    let recuInfos:{nom:string, value:string}[] = [
      {nom:"Numéro de la demande / Request number: ", value: paiement.demande.reference},
      {nom:"Date de la demande / Request date: ", value: Helpers.getFormattedDate(paiement.demande.dateDemande)},
      {nom:"Objet / Subject: ", value: paiement.demande.categorieDemande.libelle},
      {nom:"Nom & Prénom / Full Name: ", value: paiement.demande.personne.fullname},
      {nom:"Pour: ", value:validite},
      {nom:"Montant / Amount: ", value:`${paiement.montant} ${paiement.devise.libelle}`},
    ]
    const start = width / 6;
    const itemStart = ((height/2)-55);
    recuInfos.forEach(
      (e, index) => {
        let itemScale = (index*8);
        const nameWidth = doc.getTextWidth(e.nom)>100 ? 300: doc.getTextWidth(e.nom)+3;
        doc.setFont("Helvetica", "bold");
        doc.text(e.nom, start, itemScale + itemStart, {
          maxWidth: nameWidth,
        });
        doc.setFont("Helvetica", "normal");
     
        doc.text(e.value!=null ? e.value:"", start + nameWidth + 3, itemScale + itemStart,
          { maxWidth: 100 }
        );
      }
    );

     // POSTE ET NOM
     doc.setFont("Helvetica", "bold");

    //  let roles:string[] = [
    //    "LE DIRECTEUR GENERAL",
    //    "(The General Director)",
    //  ]
     let roles:string[] = [
      "SERVICE FINANCE ",
      "(Financial service)",
    ];
     let DG_NOM_START_Y = 0;
     let DG_NOM = environment.nomSignataireRecu;
     roles.forEach(
       (role, index) => {
        DG_NOM_START_Y = recuInfos.length*23 + itemStart + index*8;
        doc.text(
          role,
          width - width / 4,
          DG_NOM_START_Y,
          { maxWidth: 50, align: "center" }
        );
       }
     )
     
     doc.text(
      DG_NOM,
      width - width / 4,
      DG_NOM_START_Y + 20,
      { maxWidth: 50, align: "center" }
    );
   
    doc.text('Arrêté le '+Helpers.getFormattedDate(new Date()) +' à la somme de '+ this.montantUtils.NumberToLetter(paiement.montant)+' '+ paiement.devise.libelle, start, itemStart + recuInfos.length*15, {
      maxWidth: 400
    }); 
    // Code QR
    const QRCODE = `attestation.footer?.titrePoste?.qrCode`;
    QRCode.toDataURL(
      QRCODE,
      function (err, url) {
        const qrcodeImg = new Image();
        qrcodeImg.src = url;
        doc.addImage(
          qrcodeImg,
          "PNG",
          start - 5,
          itemStart + recuInfos.length*22,
          40,
          40,
          undefined,
          "FAST"
        );
      }
    );

     

    // PIED DE PAGE
    const INFOLINE_HEIGHT = 10;
    doc.setFillColor(47, 85, 151);
    const rectY = height - 10 - margin.y;
    doc.rect(margin.x, rectY, width - margin.x * 2, INFOLINE_HEIGHT, "F");
    doc.setFontSize(9);
    doc.setTextColor("white");
    doc.setFont("Helvetica", "bold");
    doc.text(
      "BP : 2699 Lomé – Tél : 22 26 37 40/22 26 85 97 – Fax : 22 26 08 60 – E-mail :survatter@yahoo.fr – survatter@anac-togo.tg",
      width / 2,
      rectY + INFOLINE_HEIGHT / 2 + 1,
      { maxWidth: width, align: "center" }
    );

    const date = new Date();
    // this.converter.convert(220000)
 
    try {
      // window.open(doc.output('bloburl').toString(), '_blank');
      
      doc.save(`RECU-${paiement.code}.pdf`);
    } catch (error) {
      this.logService.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }


}
