import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { WorkflowActionViewModel } from "src/app/modules/setting/models/workflow";
import { ExtensionService } from "src/app/modules/setting/services/extension/extension.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import {
  CategorieDemande,
  Demande,
  DocumentLivre,
  TypeDemande,
  TypeDemandeMontant,
} from "../../models/demande";
import { DemandeConstants } from "../../models/demande-constants";
import { DemandeHabilitation } from "../../models/demande-habilitation";
import {
  GroupeExtensionConstants,
  ReferentielConstants,
  RouteConstants,
  TypeServiceConstants,
  TYPE_SIGNATURE,
} from "../../models/gas-constants";
import { PaiementHabilitation } from "../../models/paiement-habilitation";
import { PersonnePhysique } from "../../models/personne";
import { CategorieDemandeService } from "../../services/categorie-demande.service";
import { DemandeService } from "../../services/demande.service";
import { FormulaireService } from "../../services/formulaire.service";
import { PersonnePhysiqueService } from "../../services/personne/personne-physique.service";
import { TypeDemandeMontantService } from "../../services/type-demande-montant.service";
import { TypeDemandeService } from "../../services/type-demande.service";

import { saveAs } from "file-saver";
import { FileContentType, FileUtils } from "../../models/file-utils";
import {
  GroupeExtension,
  RechercheValeurExtensionCritere,
} from "src/app/modules/setting/models/extension";
import { SearchExtensionsFormComponent } from "src/app/modules/setting/components/extension/search-extensions-form/search-extensions-form.component";
import { Formulaire } from "../../models/formulaire";
import { FileUpload } from "primeng/fileupload";
import { FormulaireComponent } from "../formulaire/formulaire.component";

const RECHERCHE_TOUS_DOC_LIVRES = "Toute autorisation/attestation";

@Component({
  selector: "app-list-demandes",
  templateUrl: "./list-demandes.component.html",
  styleUrls: ["./list-demandes.component.css"],
})
export class ListDemandesComponent implements OnInit {
  @ViewChild("dt") table: Table;
  @ViewChild("uploadDocSigne") fileUploadDocSigne: FileUpload;


  @Input() typeService: string;
  @Input() typeDemande: string;

  @Input() editForm: boolean = false;
  @Input() editPaiement: boolean = false;
  @Input() processPiece: boolean = false;
  @Input() processChecklist: boolean = false;
  // @Input() groupeExtension: string;

  @ViewChild("searchFilterForm")
  searchFilterForm: SearchExtensionsFormComponent;

  @ViewChild("formulaire")
  formulaire: FormulaireComponent;


  motcle: string;
  motcleDocumentLivre: string;
  documentLivreRech: boolean;
  concept: string;
  conceptDocumentLivre: string;
  objectId: string;
  userId: string;
  fonction: string;

  rechercheDocLivreOptions: string[] = [RECHERCHE_TOUS_DOC_LIVRES];

  filterGroupeExtensions: string[] = [];

  params: any;
  totalRecords: number;
  loading: boolean;
  demandes: Demande[];

  selectedDemande: Demande;

  demandeurs: PersonnePhysique[];
  demandeurRecherche: PersonnePhysique;

  categorieDemandes: CategorieDemande[];
  categorieDemande: string = null;

  typeDemandeMontants: TypeDemandeMontant[];
  typeDemandeMontant: string;

  typeDemandes: TypeDemande[];

  dateDebut: Date;
  dateFin: Date;

  displayRecaptulatif: boolean;
  displayPieces: boolean;
  displayPaiement: boolean;
  displayFormulaire: boolean;
  displayCheckList: boolean;
  displayPrintConfirmation: boolean;
  displayDocLivre: boolean;
  documentLivreEditable: boolean;
  displayUploadDocSigne: boolean;
  displayFeedback: boolean;

  docLivreURL: string;
  docSigneURL: string;

  demandeId: string;
  actionViewModelInline: WorkflowActionViewModel =
    WorkflowActionViewModel.INLINE;

  typeSignature: string;
  optionSignatures: { value: string; libelle: string }[] = [
    {
      value: TYPE_SIGNATURE.QRCODE,
      libelle: "Je valide avec une signature éléctronique",
    },
    //  { value: TYPE_SIGNATURE.SIGNATURE, libelle: "Je valide" },
    { value: TYPE_SIGNATURE.SANS_SIGNATURE, libelle: "Aucune signature" },
  ];

  // @ViewChild("recapitulatif") recapitulatifComponent: RecapitulatifComponent;
  // @ViewChild("paiement") paiementComponent: DemandePaiementComponent;
  // @ViewChild("formulaire") formulaireComponent: FormulaireComponent;

  displayRechMulticritere: boolean;



  constructor(
    private logService: LogService,
    private notificationService: NotificationService,
    public demandeHabilitation: DemandeHabilitation,
    public paiementHabilitation: PaiementHabilitation,
    private demandeService: DemandeService,
    private typeDemandeService: TypeDemandeService,
    private categorieDemandeService: CategorieDemandeService,
    private personnePhysiqueService: PersonnePhysiqueService,
    private typeDemandeMontantService: TypeDemandeMontantService,
    private formulaireService: FormulaireService,
    private route: ActivatedRoute,
    private router: Router,
    private extensionService: ExtensionService
  ) {
    this.route.queryParams.subscribe((parameters) => {
      if (parameters.id) {
        this.demandeId = parameters.id;
        demandeService.find(this.demandeId).subscribe((dmde) => {
          let url = RouteConstants.ROUTE_NOTFOUND;
          if (dmde && dmde.categorieDemande) {
            if (
              dmde.categorieDemande.typeDemande.typeService.id ==
              TypeServiceConstants.SURVATTER
            ) {
              url = RouteConstants.ROUTE_SURVATTER + "?id=" + this.demandeId;
            } else if (
              dmde.categorieDemande.typeDemande.typeService.id ==
              TypeServiceConstants.PEL
            ) {
              url = RouteConstants.ROUTE_PEL + "?id=" + this.demandeId;
            }
            this.router.navigateByUrl(url);
          }
        });
      }
    });
    this.concept = DemandeConstants.CONCEPT_METIER_DEMANDE;
    this.conceptDocumentLivre =
      DemandeConstants.CONCEPT_METIER_DOCUMENT_DELIVRE;

    this.fonction = DemandeConstants.FUNCTION_DEMANDE;
    this.userId = this.demandeHabilitation.userId();

    // this.filterGroupeExtensions.push("SURVATTER_GEXT");
  }

  ngOnInit(): void {
    this.params = {};
    if (this.demandeId) {
      this.params.id = this.demandeId;
    }

  }

  ngOnChanges() {
    this.initParameters();
  }

  setParams() {
    // if (this.motcle) {
    this.params.motcle = this.motcle;
    // }

    this.params.dateDebut = null;
    if (this.dateDebut) {
      this.params.dateDebut = this.dateDebut.toISOString();
    }
    this.params.dateFin = null;
    if (this.dateFin) {
      if (this.dateDebut && this.dateDebut > this.dateFin) {
        this.notificationService.errorMessage(
          "Paramètres",
          "La date de fin doit supérieure à la date de debut"
        );
        return;
      }
      this.params.dateFin = this.dateFin.toISOString();
    }
    this.params.motcle = this.motcle;
    this.params.motcle = this.motcle;

    this.logService.log("PARAMS");
    this.logService.log(this.typeDemande);
    this.logService.log(this.typeService);
    this.logService.log(this.categorieDemande);
    this.logService.log(this.demandeurRecherche);
    this.logService.log("PARAMS");

    if (this.typeService) {
      this.params.typeService = this.typeService;
    }
    this.params.typeDemande = this.typeDemande;
    this.params.categorie = this.categorieDemande;
    this.params.tdMontant = this.typeDemandeMontant;

    if (this.demandeurRecherche && this.demandeurRecherche.id) {
      this.params.personne = this.demandeurRecherche.id;
    } else {
      this.params.personne = null;
    }
    this.params.documentLivre = false;
    if (this.motcleDocumentLivre) {
      this.documentLivreRech = true;
      this.params.documentLivre = this.documentLivreRech;
      if (this.motcleDocumentLivre != RECHERCHE_TOUS_DOC_LIVRES) {
        this.params.motcleDocumentLivre = this.motcleDocumentLivre;
      }
    }

    this.logService.log("motcleDocumentLivre.rechercheMulticritere");
    this.logService.log(this.motcleDocumentLivre);
    this.logService.log(this.params);
    this.logService.log("motcleDocumentLivre.rechercheMulticritere");

    // this.demandeService.search(page, size, this.params).subscribe((data) => {
    //   if (data && data.content) {
    //     this.demandes = data.content;
    //     this.totalRecords = data.totalElements;
    //     this.loading = false;
    //   } else {
    //     this.notificationService.errorMessage(null, null);
    //   }
    // });

    if (!this.params.filter) {
      this.params.filter = {} as RechercheValeurExtensionCritere;
    }
  }

  load(event: LazyLoadEvent) {
    this.loading = true;

    // in a real application, make a remote request to load data using state metadata from event
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    // imitate db connection over a network

    // let  page = (event.sortField == null ? event.first / event.rows : 0);
    const page = event.first / event.rows;
    const size = event.rows;
    // this.params = {
    //   sort: event.sortField ? event.sortField : "reference",
    //   direction: event.sortOrder == 1 ? "DESC" : "ASC",
    //   motcle: event.globalFilter ? event.globalFilter : null,
    // };

    // this.params.sort = [event.sortField ? event.sortField : "reference"];
    // this.params.direction = event.sortOrder == 1 ? "DESC" : "ASC";

    // if (this.demandeId) {
    //   this.params.id = this.demandeId;
    // }

    this.setParams();

    this.params.filter.page = page;
    this.params.filter.size = size;


    this.demandeService.rechercheMulticritere(this.params).subscribe((data) => {
      this.logService.log("demandeService.rechercheMulticritere");
      this.logService.log(data);
      this.logService.log("demandeService.rechercheMulticritere");
      if (data && data.content) {
        this.demandes = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
      } else {
        this.notificationService.errorMessage(null, null);
      }
    });
    this.loading = false;
  }

  initParameters() {
    this.logService.log("initParameters");
    this.logService.log(this.typeService);
    this.logService.log("initParameters");

    this.typeDemandeService.getAll(this.typeService).subscribe((data) => {
      this.typeDemandes = data;
    });

    this.categorieDemandeService
      .getAll(this.typeService, null)
      .subscribe((data) => {
        this.categorieDemandes = data;
        this.initRechFilterParameters();
      });
  }

  initRechFilterParameters() {
    if (this.categorieDemandes && this.categorieDemandes.length > 0) {
      this.filterGroupeExtensions = [];
      this.categorieDemandes.forEach((cd) => {
        if (cd.formulaire && cd.formulaire.id) {
          this.formulaireService.find(cd.formulaire.id).subscribe((data) => {
            this.logService.log(data);
            let formulaire = data as Formulaire;
            formulaire.rubriques.forEach((rb) => {
              this.filterGroupeExtensions.push(rb.groupeExtension.id);
            });
          });
        }
      });
    }
  }
  resetFilters() {
    this.typeDemande = null;
    this.categorieDemande = null;
    this.motcle = null;
    this.demandeurRecherche = null;
    this.dateDebut = null;
    this.dateFin = null;
    this.motcleDocumentLivre = null;
    this.params.documentLivre = false;
    this.typeDemandeMontant = null;

    this.params.id = null;
    this.params.filter = {} as RechercheValeurExtensionCritere;

    this.initParameters();

    this.searchFilterForm.rechercheFilter = null;
    this.searchFilterForm.initParameters();

    this.resetTable();
  }

  selectTypeDemande() {
    if (this.typeDemande) {
      this.categorieDemande = null;
      this.categorieDemandeService
        .getAll(this.typeService, this.typeDemande)
        .subscribe((data) => {
          this.categorieDemandes = data;
          // this.filterGroupeExtensions=[];
          this.initRechFilterParameters();
        });
    }
  }

  selectCategorie() {
    this.typeDemandeMontant = null;
    this.typeDemandeMontantService
      .getAllByCategorie(this.categorieDemande)
      .subscribe((data) => {
        this.typeDemandeMontants = data;
      });
  }

  searchDemandeur(event) {
    let params = {
      sort: event.sortField ? event.sortField : "nom",
      direction: event.sortOrder == 1 ? "DESC" : "ASC",
      motcle: event.query ? event.query : null,
      referentiel: ReferentielConstants.REF_PERS_DEMANDEUR,
    };

    this.personnePhysiqueService.search(0, 10, params).subscribe((data) => {
      if (data && data.content) {
        this.demandeurs = data.content;
        this.demandeurs.forEach((d) => {
          d.fullname = d.nom + " " + d.prenoms;
        });
      } else {
        this.notificationService.errorMessage(null, null);
      }
    });
  }

  resetTable() {
    this.table.reset();
  }

  checkDocumentLivre(data) {
    if (data && data.idObject) {
      this.demandeService.documentDelivre(data.idObject).subscribe((dl) => {
        if (dl) {
          this.demandeService.find(data.idObject).subscribe((dmde) => {
            this.viewPrintConfirmation(dmde);
            return;
          });
        }
      });
    }
    this.table.reset();
  }

  initDemande() {
    this.selectedDemande = {} as Demande;
  }

  initNew() {
    this.initDemande();
    this.displayFormulaire = true;
  }

  createDemandeCallBack() {
    this.displayFormulaire = false;
    this.table.reset();
  }

  viewRecapitulatif(demande) {
    this.selectedDemande = demande;
    this.displayRecaptulatif = true;
  }
  closeRecapitulatif() {
    this.initDemande();
    this.displayRecaptulatif = false;
    this.table.reset();
  }

  viewFormulaire(demande) {
    this.selectedDemande = demande;
    this.displayFormulaire = true;
  }

  saveFormulaire() {
    if (this.formulaire) {
      this.logService.log("saveFormulaire");
      this.formulaire.enregistrer();
    }
  }

  closeFormulaire() {
    this.initDemande();
    this.displayFormulaire = false;
    this.table.reset();
  }

  viewPaiement(demande) {
    this.selectedDemande = demande;
    this.displayPaiement = true;
  }

  closePaiement() {
    this.initDemande();
    this.displayPaiement = false;
    this.table.reset();
  }

  viewPieces(demande) {
    this.selectedDemande = demande;
    this.displayPieces = true;
  }

  closePieces() {
    this.initDemande();
    this.displayPieces = false;
    this.table.reset();
  }

  viewFeedback(demande) {
    this.selectedDemande = demande;
    this.displayFeedback = true;
  }

  closeFeedback() {
    // this.initDemande();
    this.displayFeedback = false;
    this.table.reset();
  }


  viewCheckList(demande) {
    this.selectedDemande = demande;
    this.displayCheckList = true;
  }

  closeCheckList() {
    this.initDemande();
    this.displayCheckList = false;
    this.table.reset();
  }

  viewPrintConfirmation(demande: Demande) {
    this.selectedDemande = demande;
    this.displayPrintConfirmation = true;
  }

  uploadDocSigne(demande: Demande) {
    this.selectedDemande = demande;
    this.displayUploadDocSigne = true;
  }

  // closeUploadDocSigne() {
  //   this.selectedDemande = null;
  //   this.displayUploadDocSigne = false;
  // }

  viewDocSigneURL() {
    if (this.selectedDemande) {
      this.docSigneURL =
        this.demandeService.ressourceBaseURL() +
        "/delivrance/download/" +
        this.selectedDemande.id;
    }
  }

  onSelectDocSigne(event, demande: Demande) {
    this.selectedDemande = demande;
    for (const file of event.files) {
      if (!FileUtils.isPDFDocument(file.type)) {
        this.notificationService.errorMessage("Format PDF accepté", null);
        this.table.reset();
        break;
      }

      this.demandeService
        .uploadDocumentDelivre(demande.id, file)
        .subscribe((data) => {
          this.logService.log("####uploadDocumentDelivre##########");
          this.logService.log(data);

          this.selectedDemande.documentLivre = data;
          this.uploadDocSigne(this.selectedDemande);
          this.table.reset();
          this.logService.log("####uploadDocumentDelivre##########");
        });
    }
  }

  isImage(type: string) {
    return type && type.includes(FileContentType.IMAGE);
  }
  isPDFDocument(type: string) {
    return type && type.includes(FileContentType.PDF);
  }

  creerDocLivre(demande: Demande) {
    this.displayPrintConfirmation = false;
    this.viewDisplayDocLivre(demande);
  }

  closePrintConfirmation() {
    this.displayPrintConfirmation = false;
    this.table.reset();
  }
  closeUploadDocSigne() {
    this.selectedDemande = null;
    this.displayUploadDocSigne = false;
    this.table.reset();
  }

  viewDisplayDocLivre(demande: Demande, editable?: boolean) {

    this.selectedDemande = demande;
    this.displayDocLivre = true;
    this.documentLivreEditable = editable;
    // if (demande.documentLivre && demande.documentLivre.document) {
    //   this.demandeService
    //     .downloadDocumentDelivre(demande.id)
    //     .subscribe((response) => {
    //       this.logService.log(response);

    //       // var mediaType = "application/pdf";
    //       // var blob = new Blob([response.body], { type: mediaType });
    //       // saveAs(blob, demande.code + ".pdf");
    //       let downloadURL = window.URL.createObjectURL(
    //         new Blob([response.body], { type: "application/pdf" })
    //       );
    //       window.open(downloadURL, "", "width=1000,height=700");
    //     });
    //   return;
    // }
    // this.selectedDemande = demande;
    // // this.displayDocLivre = true;
    // this.docLivreURL = this.printDoc(demande, true);
    // this.logService.log("viewDisplayDocLivre docLivreURL: " + this.docLivreURL);
  }

  closeValidationDocLivre(event) {
    this.displayDocLivre = false;
    // this.table.reset();
  }

  viewDocLivreURL(demande): any {
    if (
      demande &&
      demande.documentDelivre &&
      demande.documentDelivre.document
    ) {
      return (
        this.demandeService.ressourceBaseURL() +
        "/delivrance/download/" +
        demande.id
      );
    }
    // return "C:\Users\rkoufionou\Downloads\ATTESTATION-Tue, 18 Jan 2022 19 53 32 GMT.pdf";
    this.typeSignature = TYPE_SIGNATURE.QRCODE;

    let doc = this.printDoc(demande, true);
    this.logService.log("setViewDocLivreURL " + doc);
    this.logService.log(doc);
    this.logService.log("setViewDocLivreURL");
    return doc;
  }

  uploadDocumentLivre(file: File) {
    this.demandeService
      .uploadDocumentDelivre(this.selectedDemande.id, file)
      .subscribe((data) => { });
  }

  downloadDocumentLivre(demande: Demande) {
    this.logService.log("downloadDocumentLivre: " + demande.id);
    // if (demande.documentLivre && demande.documentLivre.document) {
    this.demandeService
      .downloadDocumentDelivre(demande.id, this.typeService)
      .subscribe((response) => {
        this.logService.log(response);
        // let downloadURL = window.URL.createObjectURL(new Blob(resultBlob.body, {type: "application/pdf"}));
        // window.open(downloadURL);
        var mediaType = "application/pdf";
        var filename = "Autorisation accordée";
        if (this.typeService == TypeServiceConstants.PEL) filename = "Licence accordée"
        var blob = new Blob([response.body], { type: response.body.type });
        saveAs(blob, filename + ".pdf");
      });
    return;
    // }
    // this.typeSignature = TYPE_SIGNATURE.QRCODE;
    // this.notificationService.infoMessage(
    //   "Document avec signature electronique"
    // );
    // this.printDoc(demande, false);
  }

  printDoc(demande: Demande, view: boolean): string {
    if (!this.typeSignature) {
      // this.notificationService.errorMessage(
      //   "Selection de type de signature",
      //   "Veuillez choisir une option de signature"
      // );
      // return;
    }

    if (
      demande.categorieDemande.typeDemande.typeService.id ==
      TypeServiceConstants.SURVATTER
    ) {
      this.extensionService
        .getValeurExtensions(
          demande.id,
          GroupeExtensionConstants.GROUPE_EXTENSION_SURVATTER
        )
        .subscribe((data) => {
          this.logService.log("printResult");
          let printURL = this.demandeService.printAttestationSurvatter(
            demande,
            data,
            this.typeSignature,
            view
          );

          this.logService.log(printURL);
          this.logService.log("printResult : " + printURL);

          this.notificationService.successMessage(
            "L'attestation a été généré avec succes"
          );
          this.closePrintConfirmation();
          this.docLivreURL = printURL;
          return printURL;
        });
    } else if (
      demande.categorieDemande.typeDemande.typeService.id ==
      TypeServiceConstants.PEL
    ) {
      //Generate licence PEL
      this.closePrintConfirmation();
      return;
    }
  }

  printFormulaire(demande: Demande) {
    this.demandeService
      .downloadFormulaire(demande.id, demande.categorieDemande.typeDemande.typeService.id, true)
      .subscribe((response) => {
        this.logService.log(response);
        var mediaType = "application/pdf";
        var filename = "Formulaire";
        var blob = new Blob([response.body], { type: response.body.type });
        saveAs(blob, filename + ".pdf");
      });
    return;
    // if (
    //   demande.categorieDemande.typeDemande.typeService.id ==
    //   TypeServiceConstants.SURVATTER
    // ) {
    //   this.extensionService
    //     .getValeurExtensions(
    //       demande.id,
    //       GroupeExtensionConstants.GROUPE_EXTENSION_SURVATTER
    //     )
    //     .subscribe((data) => {
    //       this.formulaireService.printFormulaire(demande, data);
    //     });
    // } else if (
    //   demande.categorieDemande.typeDemande.typeService.id ==
    //   TypeServiceConstants.PEL
    // ) {
    //   //Generate form PEL

    //   return;
    // }
  }



  editRefDocumentLivre(demande: Demande) {
    if (!demande || !demande.documentLivre) {
      return;
    }
    this.notificationService.infoMessage("editRefDocumentLivre  Open modal for edition")
  }

  initRechMulticritere() {
    this.searchFilterForm.gExtensions = this.filterGroupeExtensions;
    this.searchFilterForm.initParameters();
    this.displayRechMulticritere = true;
  }

  closeRechMulticritere() {
    this.displayRechMulticritere = false;
  }

  applyFilters() {
    this.logService.log("applyFilters");
    this.logService.log(this.searchFilterForm);

    this.searchFilterForm.notifyRechercheCritere();
    this.logService.log("applyFilters");
  }

  supprimerFiltres() {
    this.searchFilterForm.initParameters();
  }

  canApplyFilter() {
    return (
      this.searchFilterForm.rechercheFilter.criteres &&
      this.searchFilterForm.rechercheFilter.criteres.length > 0
    );
  }

  rechMulticritere() {
    this.params.filter = this.searchFilterForm.rechercheFilter;
    this.closeRechMulticritere();
    this.resetTable();

    // this.demandeService.rechercheMulticritere(this.searchFilterForm.rechercheFilter).subscribe((data) => {
    //   this.logService.log(data);
    //   if (data && data.content) {
    //     this.demandes = data.content;
    //     this.totalRecords = data.totalElements;
    //     this.loading = false;
    //   } else {
    //     this.notificationService.errorMessage(null, null);
    //   }
    // });
    this.notificationService.infoMessage(
      "Recherche multicritère",
      "Filtres appliqués"
    );
  }

  downloadStatistic() {
    this.logService.log("downloadStatistic");
    
    this.setParams();

    this.logService.log(this.params);
    this.demandeService.downloadStatistic(this.typeService, this.params)
      .subscribe((response) => {
        this.logService.log(response);
        // let downloadURL = window.URL.createObjectURL(new Blob(resultBlob.body, {type: "application/pdf"}));
        // window.open(downloadURL);
        var mediaType = "application/pdf";
        var filename = "Liste des demandes";
        var blob = new Blob([response.body], { type: response.body.type });
        saveAs(blob, filename + ".pdf");
      });
    return;
  }
}
