import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RouteConstants } from "../../gas/demande/models/gas-constants";
import { Habilitation, ProfilUtilisteur } from "../models/habilitation";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export abstract class HabilitationService {
  constructor(private authService: AuthService, private router: Router) {}

  abstract getFunction(): string;

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  logout() {
    this.authService.logout();
  }

  redirectHome() {
    this.router.navigateByUrl(RouteConstants.ROUTE_HOME);
  }

  redirectLogin() {
    this.router.navigateByUrl(RouteConstants.ROUTE_LOGIN);
  }

  redirectAccessDenied() {
    this.router.navigateByUrl(RouteConstants.ROUTE_ACCESS_DENIED);
  }

  redirectNotFound() {
    this.router.navigateByUrl(RouteConstants.ROUTE_NOTFOUND);
  }

  userId(): string {
    return this.authService.getUserId();
  }

  userPersonneId(): string {
    return this.authService.getUserPersonneId();
  }

  userFullName(): string {
    return this.authService.getUserFullName();
  }

  userAvatarName(): string {
    const names: string[] = this.authService.getUserFullName().split(" ");
    let avatarName = "";
    names.forEach((n) => (avatarName = avatarName + n.charAt(0)));
    return avatarName.toUpperCase();
  }

  hasHabilitation(ft: string, habilitation: number): boolean {
    // let check: boolean;
    // this.authService.checkHabilitation(ft, habilitation).then((data) => {
    //   check = data;
    // });
    return this.authService.checkHabilitation(ft, habilitation);
  }

  hasProfil(code: string): boolean {
    return this.authService.checkProfilHab(code);
  }

  canRead(): boolean {
    return this.hasHabilitation(
      this.getFunction(),
      Habilitation.HABILITATION_READ
    );
  }

  canCreate(): boolean {
    return this.hasHabilitation(
      this.getFunction(),
      Habilitation.HABILITATION_CREATE
    );
  }
  canUpdate(): boolean {
    return this.hasHabilitation(
      this.getFunction(),
      Habilitation.HABILITATION_UPDATE
    );
  }
  canDelete(): boolean {
    return this.hasHabilitation(
      this.getFunction(),
      Habilitation.HABILITATION_DELETE
    );
  }
  canValidate(): boolean {
    return this.hasHabilitation(
      this.getFunction(),
      Habilitation.HABILITATION_VALIDATE
    );
  }

  isAdmin(): boolean {
    return this.hasHabilitation(
      this.getFunction(),
      Habilitation.HABILITATION_ADMINISTRATE
    );
  }
}
