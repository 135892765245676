import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-save-demande-piece-jointes',
  templateUrl: './save-demande-piece-jointes.component.html',
  styleUrls: ['./save-demande-piece-jointes.component.css']
})
export class SaveDemandePieceJointesComponent implements OnInit {

  uploadedFiles: any[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  onUpload(event) {
    for (const file of event.files) {
        this.uploadedFiles.push(file);
    }

    // this.messageService.add({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
}

openWindow(fichier){
  fichier.click();
}

}
