import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-demande',
  templateUrl: './detail-demande.component.html',
  styleUrls: ['./detail-demande.component.css']
})
export class DetailDemandeComponent implements OnInit {
  displayValidation:string;
  constructor() { 
    
  }

  ngOnInit(): void {
  }

}
