import { Component, Input, OnInit } from "@angular/core";
import { DemandeService } from "src/app/modules/gas/demande/services/demande.service";
import { PaiementService } from "src/app/modules/gas/demande/services/paiement.service";
import { ListItem } from "src/app/modules/setting/models/codification";
import {
  StatusView,
  StatusViewSeverity,
} from "src/app/modules/setting/models/codification-constants";
import { LogService } from "src/app/modules/shared/services/log.service";

@Component({
  selector: "app-paiement-status",
  templateUrl: "./paiement-status.component.html",
  styleUrls: ["./paiement-status.component.css"],
})
export class PaiementStatusComponent implements OnInit {
  statutPaiement: StatusView;

  @Input() demande: string;
  @Input() showAmount: boolean = false;
  @Input() accepted: boolean = true;

  amount: number;
  devise: ListItem;

  constructor(
    private demandeService: DemandeService,
    private paiementService: PaiementService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    //this.getPaiementTagCheck();
  }

  getPaiementTagCheck() {
    if (this.demande) {
      this.demandeService.find(this.demande).subscribe((dmdeObject) => {
        if (dmdeObject) {
          this.devise = dmdeObject.typeDemandeMontant.devise;

          this.paiementService
            .amontByDmde(dmdeObject.id, this.accepted)
            .subscribe((data) => {
              this.statutPaiement = {} as StatusView;

              this.amount = data;
              if (data && data > 0) {
                if (data < dmdeObject.typeDemandeMontant.montantTotal) {
                  this.statutPaiement.label = "Redevance";
                  this.statutPaiement.severity = StatusViewSeverity.warning;
                } else {
                  this.statutPaiement.label = "Payé";
                  this.statutPaiement.severity = StatusViewSeverity.success;
                  if(!this.accepted){
                    this.statutPaiement.severity = StatusViewSeverity.info;
                  }
                }
              } else {
                this.statutPaiement.label = "Non Payé";
                this.statutPaiement.severity = StatusViewSeverity.danger;
                if (this.accepted) {
                  this.paiementService
                    .amontByDmde(dmdeObject.id, false)
                    .subscribe((data) => {
                      if (data > 0) {
                        this.statutPaiement.label = "A valider";
                        this.statutPaiement.severity =
                          StatusViewSeverity.warning;
                      }
                    });
                }
              }
            });
        }
      });
    }
  }

  ngOnChanges() {
    this.logService.log("UPDATE getPaiementTagCheck " + this.demande);
    this.getPaiementTagCheck();
    this.logService.log("UPDATE getPaiementTagCheck");
  }
}
