import { CodificationTypeDonnee } from "../../setting/models/codification-constants";
import { Extension } from "../../setting/models/extension";


declare var jQuery: any;
declare var $: any;

export class ExtensionUtils {
	// supprimer les champs de params qui sont null, undefined, ""
	static isNumeric(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.NUMERIQUE;
	}

	static isAlphaNumeric(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.ALPHANUMERIQUE;
	}
	static isTextArea(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.TEXT;
	}
	static isDateTime(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.DATETIME;
	}

	static isDate(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.DATE;
	}
	static isSelect(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.LIST;
	}

	static isMultiSelect(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.MULTIVALUE;
	}

	static isTreeSelect(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.CLASSIFICATION;
	}

	static isTagSelect(extension: Extension): boolean {
		return extension.typeDonnee.id == CodificationTypeDonnee.ETIQUETTE;
	}
}
