import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import { ReferentielPersonne } from "../../models/personne";

const URL_PERSONNE: string = environment.gasAPIUrl + "/personne";
const URL_PERSONNE_REFERENTIEL: string =
  environment.gasAPIUrl + "/personne/referentiel";

@Injectable({
  providedIn: "root",
})
export class PersonneService extends CrudService {
  ressourceBaseURL(): string {
    return URL_PERSONNE;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }
  allReferentiels(): Observable<ReferentielPersonne[]> {
    return this.httpClient
      .get<ReferentielPersonne[]>(URL_PERSONNE_REFERENTIEL + "/all")
      .pipe(
        catchError((error: any): Observable<ReferentielPersonne[]> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }
}
