import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { Demande } from "../../../models/demande";
import { DemandeHabilitation } from "../../../models/demande-habilitation";
import {
  RouteConstants,
  TypeServiceConstants,
} from "../../../models/gas-constants";
import { Paiement } from "../../../models/paiement";
import { DemandeService } from "../../../services/demande.service";
import { PaiementService } from "../../../services/paiement.service";

@Component({
  selector: "app-view-paiement",
  templateUrl: "./view-paiement.component.html",
  styleUrls: ["./view-paiement.component.css"],
})
export class ViewPaiementComponent implements OnInit {
  demande: Demande;
  paiement: Paiement;

  constructor(
    private route: ActivatedRoute,
    private demandeService: DemandeService,
    private paiementService: PaiementService,
    private habilitationService: DemandeHabilitation,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.queryParams.subscribe((params) => {
      const id = params.id;
      if (id) {
        this.paiementService.find(id).subscribe((data) => {
          if (!data || !data.id) {
            this.habilitationService.redirectHome();
          }
          this.paiement = data;
          this.demande = this.paiement.demande;
          let listUrl = "";
          if (
            this.demande.categorieDemande.typeDemande.typeService.code ==
            TypeServiceConstants.SURVATTER
          ) {
            listUrl = RouteConstants.ROUTE_SURVATTER;
          } else if (
            this.demande.categorieDemande.typeDemande.typeService.code ==
            TypeServiceConstants.PEL
          ) {
            listUrl = RouteConstants.ROUTE_PEL;
          }
          this.breadcrumbService.setItems([
            {
              label: this.demande.categorieDemande.typeDemande.libelle,
              routerLink: listUrl,
            },
            { label: this.demande.reference },
            { label: "paiements" },
          ]);
        });
      } else {
        this.habilitationService.redirectHome();
      }
    });
  }

  ngOnInit(): void {}
}
