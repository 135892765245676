// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,


  apiUrl: "https://gasapi.anac-togo.tg",
  gasAPIUrl: "https://gasapi.anac-togo.tg/anac",
  // Session
  storagePrefix: "gas_agt",
  logoBaseUrl: "assets/layout/images",

  // in seconds ( after 10 min)
  idleSessionTimeout: 600,
  viewNotification: false,
  cacheRetentionInterval: 3600, 

  nomSignataireRecu: '',
  // nomSignataireAttestation: 'Le Directeur Général',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
