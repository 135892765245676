import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/modules/shared/services/crud.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { environment } from 'src/environments/environment';

const URL_DOCUMENT_LIVRE: string = environment.gasAPIUrl + "/document-livre";


@Injectable({
  providedIn: 'root'
})
export class DocumentLivreService extends CrudService {
  ressourceBaseURL(): string {
    return URL_DOCUMENT_LIVRE;
  }
  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }
}
