import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import {
  HabilitationConstants,
  ReferentielConstants,
  RouteConstants,
} from "src/app/modules/gas/demande/models/gas-constants";
import {
  PersonnePhysique,
  ReferentielPersonne,
} from "src/app/modules/gas/demande/models/personne";
import { PersonnePhysiqueService } from "src/app/modules/gas/demande/services/personne/personne-physique.service";
import { PersonneService } from "src/app/modules/gas/demande/services/personne/personne.service";
import { Profil, User } from "src/app/modules/security/models/user";
import { UserService } from "src/app/modules/security/services/user.service";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { FormService } from "src/app/modules/shared/services/form.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { PersonneHabilitation } from "../../../models/personne-habilitation";

@Component({
  selector: "app-personnel",
  templateUrl: "./personnel.component.html",
  styleUrls: ["./personnel.component.css"],
})
export class PersonnelComponent implements OnInit {
  motcle: string;
  concept: string;
  objectId: string;
  userId: string;
  fonction: string;

  personneId: string;
  params: any;
  totalRecords: number;
  loading: boolean;

  referentiel: string;
  referentiels: ReferentielPersonne[];

  personne: PersonnePhysique;
  editable: boolean;
  display: boolean;

  personnes: PersonnePhysique[];

  profils: Profil[];
  selectedProfils: Profil[];

  @ViewChild("dt") table: Table;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private notificationService: NotificationService,
    private habilitation: PersonneHabilitation,
    private personnePhysiqueService: PersonnePhysiqueService,
    private personneService: PersonneService,
    private formService: FormService,
    private logService: LogService,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.route.queryParams.subscribe((parameters) => {
      if (parameters.id) {
        this.personneId = parameters.id;
        personnePhysiqueService.find(this.personneId).subscribe((pers) => {
          if (
            pers &&
            pers.referentielPersonne.id ==
              ReferentielConstants.REF_PERS_DEMANDEUR
          ) {
            this.router.navigateByUrl(
              RouteConstants.ROUTE_DEMANDEUR + "?id=" + this.personneId
            );
          }
        });
      }
    });

    this.breadcrumbService.setItems([
      { label: "ANAC" },
      {
        label: "Personnel",
        routerLink: [RouteConstants.ROUTE_PERSONNEL],
      },
    ]);

    this.concept = HabilitationConstants.CONCEPT_METIER_PERSONNE;
    this.fonction = HabilitationConstants.FUNCTION_PERSONNE;
    this.userId = this.habilitation.userId();
    this.personne = {} as PersonnePhysique;
    this.personne.utilisateur = {} as User;
  }

  ngOnInit(): void {
    this.initParameters();
  }

  initParameters(): void {
    this.personneService.allReferentiels().subscribe((data) => {
      if (data) {
        this.referentiels = data.filter(
          (ref) => ref.id != ReferentielConstants.REF_PERS_DEMANDEUR
        );
      }
    });

    this.authService.allProfils().subscribe((data) => {
      this.profils = data;
    });
  }

  load(event: LazyLoadEvent) {
    this.loading = true;

    // in a real application, make a remote request to load data using state metadata from event
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value
    // imitate db connection over a network

    // let  page = (event.sortField == null ? event.first / event.rows : 0);
    const page = event.first / event.rows;
    const size = event.rows;
    this.params = {
      sort: event.sortField ? event.sortField : "reference",
      direction: event.sortOrder == 1 ? "DESC" : "ASC",
      motcle: event.globalFilter ? event.globalFilter : null,
      rfexc: ReferentielConstants.REF_PERS_DEMANDEUR,
    };

    if (this.motcle) {
      this.params.motcle = this.motcle;
    }

    if (this.referentiel) {
      this.params.referentiel = this.referentiel;
    }
    // Filter by Id
    if (this.personneId) {
      this.params.id = this.personneId;
    }

    this.personnePhysiqueService
      .search(page, size, this.params)
      .subscribe((data) => {
        if (data && data.content) {
          this.personnes = data.content;
          this.totalRecords = data.totalElements;
          this.loading = false;
        } else {
          this.notificationService.errorMessage(null, null);
        }
      });
    this.loading = false;
  }

  add() {
    this.personne = {} as PersonnePhysique;
    this.personne.utilisateur = {} as User;
    this.personne.referentielPersonne = {} as ReferentielPersonne;
    this.editable = true;
    this.display = true;
  }

  view(personne: PersonnePhysique, editable = false) {
    this.userService.find(personne.utilisateur.id).subscribe((user) => {
      this.display = true;
      this.personne = personne;
      this.logService.log(
        "view referentiel: " + personne.referentielPersonne.id
      );
      this.logService.log("view profils: " + user.profils);
      this.logService.log(user.profils);
      this.logService.log("view profils: " + user.profils);

      this.personne.utilisateur = user;
      this.selectedProfils = user.profils;
      this.referentiel = personne.referentielPersonne.id;
      this.editable = personne != null && personne.etat.modifiable && editable;
    });
  }

  refreshTable() {
    this.table.reset();
  }
  resetFilters() {
    this.motcle = null;
    this.referentiel = null;
  }

  closeView() {
    this.display = false;
    this.personne = {} as PersonnePhysique;
    this.personne.utilisateur = {} as User;
    this.selectedProfils = [];
  }

  save(editform: NgForm) {
    if (this.formService.isNgFormValid(editform)) {
      if (
        this.personne.utilisateur.password !=
        this.personne.utilisateur.confirmPassword
      ) {
        this.notificationService.errorMessage(
          "Mot de passe est incorrect",
          "Veuillez confirmer votre mot de passe!"
        );
        return;
      }
      this.personne.utilisateur.firstName = this.personne.nom;
      this.personne.utilisateur.lastName = this.personne.prenoms;
      this.personne.utilisateur.email = this.personne.email;
      this.personne.utilisateur.username = this.personne.email;
      this.personne.utilisateur.profils = this.selectedProfils;

      // this.personne.utilisateur.profils = [];
      // this.personne.utilisateur.profils.push({
      //   id: UserProfilsConstants.PROFIL_personne,
      // });

      this.personne.referentielPersonne = {} as ReferentielPersonne;
      this.personne.referentielPersonne.id = this.referentiel;
      this.personne.demandeur = false;

      if (this.personne.id) {
        this.personnePhysiqueService
          .save(this.personne)
          .subscribe((personne) => {
            if (personne.id) {
              this.notificationService.successMessage();
              this.closeView();
              this.refreshTable();
            } else {
              // something went wrong
              this.notificationService.serverError();
            }
          });
      } else {
        this.authService.register(this.personne).subscribe((personne) => {
          if (personne.id) {
            this.notificationService.successMessage(
              "Utilisateur notifié par mail"
            );
            this.closeView();
            this.refreshTable();
          } else {
            // something went wrong
            this.notificationService.serverError();
          }
        });
      }
    }
  }
}
