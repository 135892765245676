import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AppMainComponent } from "./app.main.component";
import { AppErrorComponent } from "../../../pages/app.error.component";
import { CodificationComponent } from "../../setting/components/codification/codification.component";
import { ExtensionComponent } from "../../setting/components/extension/extension.component";
import { WorkflowComponent } from "../../setting/components/workflow/workflow.component";
import { PaysComponent } from "../../setting/components/pays/pays.component";
import { UserComponent } from "../../security/components/user/user.component";
import { ProfilComponent } from "../../security/components/profil/profil.component";
import { TypeDocumentComponent } from "../../ged/components/type-document/type-document.component";
import { LoginComponent } from "../../gas/home/components/login/login.component";
import { AuthSurvatterGuard } from "../services/auth-guard/auth-survatter.guard";
import { AuthPelGuard } from "../services/auth-guard/auth-pel.guard";
import { AuthComptabiliteGuard } from "../services/auth-guard/auth-comptabilite.guard";
import { AuthDemandeurGuard } from "../services/auth-guard/auth-demandeur.guard";
import { AuthPersonnelGuard } from "../services/auth-guard/auth-personnel.guard";
import { AuthGuard } from "../services/auth-guard/auth.guard";
import { SurvatterComponent } from "../../gas/demande/components/survatter/survatter.component";
import { PelComponent } from "../../gas/demande/components/pel/pel.component";
import { PaiementComponent } from "../../gas/comptabilite/components/paiement/paiement.component";
import { DemandeurComponent } from "../../gas/compte/components/personne/demandeur/demandeur.component";
import { PersonneMoraleComponent } from "../../gas/compte/components/personne/personne-morale/personne-morale.component";
import { PersonnelComponent } from "../../gas/compte/components/personne/personnel/personnel.component";
import { ViewDemandeComponent } from "../../gas/demande/components/pages/view-demande/view-demande.component";
import { ViewPaiementComponent } from "../../gas/demande/components/pages/view-paiement/view-paiement.component";
import { ViewPiecesComponent } from "../../gas/demande/components/pages/view-pieces/view-pieces.component";
import { ViewFormulaireComponent } from "../../gas/demande/components/pages/view-formulaire/view-formulaire.component";
import { ActivationComponent } from "../../gas/compte/components/activation/activation.component";
import { ForgetPasswordComponent } from "../../gas/compte/components/forget-password/forget-password.component";
import { RecoveryComponent } from "../../gas/compte/components/forget-password/recovery/recovery.component";
import { AppAccessdeniedComponent } from "./app.accessdenied.component";
import { AppNotfoundComponent } from "./app.notfound.component";
import { ListDemandesComponent } from "../../gas/demande/components/list-demandes/list-demandes.component";
import { MonCompteComponent } from "../../gas/compte/components/mon-compte/mon-compte.component";
import { AccueilComponent } from "../../gas/home/components/accueil/accueil.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: "login", component: LoginComponent },
        // { path: "creer-compte", component: CreerCompteComponent },
        {
          path: "demande",
          component: AppMainComponent,
          canActivate: [AuthSurvatterGuard],
          children: [
            {
              path: "",
              component: ListDemandesComponent,
            },
            {
              path: "recapitulatif",
              component: ViewDemandeComponent,
            },
            {
              path: "paiement",
              component: ViewPaiementComponent,
            },
            {
              path: "pieces",
              component: ViewPiecesComponent,
            },
            {
              path: "formulaire",
              component: ViewFormulaireComponent,
            },
          ],
        },
        {
          path: "survatter",
          component: AppMainComponent,
          canActivate: [AuthSurvatterGuard],
          children: [
            {
              path: "",
              component: SurvatterComponent,
            },
          ],
        },
        {
          path: "pel",
          component: AppMainComponent,
          canActivate: [AuthPelGuard],
          children: [
            {
              path: "",
              component: PelComponent,
            },
          ],
        },
        {
          path: "comptabilite",
          component: AppMainComponent,
          canActivate: [AuthComptabiliteGuard],
          children: [
            {
              path: "paiement",
              component: PaiementComponent,
            },
          ],
        },

        {
          path: "personne",
          component: AppMainComponent,
          canActivate: [AuthDemandeurGuard],
          children: [
            {
              path: "demandeur",
              component: DemandeurComponent,
            },
            {
              path: "pm",
              component: PersonneMoraleComponent,
            },
          ],
        },
        {
          path: "personnel",
          component: AppMainComponent,
          canActivate: [AuthPersonnelGuard],
          children: [
            {
              path: "",
              component: PersonnelComponent,
            },
          ],
        },
        {
          path: "compte",
          component: AppMainComponent,
          canActivate: [AuthGuard],
          children: [
            {
              path: "",
              component: MonCompteComponent,
            },
            {
              path: "password",
              component: MonCompteComponent,
            },
          ],
        },

        {
          path: "",
          component: AppMainComponent,
          // path: '', component: LoginComponent,
          canActivate: [AuthGuard],
          children: [
            { path: "", component: AccueilComponent },

            // { path: '', component: LoginComponent },

            { path: "setting/codification", component: CodificationComponent },
            { path: "setting/extension", component: ExtensionComponent },
            { path: "setting/workflow", component: WorkflowComponent },
            { path: "setting/pays", component: PaysComponent },

            { path: "security/user", component: UserComponent },
            { path: "security/profil", component: ProfilComponent },

            { path: "ged/type-document", component: TypeDocumentComponent },
          ],
        },
        { path: "activation/:key", component: ActivationComponent },
        { path: "forget", component: ForgetPasswordComponent },
        { path: "recovery/:key", component: RecoveryComponent },
        { path: "error", component: AppErrorComponent },
        { path: "accessdenied", component: AppAccessdeniedComponent },
        { path: "notfound", component: AppNotfoundComponent },

        { path: "**", redirectTo: "/notfound" },
      ],
      { scrollPositionRestoration: "enabled" }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
