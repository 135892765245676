import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-demande-autre-information',
  templateUrl: './save-demande-autre-information.component.html',
  styleUrls: ['./save-demande-autre-information.component.css']
})
export class SaveDemandeAutreInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
